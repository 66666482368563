/* eslint-disable no-restricted-globals */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsOpenModal, setIsModalSpeaker } from "../../../redux/modal/modal";
import { getCalendarLinks } from "../../../api/events/events";
import DOMPurify from "dompurify";

function ModalSpeaker() {
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const { speaker } = useSelector((state) => state.event);
  const baseUrl = process.env.REACT_APP_BASE_URL;

  React.useEffect(() => {
    let timer1 = setTimeout(() => {
      window.speakerSetCodeHtmlModal && window.speakerSetCodeHtmlModal();
      window.speakerSetCodeTextModal && window.speakerSetCodeTextModal();
      
    }, 1000);
    return () => {
      clearTimeout(timer1);
    };
  }, [speaker]);

  return (
    <div className="modal__speaker">
      <div className="modal__speaker-box">
        <button
          className="modal__close-speaker"
          onClick={() => {
            dispatch(setIsOpenModal(false));
            dispatch(setIsModalSpeaker(false));
          }}
        />
      </div>
      <div className="scroll">
        <div className="speaker__box" id={`speakerBox-${speaker.id}`}>
          <img
            className="speaker__image"
            src={baseUrl + speaker.image}
            alt="speaker avatar"
          />
          <div className="speaker__info">
            <div className="speaker__row">
              <span className="speaker__name">
                {speaker.first_name + " " + speaker.last_name}
              </span>
              <span className="speaker__company">{speaker.company}</span>
            </div>
            <span className="speaker__position">{speaker.position}</span>
          </div>
        </div>
        <p
          className="speaker__text"
          id={`speakerText-${speaker.id}`}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(speaker.bio),
          }}
        ></p>
      </div>
    </div>
  );
}

export default ModalSpeaker;
