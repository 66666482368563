import { FC } from 'react'
import { SeminarAssignButtonStyle } from './styled'

interface SeminarAssignButtonTypes {
  isAssigned: string
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  outline?: boolean
  bold?: boolean
  big?: boolean
  disabled?: boolean
  className?: string
}

const SeminarAssignButton: FC<SeminarAssignButtonTypes> = ({
  isAssigned,
  onClick,
  outline,
  bold,
  big,
  disabled,
  className,
}) => {
  return (
    <SeminarAssignButtonStyle
      onClick={onClick}
      outline={outline}
      bold={bold}
      big={big}
      disabled={disabled}
      className={className}
    >
      {isAssigned && (
        <span>Не приду</span>
      )}
      {!isAssigned && (
        <span>Записаться</span>
      )}
    </SeminarAssignButtonStyle>
  )
}

export default SeminarAssignButton
