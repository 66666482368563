import React, { useEffect, useState } from "react";
import "./Speaker.scss";
import { getSpeakerInfo } from "../../api/events/events";
import { useDispatch } from "react-redux";
import { handleMouseDown } from "../../utils/MouseDown";

function Speaker({ card, index, id }) {
  const token = localStorage.getItem("accessToken");
  const dispatch = useDispatch();

  const [isCodeSucess, setIsCodeSucess] = useState(false);

  // useEffect(() => {
  //   isCodeSucess && window.eventSetCodeSpeaker && window.eventSetCodeSpeaker();
  // }, [isCodeSucess]);

  return (
    <li className="speaker">
      <div className="speaker__box" id={`speaker-${id}`}>
        <img
          id={`speaker__secret-${id}`}
          className="speaker__image desktop"
          src={card.image}
          alt="speaker avatar"
          onMouseDown={(e) => {
            handleMouseDown(e, false, "speaker");
          }}
        />
        <img
          className="speaker__image mobile"
          src={card.image}
          alt="speaker avatar"
        />

        <div className="speaker__info">
          <div className="speaker__row">
            <span className="speaker__name">
              {card.first_name + " " + card.last_name}
            </span>
            <span className="speaker__company">{card.company}</span>
          </div>
          <span className="speaker__position">{card.position}</span>
        </div>
      </div>
      <button
        className="speaker__button"
        onClick={() => {
          getSpeakerInfo(token, dispatch, card.id);
        }}
      />
    </li>
  );
}

export default Speaker;
