import { createSlice } from '@reduxjs/toolkit'

const seminar = createSlice({
	name: 'seminar',
	initialState: {
		tabs: [
			{ id: 1, title: 'Трек Technologies', tag: 'Technologies', isSelected: false },
			// { id: 2, title: 'Трек Science', tag: 'Science', isSelected: false },
			// { id: 3, title: 'Трек Lifestyle', tag: 'Lifestyle', isSelected: false },
			{ id: 4, title: 'Трек Product/Design', tag: 'Art', isSelected: false },
		],
		tag: '',
		defaultTag: 'Technologies',
		seminars: [],
		filteredSeminars: [],
		favoriteList: [],
		assignSeminar: null,
		assignAction: '', // invite | reject
		assignList: [], // UniqueId[] Список id семинаров, на которые записан текущий пользователь
	},
	reducers: {
		setTab(state, action) {
			const selectedId = action.payload
			state.tabs = state.tabs.map((item) => {
				return { ...item, ...{ isSelected: item.id === selectedId } }
			})
		},
		setTag(state, action) {
			state.tag = action.payload
		},
		setSeminars(state, action) {
			state.seminars = action.payload
		},
		setFilteredSeminars(state) {
			const category = state.tag || state.defaultTag
			state.filteredSeminars = state.seminars
				.filter((item) => {
					return item.tag === category
				})
				.sort((a, b) => String(a.schedule).toLowerCase() > String(b.schedule).toLowerCase() ? 1 : -1);
		},
		setFavorite(state, action) {
			const currentBookmark = action.payload
			if (state.favoriteList.some((item) => item.id === currentBookmark.id)) {
				state.favoriteList = state.favoriteList.filter((item) => item.id !== currentBookmark.id)
			} else {
				state.favoriteList = state.favoriteList.concat([currentBookmark])
			}
		},
		setAssignSeminar(state, action) {
			state.assignSeminar = action.payload
		},
		setAssignList(state, action) {
			const currentAssign = action.payload
			if (state.assignList.includes(currentAssign)) {
				state.assignList = state.assignList.filter((item) => item !== currentAssign)
			} else {
				state.assignList = state.assignList.concat([currentAssign])
			}
		},
		setAssignAction(state, action) {
			state.assignAction = action.payload
		},
		clearAssign(state) {
			state.assignSeminar = null
			state.assignAction = ''
		},
		updateFavoriteList(state, action) {
			state.favoriteList = action.payload
		}
	}
})

export default seminar.reducer

export const {
	setTab,
	setTag,
	setSeminars,
	setFilteredSeminars,
	setFavorite,
	setAssignSeminar,
	setAssignList,
	setAssignAction,
	clearAssign,
	updateFavoriteList,
} = seminar.actions
