// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginPage_loginPage__aF1Sx {
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 140px 100px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  min-width: 100%;
  margin: 0;
  flex-direction: column;
  background: linear-gradient(180deg, #E1DEF3 -63.42%, rgba(225, 222, 243, 0.15) 113.11%), #F8F9FB;
}
.LoginPage_loginPage__wrapper__JvZR0 {
  width: 514px;
  height: 438px;
  position: relative;
}
.LoginPage_loginPage__wrapper__JvZR0 .LoginPage_logoImg__YkdI\\+ {
  position: absolute;
  top: -90px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.LoginPage_loginPage__wrapper__JvZR0 .LoginPage_backgroundImg__Ibllv {
  position: absolute;
  top: 20px;
  left: -33%;
  max-width: unset !important;
}

@media (max-width: 520px) {
  .LoginPage_loginPage__aF1Sx {
    padding: 0;
    display: block;
  }
  .LoginPage_loginPage__wrapper__JvZR0 {
    width: unset;
    height: unset;
    margin-top: 32px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/LoginPage/LoginPage.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,sBAAA;EACA,yBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,YAAA;EACA,eAAA;EACA,SAAA;EACA,sBAAA;EACA,gGAAA;AACF;AACE;EACE,YAAA;EACA,aAAA;EACA,kBAAA;AACJ;AACI;EACE,kBAAA;EACA,UAAA;EACA,SAAA;EACA,gCAAA;AACN;AAEI;EACE,kBAAA;EACA,SAAA;EACA,UAAA;EACA,2BAAA;AAAN;;AAgCA;EACE;IACE,UAAA;IACA,cAAA;EA7BF;EA8BE;IACE,YAAA;IACA,aAAA;IACA,gBAAA;EA5BJ;AACF","sourcesContent":[".loginPage {\n  overflow-x: hidden;\n  box-sizing: border-box;\n  padding: 140px 100px 50px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  width: 100vw;\n  min-width: 100%;\n  margin: 0;\n  flex-direction: column;\n  background: linear-gradient(180deg, #E1DEF3 -63.42%, rgba(225, 222, 243, .15) 113.11%), #F8F9FB;\n\n  &__wrapper {\n    width: 514px;\n    height: 438px;\n    position: relative;\n\n    .logoImg {\n      position: absolute;\n      top: -90px;\n      left: 50%;\n      transform: translate(-50%, -50%);\n    }\n\n    .backgroundImg {\n      position: absolute;\n      top: 20px;\n      left: -33%;\n      max-width: unset !important;\n    }\n  }\n}\n\n// @media (min-width: 850px) and (max-height: 750px) {\n//   .loginPage {\n//     &__wrapper {\n//       .backgroundImg {\n//         height: 75vh;\n//       }\n//     }\n//   }\n// }\n\n\n// @media (max-width: 850px) {\n//   .loginPage {\n//     padding: 0;\n\n\n//     &__wrapper {\n//       width: unset;\n\n//       .backgroundImg {\n//         display: none;\n//       }\n//     }\n//   }\n// }\n\n\n@media (max-width: 520px) {\n  .loginPage {\n    padding: 0;\n    display: block;\n    &__wrapper {\n      width: unset;\n      height: unset;\n      margin-top: 32px;\n    }\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginPage": `LoginPage_loginPage__aF1Sx`,
	"loginPage__wrapper": `LoginPage_loginPage__wrapper__JvZR0`,
	"logoImg": `LoginPage_logoImg__YkdI+`,
	"backgroundImg": `LoginPage_backgroundImg__Ibllv`
};
export default ___CSS_LOADER_EXPORT___;
