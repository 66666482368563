import React from 'react'
import './NotFound.scss'
import { useNavigate } from "react-router-dom";
import mistake_404 from "../../images/404.png"

function NotFound() {
    const navigate = useNavigate();
    const isLogin = localStorage.getItem("login");

    return (
        <div className='not-found'>
            <img
                src={mistake_404}
                alt='error 404'
            />
            <h2>Запрошенная вами страница не найдена</h2>
            <span>Возможно, вы указали неправильный адрес или страница, на которую вы хотели зайти, устарела и была удалена.</span>
            <button
                onClick={ () => navigate('/')}
            >На главную</button>
        </div>
    )
}

export default NotFound;
