import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import "./CentralColumn.scss";
import broadcast from "../../images/broadcast.png";
import clock from "../../images/broadcast/clock.svg";
import arr from "../../images/broadcast/arr.svg";
import fav from "../../images/broadcast/fav.svg";
import { BroadCast } from "../BroadCast/BroadCast";
import ProgramCard from "../ProgramCard/ProgramCard";
import ProgramButtons from "../ProgramButtons/ProgramButtons";
import {
  getEvents,
  getSchedule,
  getTabs,
  watchingBroadcast,
} from "../../api/events/events";
import { useNavigate } from "react-router-dom";
import { guidToInt } from "../../utils/convertGuid";

function BroadcastColumn() {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const { event, schedule } = useSelector((state) => state.event);
  const STARTED_BROADCAST = false;
  const COMMENTS_ENABLED = false;

  useEffect(() => {
    getEvents(token, dispatch, "Main");
    getSchedule(token, dispatch);
    getTabs(token, dispatch);
  }, []);

  useEffect(() => {
    const logSomething = () => {
      if (user.watching_broadcast === false) {
        watchingBroadcast(token);
      }
    };

    const timeout = setTimeout(logSomething, 60000);

    return () => clearTimeout(timeout);
  }, []);
  let userName = user?.first_name + user?.last_name;
  if (userName == '') {
    userName = 'Гость мероприятия';
  }
  const userRegistrationData = JSON.stringify({
    idnumber: guidToInt(user.uuid),
    name: user?.first_name || 'Гость',
    company: user.company,
    surname: user?.last_name || ' ',
  });
  // const script = document.querySelector(
  //   'script[src="https://broadcast.comdi.com/widget/static/js/embed-player.js"]'
  // );
  // console.log(script);

  useEffect(() => {
    window.broadcastLoader.createPlayer(document.querySelector(".broadcast__container"))
    // if (script) {
    //   script.setAttribute("data-eventKey", "ebba3g2f");
    //   script.setAttribute("data-widgetKey", "wrj5fmab");
    //   script.setAttribute("data-login", user?.first_name);
    //   script.setAttribute(
    //     "data-registration",
    //     JSON.stringify(userRegistrationData)
    //   );
    //   const broadcast = document.querySelector(".broadcast__container");

    //   broadcast.appendChild(script);
    //   return () => {
    //     if (broadcast.contains(script)) {
    //       broadcast.removeChild(script);
    //     }
    //   };
    // }
  }, []);

  return (
    <>
      <div>
        <ul className="program__list">
          <div 
            className="broadcast__container"
            data-eventKey="ebba3g2f"
            data-widgetKey="wrj5fmab"
            data-login={userName}
            data-registration={userRegistrationData}
          >
          </div>
          {/* {event?.map((item, index) => {
            return <ProgramCard index={index} key={item.id} item={item} />;
          })} */}
        </ul>
      </div>
    </>
  );
}

export default BroadcastColumn;
