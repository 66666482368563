import styled from "styled-components";
import { device } from "../../styles/BreakPoints";
import {Button} from "antd";

export const QuizContainer = styled.div<{ selectedAnswer: boolean }>`
  width: 100%;
  max-width: 740px;
  min-height: 385px;
  background: ${({ theme }) => theme.colors.cardBackground};
  border-radius: 20px;
  padding: 24px 25px 100px;
  margin-bottom: 70px;
  position: relative;
  @media ${device.md} {
    width: 100%;
    padding: 15px 15px 100px 15px;
  }
  button {
    span {
      svg {
        path {
          fill: ${({ selectedAnswer, theme }) =>
  selectedAnswer ? `${theme.colors.buttonText}` : `${theme.colors.darkGray}`};
        }
      }
    }
  }
`

export const LogoContainer = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  @media ${device.md} {
    margin-top: 10px;
    margin-bottom: 20px;
    svg {
      width: 185px;
      height: 80px;
    }
  }
`

export const ButtonWrapper = styled.div`
  position: absolute;
  left: 25px;
  bottom: 25px;
  display: flex;
  gap: 20px;
  button {
    background-color: 
  }
  @media ${device.sm} {
    position: relative;
    bottom: auto;
    left: auto;
    justify-content: flex-start;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 20px;
  }
`

export const NextButton = styled(Button)`
  background-color: rgba(255, 0, 50, 1);
  color: #fff;
  font-family: "MTS Compact";
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  width: 130px;
  height: 52px;
  border: none !important;
  &:hover, &:focus {
    background-color: rgba(255, 0, 50, 1) !important;
    color: #fff !important;
    opacity: 1;
  }
  &:disabled, &:disabled:hover, &:disabled:focus {
    background-color: rgba(188, 195, 208, 0.5);
    color: #969FA8;
    opacity: 1;
  }
`
export const PrevButton = styled(Button)`
  background-color: #F2F3F7;
  color: #1D2023;
  font-family: "MTS Compact";
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  width: 130px;
  height: 52px;
  border: none !important;
  &:hover, &:focus {
    background-color: #F2F3F7 !important;
    color: #1D2023 !important;
    opacity: 1;
  }
`

export const FinishDetail = styled.div`
  color: #969FA8;
`

