// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SeminarTabs_SeminarTabs__XWHCI {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: -24px;
}
@media screen and (max-width: 767px) {
  .SeminarTabs_SeminarTabs__XWHCI {
    gap: 10px;
  }
}
.SeminarTabs_SeminarTabs__XWHCI button {
  border-bottom: 2px solid transparent;
  font-family: "MTS Compact";
  font-size: 17px;
  min-width: 71px;
  padding: 3px 3px 10px;
  letter-spacing: 0.03em;
  margin-right: 7px;
}
@media screen and (max-width: 767px) {
  .SeminarTabs_SeminarTabs__XWHCI button {
    font-size: 14px;
    letter-spacing: 0.03em;
  }
}
.SeminarTabs_SeminarTabs__itemSelected__yeKrZ {
  border-bottom-color: #FF0032 !important;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/components/Seminar/SeminarTabs.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,2BAAA;EACA,uBAAA;EACA,eAAA;EACA,SAAA;EACA,oBAAA;AACF;AAAE;EARF;IASI,SAAA;EAGF;AACF;AAFE;EACE,oCAAA;EACA,0BAAA;EACA,eAAA;EACA,eAAA;EACA,qBAAA;EACA,sBAAA;EACA,iBAAA;AAIJ;AAFI;EATF;IAUI,eAAA;IACA,sBAAA;EAKJ;AACF;AAHE;EACE,uCAAA;EACA,gBAAA;AAKJ","sourcesContent":[".SeminarTabs {\n  width: 100%;\n  display: flex;\n  justify-content: flex-start;\n  align-items: flex-start;\n  flex-wrap: wrap;\n  gap: 15px;\n  margin-bottom: -24px;\n  @media screen and (max-width: 767px) {\n    gap: 10px;\n  }\n  button {\n    border-bottom: 2px solid transparent;\n    font-family: \"MTS Compact\";\n    font-size: 17px;\n    min-width: 71px;\n    padding: 3px 3px 10px;\n    letter-spacing: 0.03em;\n    margin-right: 7px;\n\n    @media screen and (max-width: 767px) {\n      font-size: 14px;\n      letter-spacing: 0.03em;\n    }\n  }\n  &__itemSelected {\n    border-bottom-color: #FF0032 !important;\n    font-weight: 500;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SeminarTabs": `SeminarTabs_SeminarTabs__XWHCI`,
	"SeminarTabs__itemSelected": `SeminarTabs_SeminarTabs__itemSelected__yeKrZ`
};
export default ___CSS_LOADER_EXPORT___;
