import styled from 'styled-components'
import { device } from '../../styles/BreakPoints'
import { Button } from "antd";

export const Heading = styled.h2`
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
`

export const DetailText = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
`

export const SelectButtonContainer = styled.div`
  width: 100%;
  @media ${device.md} {
    row-gap: 20px;
    column-gap: 20px;
    max-width: 100%;
  }
`

export const ProfileWrapper = styled.div`
  width: 100%;
`

export const BoxHeader = styled.h4`
  padding: 0;
  font-family: "MTS Text";
  font-size: 24px;
  font-weight: 500;
  line-height: 33.7px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-bottom: 15px;
`

export const NextButton = styled(Button)`
  background-color: rgba(255, 0, 50, 1) !important;
  font-family: "MTS Compact";
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  width: 130px;
  height: 52px;
  border: none !important;
`

export interface SelectButtonProps {
  active: boolean
  disabled?: boolean
}

export const SelectButton = styled.div<SelectButtonProps>`
  background-color: rgba(255, 0, 50, 1) !important;
  font-family: "MTS Compact";
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  width: 130px;
  height: 52px;
  border: none !important;
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  @media ${device.md} {
    tap-highlight-color: transparent;
    -webkit-tap-highlight-color: transparent;
  }
`

export const SelectButtonText = styled.span`
  font-size: 18px;
  font-weight: 600;
  margin-left: 10px;
  @media ${device.md} {
    font-size: 16px;
    font-weight: 500;
  }
`
