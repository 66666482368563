import { createSlice } from '@reduxjs/toolkit'

const login = createSlice({
	name: 'form',
	initialState: {
		isLogin: false,
	},
	reducers: {
		setIsLogin(state, action) {
			state.isLogin = action.payload
		},
	}
})

export default login.reducer

export const { 
	setIsLogin, 
} = login.actions