/* eslint-disable no-restricted-globals */
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux';
import { setIsOpenModal, setModalCalendar } from '../../../redux/modal/modal';
import { useSelector } from 'react-redux';
import { getCalendarLinks } from '../../../api/events/events';
import google from '../../../images/google.png'
import apple from '../../../images/apple.png'
import other from '../../../images/other.png'

function ModalCalendar () {
    const dispatch = useDispatch();
    const token = localStorage.getItem("accessToken");
    const { calendarLinks } = useSelector((state) => state.modal);

    useEffect(() => {
        getCalendarLinks(token, dispatch);
	}, []);

    return (
        <div className="modal__calendar">
            <div className='modal__calendar-box'>
                <h3 className='modal__header-calendar'>Добавить напоминание</h3>
                <button
                    className='modal__close-calendar'
                    onClick={ () => { dispatch(setIsOpenModal(false)); dispatch(setModalCalendar(false)); }}
                />
            </div>
            <span className='modal-calendar-span'>Загрузите файл с мероприятиями в ваш календарь</span>
            <a 
                className='modal__card'
                href={calendarLinks.google_link}
                target="_blank" 
                rel="noopener noreferrer"
            >
                <img
                    src={google}
                    alt='google calendar'
                />
                <span className='modal__calendar-name'>Google Calendar</span>
            </a>
            <a 
                className='modal__card'
                href={calendarLinks.schedule_link}
                target="_blank" 
                rel="noopener noreferrer"
            >
                <img
                    src={apple}
                    alt='apple calendar'
                />
                <span className='modal__calendar-name'>Apple Calendar</span>
            </a>
            <a 
                className='modal__card'
                href={calendarLinks.schedule_link}
                target="_blank" 
                rel="noopener noreferrer"
            >
                <img
                    src={other}
                    alt='other calendar'
                />
                <span className='modal__calendar-name'>Other Calendar App</span>
            </a>
        </div>
    )
}

export default ModalCalendar;