import {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react'

interface AuthCountdownProps {
  totalTime: number
}

export const useAuthCountdown = ({
  totalTime: number,
}: AuthCountdownProps) => {
  const [verificationCode, setVerificationCode] = useState('')
  const [isStartCountdown, setIsStartCountdown] = useState(false)
  const [isButtonSubmitDisabled, setIsButtonSubmitDisabled] = useState(true)

  const onFinishCountdown = useCallback(() => {
    setIsStartCountdown(false)
  }, [setIsStartCountdown])

  const onRepeatSendCode = useCallback(() => {
    setIsStartCountdown(true)
    setVerificationCode('')
  }, [setIsStartCountdown, setVerificationCode])

  useEffect(() => {
    if (verificationCode.length === 4) {
      setIsButtonSubmitDisabled(false)
    } else {
      setIsButtonSubmitDisabled(true)
    }
  }, [verificationCode])

  useEffect(() => {
    if (isStartCountdown) {
      setVerificationCode('')
      setIsButtonSubmitDisabled(false)
    }
  }, [isStartCountdown])

  return {
    verificationCode,
    setVerificationCode,
    isStartCountdown,
    onFinishCountdown,
    onRepeatSendCode,
    isButtonSubmitDisabled,
    setIsButtonSubmitDisabled,
  }
}
