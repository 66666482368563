import styled from 'styled-components'
import { device } from '../../../../styles/BreakPoints'
interface ButtonType {
  outline?: boolean
  bold?: boolean
  big?: boolean
}

export const SeminarAssignButtonStyle = styled.button.attrs(({ outline, bold, big }: ButtonType) => ({
  outline,
  bold,
  big,
}))`
  font-weight: ${({ bold }) => (bold ? '700' : '500')};
  color: #fff;
  background: #FF0032;
  border: none;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  font-size: 17px;
  line-height: 1;
  letter-spacing: 0.025em;
  @media ${device.md} {
    tap-highlight-color: transparent;
    -webkit-tap-highlight-color: transparent;
  }
  &:disabled {
    background: ${({ theme }) => theme.colors.disabledButton};
    color: ${({ theme }) => theme.colors.darkGray};
    cursor: not-allowed;
    transform: unset;
    box-shadow: unset;
  }
`
