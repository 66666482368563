import { FC } from 'react'

import CodeSnippet from '../../ui/CodeSnippet'
import Answer from '../Answer'
import QuizImage from '../../ui/QuizImage'
import { AnswerType, Choice } from '../../../data/QuizQuestions'
import {
  QuestionContainer,
  AnswersContainer,
  QuestionStyle,
} from './styled'

interface QuestionTypes {
  question: string
  code?: string
  image?: string
  type: string
  answers: AnswerType[]
  selectedAnswer: AnswerType[]
  handleAnswerSelection: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void
}

const Question: FC<QuestionTypes> = ({
  question,
  code,
  image,
  type,
  answers,
  selectedAnswer,
  handleAnswerSelection,
}) => {

  return (
    <QuestionContainer>
      <QuestionStyle>{question}</QuestionStyle>
      {/* if question contains code snippet then show code */}
      {code && <CodeSnippet code={code} language="javascript" />}
      {/* if question contains an image */}
      {image && <QuizImage image={image} />}
      <AnswersContainer>
        {answers.map((answer, index) => (
          <Answer
            answer={answer}
            index={index}
            key={index}
            onChange={(e) => handleAnswerSelection(e, index)}
            type={type}
            selectedAnswer={selectedAnswer}
          />
        ))}
      </AnswersContainer>
    </QuestionContainer>
  )
}

export default Question
