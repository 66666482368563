import React from "react";
import "../../CentralColumn.scss";
import "../../EventsColumn.scss";
import { DownloadLinkIcon } from "../../../ui/Icons/DownloadLinkIcon";
import {config} from "../../../../config/config";

function EventsColumn() {
  return (
    <div className={`flex space-x-4 justify-between`}>
      <h2 className={`text-[#1D2023] text-[20px] md:text-[30px] leading-[28px] font-medium mb-[29px]`}>
        Программа
      </h2>
      {false && (
        <a href={config.docs.scheduleDownload} target="_blank" title="Скачать программу в pdf" className={`flex text-[#0070e5] text-[17px]`}>
          <DownloadLinkIcon className={`mr-0.5 w-[24px] h-[24px]`} />&nbsp;<span className={`whitespace-nowrap`}>Скачать программу</span>
        </a>
      )}
    </div>
  );
}

export default EventsColumn;
