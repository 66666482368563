export const handleMouseDown = (e, setIsCodeSuccess, section) => {
  // Получаем ссылку на элемент, который будет перемещаться
  const mouseDownBlock = e.target;

  // Записываем начальные координаты курсора относительно элемента
  const startX = e.clientX - mouseDownBlock.getBoundingClientRect().left;
  const startY = e.clientY - mouseDownBlock.getBoundingClientRect().top;

  // Обработчик движения мыши перетаскивает элемент
  const handleMouseMove = (event) => {
    moveMouseDownBlock(event.pageX, event.pageY);
  };

  // По окончанию перетаскивания удаляем обработчики
  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  // Функция перемещения элемента с учётом начальных координат
  function moveMouseDownBlock(pageX, pageY) {
    mouseDownBlock.style.left = pageX - startX + "px";
    mouseDownBlock.style.top = pageY - startY + "px";
  }
  if (section === "speaker") {
    if (window.eventSetCodeSpeaker) {
      console.log(window.eventSetCodeSpeaker().index);
      console.log(e.target.id);
      if (
        e.target.id === `speaker__secret-${window.eventSetCodeSpeaker().index}`
      ) {
        const speaker = document.querySelector(".speaker__codeBlock");
        const item = document.getElementById(e.target.id);
        if (!speaker) {
          const newElement = document.createElement("div");
          newElement.className = "speaker__image";
          newElement.innerHTML = `
                      <div class="speaker__codeBlock"></div>`;
          item.insertAdjacentElement("afterend", newElement);
          const speakerEnd = document.querySelector(".speaker__codeBlock");
          speakerEnd.innerHTML = `${window.eventSetCodeSpeaker().code}`;
        }
        mouseDownBlock.style.position = "absolute";
        mouseDownBlock.style.zIndex = "1000";
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
      }
    }
  } else {
    setIsCodeSuccess(true);
    // Устанавливаем стили для абсолютного позиционирования
    mouseDownBlock.style.position = "absolute";
    mouseDownBlock.style.zIndex = "1000";
    // Добавляем обработчики движения и отпускания мыши
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  }
};
