import React, { Suspense, useState } from "react";
import "./globalStyles/global.scss";
import AppRoutes from "./routes/AppRoutes";
import { ThemeProvider } from 'styled-components'
import { GlobalStyles } from './styles/Global'
import { themes } from './styles/Theme'
import {useUtm} from "./hooks/useUtm";

function App() {
  const [currentTheme, setCurrentTheme] = useState(() => {
    const savedTheme = localStorage.getItem('theme')
    return savedTheme || 'light'
  })

  const toggleTheme = (e) => {
    const { checked } = e.target
    setCurrentTheme(checked ? 'dark' : 'light')
    localStorage.setItem('theme', checked ? 'dark' : 'light')
  }
  const theme = currentTheme === 'light' ? themes.light : themes.dark

  const { initUtm } = useUtm()
  initUtm()

  // const isLogin = localStorage.getItem("login");

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Suspense fallback={<div>Идет загрузка...</div>}>
        <AppRoutes />
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
