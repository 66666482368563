// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AuthByPhone_wrapperAuthByPhone__OC821 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.AuthByPhone_wrapperAuthByPhone__header__CfTXG {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}
.AuthByPhone_wrapperAuthByPhone__backLink__jCcE9 {
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 35px;
  padding: 0;
}
.AuthByPhone_wrapperAuthByPhone__title__\\+N\\+oI {
  display: inline-block;
  margin-bottom: 32px;
  margin-top: 2px;
  color: #000;
  text-align: center;
  font-family: "MTS Compact";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 116.667% */
}
.AuthByPhone_wrapperAuthByPhone__footer__4huFx {
  margin-top: 5px;
}
.AuthByPhone_wrapperAuthByPhone__formWrapper__S05o5 {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.AuthByPhone_wrapperAuthByPhone__formWrapper__formItem__BYkDn {
  margin-bottom: 25px;
}
.AuthByPhone_wrapperAuthByPhone__formWrapper__itemInput__wHAVY .AuthByPhone_ant-input-password__CGjhB {
  padding: 3px 12px;
}
.AuthByPhone_wrapperAuthByPhone__formWrapper__itemInput__password__n27Lg {
  margin-bottom: 26px;
}
.AuthByPhone_wrapperAuthByPhone__formWrapper__itemInput__password__n27Lg input {
  height: 42px;
}
.AuthByPhone_wrapperAuthByPhone__formWrapper__itemInput__password__n27Lg > span {
  text-align: center;
  height: 40px;
}
.AuthByPhone_wrapperAuthByPhone__OC821 .AuthByPhone_buttonPrev__x9e9O {
  text-align: center;
  cursor: pointer;
  color: #0070e5;
}`, "",{"version":3,"sources":["webpack://./src/components/Auth/AuthByPhone/AuthByPhone.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;AACE;EACE,kBAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;AACJ;AACE;EACE,kBAAA;EACA,OAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;AACJ;AAEE;EACE,qBAAA;EACA,mBAAA;EACA,eAAA;EACA,WAAA;EACA,kBAAA;EACA,0BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,aAAA;AAAJ;AAEE;EACE,eAAA;AAAJ;AAGE;EACE,WAAA;EACA,aAAA;EACA,sBAAA;AADJ;AAGI;EACE,mBAAA;AADN;AAMM;EACE,iBAAA;AAJR;AAMM;EACE,mBAAA;AAJR;AAKQ;EACE,YAAA;AAHV;AAOQ;EACE,kBAAA;EACA,YAAA;AALV;AAUE;EACE,kBAAA;EACA,eAAA;EACA,cAAA;AARJ","sourcesContent":[".wrapperAuthByPhone {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  &__header {\n    position: relative;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n  }\n  &__backLink {\n    position: absolute;\n    left: 0;\n    top: 0;\n    width: 24px;\n    height: 35px;\n    padding: 0;\n  }\n\n  &__title {\n    display: inline-block;\n    margin-bottom: 32px;\n    margin-top: 2px;\n    color: #000;\n    text-align: center;\n    font-family: \"MTS Compact\";\n    font-size: 24px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 28px; /* 116.667% */\n  }\n  &__footer {\n    margin-top: 5px;\n  }\n\n  &__formWrapper {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n\n    &__formItem {\n      margin-bottom: 25px;\n    }\n\n\n    &__itemInput {\n      .ant-input-password{\n        padding: 3px 12px;\n      }\n      &__password {\n        margin-bottom: 26px;\n        & input {\n          height: 42px;\n        }\n        span:first-of-type{\n        }\n        & > span {\n          text-align: center;\n          height: 40px;\n        }\n      }\n    }\n  }\n  .buttonPrev {\n    text-align: center;\n    cursor: pointer;\n    color: #0070e5;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperAuthByPhone": `AuthByPhone_wrapperAuthByPhone__OC821`,
	"wrapperAuthByPhone__header": `AuthByPhone_wrapperAuthByPhone__header__CfTXG`,
	"wrapperAuthByPhone__backLink": `AuthByPhone_wrapperAuthByPhone__backLink__jCcE9`,
	"wrapperAuthByPhone__title": `AuthByPhone_wrapperAuthByPhone__title__+N+oI`,
	"wrapperAuthByPhone__footer": `AuthByPhone_wrapperAuthByPhone__footer__4huFx`,
	"wrapperAuthByPhone__formWrapper": `AuthByPhone_wrapperAuthByPhone__formWrapper__S05o5`,
	"wrapperAuthByPhone__formWrapper__formItem": `AuthByPhone_wrapperAuthByPhone__formWrapper__formItem__BYkDn`,
	"wrapperAuthByPhone__formWrapper__itemInput": `AuthByPhone_wrapperAuthByPhone__formWrapper__itemInput__wHAVY`,
	"ant-input-password": `AuthByPhone_ant-input-password__CGjhB`,
	"wrapperAuthByPhone__formWrapper__itemInput__password": `AuthByPhone_wrapperAuthByPhone__formWrapper__itemInput__password__n27Lg`,
	"buttonPrev": `AuthByPhone_buttonPrev__x9e9O`
};
export default ___CSS_LOADER_EXPORT___;
