import React from 'react'
import s from './Popup.module.scss'
import logo from "../../images/logo.svg";
import { AppLogo } from "../ui/Icons/AppLogo";

const Popup = ({children}) => {
  return (
    <div className={s.popup}>
      <div className={s.popup__logoImg}>
        <AppLogo />
      </div>
      {children}
    </div>
  )
}

export default Popup
