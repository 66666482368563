import { createSlice } from '@reduxjs/toolkit'

const button = createSlice({
	name: 'button',
	initialState: {
		activeButton: "Main",
	},
	reducers: {
		setActiveButton(state, action) {
			state.activeButton = action.payload
		},
	}
})

export default button.reducer

export const { 
	setActiveButton,

} = button.actions