import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import s from "./ThirdPopupRegistration.module.scss";
import { Button, Checkbox, Form, Input, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { regexPassword } from "../../../../constants/constants";
import { useDispatch } from "react-redux";
import { registerUser } from "../../../../api/auth/auth";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";

const ThirdPopupRegistration = ({ handleInputChange, formData }) => {
  const {
    role,
    level,
    firstName,
    lastName,
    phone,
    password,
    confirmPassword,
    isPersonalDataPolicyChecked,
    receive_advertising,
  } = formData;
  const [form] = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [disabledButton, setDisabledButton] = useState(true);

  useEffect(() => {
    // let onlyphone = phone.replace(/[^0-9]/g, "");
    if (password.length && confirmPassword && isPersonalDataPolicyChecked) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }, [
    role,
    level,
    firstName,
    lastName,
    phone,
    password,
    confirmPassword,
    isPersonalDataPolicyChecked,
    receive_advertising,
  ]);

  // console.log(phoneNumber)
  // const validation = (rule, value) => {
  //   let onlyphone = value.replace(/[^0-9]/g, "");
  //   if (onlyphone.length !== 11) {
  //     console.log(onlyphone);
  //     console.log(onlyphone.length);
  //     return Promise.reject(new Error("Номер введен не полностью"));
  //   } else {
  //     return Promise.resolve();
  //   }
  // };
  return (
    <div className={s.thirdPopupRegistration}>
      <h3 className={s.thirdPopupRegistration__title}>Регистрация</h3>
      <Form
        // onFinish={onFinish}
        form={form}
        name="basic"
        initialValues={{ remember: true }}
        autoComplete="off"
        className={s.thirdPopupRegistration__formWrapper}
      >
        <Form.Item name="role">
          <Input placeholder="E-Mail*" defaultValue={formData.email} disabled />
        </Form.Item>

        {/* <Form.Item
          name="level"
          rules={[
            { type: "string", required: true, message: "Выберете уровень" },
          ]}
        >
          <select
            className={s.select}
            name="level"
            onChange={(e) => handleInputChange("level", e)}
            style={
              level
                ? { color: "rgba(0, 0, 0, 1)" }
                : { color: "rgba(98, 108, 119, 1)" }
            }
            defaultValue=""
          >
            <option
              className="s.option"
              disabled
              defaultValue
              hidden
              selected="selected"
              value=""
            >
              Уровень*
            </option>
            <option className="s.option" value="STUDENT">
              Студент
            </option>
            <option className="s.option" value="JUNIOR">
              Junior
            </option>
            <option className="s.option" value="MIDDLE">
              Middle
            </option>
            <option className="s.option" value="SENIOR">
              Senior
            </option>
            <option className="s.option" value="TEAM_LEAD">
              Team Lead
            </option>
            <option className="s.option" value="STO">
              CTO
            </option>
            <option className="s.option" value="OTHER">
              Другое
            </option>
          </select>
        </Form.Item> */}

        {/* <Form.Item
          name="firstName"
          rules={[{ type: "string", required: true, message: "Введите имя" }]}
        >
          <Input
            placeholder="Имя*"
            name="firstName"
            onChange={(e) => handleInputChange("firstName", e)}
          />
        </Form.Item> */}

        {/* <Form.Item
          name="lastName"
          rules={[
            { type: "string", required: true, message: "Введите фамилию" },
          ]}
        >
          <Input
            placeholder="Фамилия*"
            name="lastName"
            onChange={(e) => handleInputChange("lastName", e)}
          />
        </Form.Item> */}

        {/* <Form.Item name="phone" rules={[{ validator: validation }]}>
          <InputMask
            name="phone"
            placeholder="Телефон*"
            mask="+7 (999) 999-99-99"
            maskChar=" "
            onChange={(e) => handleInputChange("phone", e)}
          />
        </Form.Item> */}

        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message:
                "Пароль должен быть не короче 8 символов, содержать строчную латинскую букву, заглавную латинскую букву, спецсимвол и цифру",
              validator: (_, value) =>
                regexPassword.test(password)
                  ? Promise.resolve()
                  : Promise.reject(new Error("Неверный пароль")),
            },
          ]}
          hasFeedback
        >
          <Input.Password
            placeholder="Пароль*"
            style={{ background: "#BCC3D080" }}
            onChange={(e) => handleInputChange("password", e)}
          />
        </Form.Item>

        <Form.Item
          name="confirm"
          dependencies={["password"]}
          rules={[
            {
              //required: true,
              message: "Пожалуйста подтвердите ваш пароль",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Пароль, который вы ввели, не соответствует")
                );
              },
            }),
          ]}
        >
          <Input.Password
            placeholder="Повторите пароль*"
            style={{ background: "#BCC3D080" }}
            onChange={(e) => handleInputChange("confirmPassword", e)}
          />
        </Form.Item>

        <Form.Item>
          <div
            className={s.thirdPopupRegistration__formWrapper__itemInput__policy}
          >
            <Checkbox
              checked={formData.isPersonalDataPolicyChecked}
              onChange={(e) =>
                handleInputChange("isPersonalDataPolicyChecked", e)
              }
            />
            <span>
              Используя этот сайт, я согласен на{" "}
              <a
                href="https://docs.google.com/document/d/1tseyo53JSw9HLs1dQjsybhrINa0Qyzji/"
                rel="noreferrer"
                style={{ color: "#0070E5", textDecoration: "underline" }}
                target="_blank"
              >
                обработку персональных данных
              </a>{" "}
              и с{" "}
              <a
                href="https://moskva.mts.ru/about/investoram-i-akcioneram/korporativnoe-upravlenie/dokumenti-pao-mts/politika-obrabotka-personalnih-dannih-v-pao-mts"
                style={{ color: "#0070E5", textDecoration: "underline" }}
                rel="noreferrer"
                target="_blank"
              >
                Политикой «Обработка персональных данных» в ПАО «МТС» - Москва
                (mts.ru)
              </a>
            </span>
          </div>
          <div
            className={s.thirdPopupRegistration__formWrapper__itemInput__policy}
          >
            <Checkbox
              checked={formData.receive_advertising}
              onChange={(e) => handleInputChange("receive_advertising", e)}
            />
            <span>
              Я согласен на получение{" "}
              <a
                href="https://docs.google.com/document/d/1p2Fl-aeR4uI03NQjlKESvOtnir6YwdSZco3TwvYnejo"
                rel="noreferrer"
                style={{ color: "#0070E5", textDecoration: "underline" }}
                target="_blank"
              >
                рекламно-информационных рыссылок
              </a>
            </span>
          </div>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={disabledButton}
            className="buttonSend"
            onClick={() => {
              try {
                window._tmr.push({
                  type: "reachGoal",
                  id: 3500369,
                  goal: "completion_of_registration",
                });
                window.ar_callCounter(
                  "oformleniye_registratsii_stranitse_registratsii"
                );
              } catch (e) {
                console.log(e);
              } finally {
                registerUser(formData, dispatch, toast);
              }
            }}
          >
            Завершить регистрацию
          </Button>
        </Form.Item>

        <div className={s.thirdPopupRegistration__wrapperButtonFooter}>
          <button className="createAccount" onClick={() => navigate("/login")}>
            Уже есть аккаунт
          </button>
        </div>
      </Form>
    </div>
  );
};

export default ThirdPopupRegistration;
