import React, { useEffect, useState } from "react";
import "./Promo.scss";
import { useSelector } from "react-redux";
import { PromoAnnouncement } from "./PromoAnnouncement";
import { PromoVerified } from "./PromoVerified";
import { PromoComplete } from "./PromoComplete";
import { PromoPerform } from "./PromoPerform";
import {PromoUncomplete} from "./PromoUncomplete";
import {PromoProfileCompleted} from "./PromoProfileCompleted";
import { PromoQr } from "./PromoQr";
import { PromoRefuse } from "./PromoRefuse";

// 'enum' declarations can only be used in TypeScript files
const promoTypes = {
  announcement: "announcement", // Квиз только анонсирован и недоступен для прохождения
  verified: "verified", // Квиз доступен для прохождения
  profile: "profile", // Заполнен профиль, но квиз не пройден
  complete: "complete", // Квиз отвечен
  uncomplete: "uncomplete", // Квиз отвечен не полностью, не уложился по времени
  perform: "perform", // Квиз брошен - начат, но не закончен
  invite: "invite",
  refuse: "refuse",
  approved: "approved",
}

export const PromoWrapper = () => {
  const { user } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const [promoType, setPromoType] = useState(promoTypes.announcement);

  useEffect(() => {
    if (!user.quizProcess && !!user?.address && !!user?.company && !!user?.secondName && !!user?.stack) {
      setPromoType(promoTypes.profile);
    } else if (user?.status === 'invite' || user?.status === 'approved') {
      setPromoType(user?.status)
    } else if (user?.status === 'refuse') {
      setPromoType(user?.status)
    } else if (user?.id && !user.quizProcess) { // quizProcess = null
      setPromoType(promoTypes.verified);
    } else if (user?.quizProcess?.status) {
      setPromoType(user?.quizProcess?.status); // user?.quizProcess?.status
    } else {
      setPromoType(promoTypes.refuse)
    }
  }, [user]);

  useEffect(() => {
    if (user?.status) {
      setLoading(false);
    }
  }, [user?.status]);

  if (loading) {
    return <div className="promo"></div>
  }

  return (
    <div className="promo">
      {!loading && promoType === promoTypes.announcement
        ? <PromoAnnouncement />
        : promoType === promoTypes.profile
          ? <PromoProfileCompleted />
          : !loading && promoType === promoTypes.refuse
            ? <PromoRefuse />
            : !loading && (promoType === promoTypes.invite || promoType === promoTypes.approved)
              ? <PromoQr />
              : promoType === promoTypes.verified
                ? <PromoVerified />
                : promoType === promoTypes.complete
                  ? <PromoComplete />
                  : promoType === promoTypes.uncomplete
                    ? <PromoUncomplete />
                    : promoType === promoTypes.perform
                      ? <PromoPerform />
                      : '-'
      }
    </div>
  );
}
