import React, { useEffect } from 'react'
import './Codenrock.scss'
import { useNavigate } from "react-router-dom";
import { getCodenrockToken } from '../../api/auth/auth';


function Codenrock() {
    const navigate = useNavigate();
    const isLogin = localStorage.getItem("login");
    const token = window.location?.search?.slice(14);

    useEffect(() => {
        getCodenrockToken(token);
    }, []);


    return (
        <div className='codenrock'>
            <button
                onClick={ () => isLogin ? navigate('/account') : navigate('/login')}
            >На главную</button>
        </div>
    )
}

export default Codenrock;