import { createSlice } from '@reduxjs/toolkit'

const offline = createSlice({
	name: 'form',
	initialState: {
		registrationStage: 'first',
	},
	reducers: {
		setRegistrationStage(state, action) {
			state.registrationStage = action.payload
		},
	}
})

export default offline.reducer

export const { 
	setRegistrationStage, 
} = offline.actions