// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AuthVerification_wrapperVerification__7Ln3w {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.AuthVerification_wrapperVerification__header__anhoP {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}
.AuthVerification_wrapperVerification__backLink__Ts34L {
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 35px;
  padding: 0;
}
.AuthVerification_wrapperVerification__title__Z4DCr {
  display: inline-block;
  margin-bottom: 32px;
  margin-top: 2px;
  color: #000;
  text-align: center;
  font-family: "MTS Compact";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 116.667% */
}
.AuthVerification_wrapperVerification__formItem__NnxIc {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}
.AuthVerification_wrapperVerification__formItem__NnxIc .AuthVerification_buttonSend__ChIG0 {
  width: 100%;
  font-weight: 500;
}
.AuthVerification_wrapperVerification__7Ln3w .AuthVerification_buttonPrev__NIeM4 {
  text-align: center;
  cursor: pointer;
  color: #0070e5;
}`, "",{"version":3,"sources":["webpack://./src/components/Auth/AuthVerification/AuthVerification.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;AACE;EACE,kBAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;AACJ;AACE;EACE,kBAAA;EACA,OAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;AACJ;AAEE;EACE,qBAAA;EACA,mBAAA;EACA,eAAA;EACA,WAAA;EACA,kBAAA;EACA,0BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,aAAA;AAAJ;AAGE;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AADJ;AAEI;EACE,WAAA;EACA,gBAAA;AAAN;AAGE;EACE,kBAAA;EACA,eAAA;EACA,cAAA;AADJ","sourcesContent":[".wrapperVerification {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  &__header {\n    position: relative;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n  }\n  &__backLink {\n    position: absolute;\n    left: 0;\n    top: 0;\n    width: 24px;\n    height: 35px;\n    padding: 0;\n  }\n\n  &__title {\n    display: inline-block;\n    margin-bottom: 32px;\n    margin-top: 2px;\n    color: #000;\n    text-align: center;\n    font-family: \"MTS Compact\";\n    font-size: 24px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 28px; /* 116.667% */\n  }\n\n  &__formItem {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 25px;\n    .buttonSend {\n      width: 100%;\n      font-weight: 500;\n    }\n  }\n  .buttonPrev {\n    text-align: center;\n    cursor: pointer;\n    color: #0070e5;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperVerification": `AuthVerification_wrapperVerification__7Ln3w`,
	"wrapperVerification__header": `AuthVerification_wrapperVerification__header__anhoP`,
	"wrapperVerification__backLink": `AuthVerification_wrapperVerification__backLink__Ts34L`,
	"wrapperVerification__title": `AuthVerification_wrapperVerification__title__Z4DCr`,
	"wrapperVerification__formItem": `AuthVerification_wrapperVerification__formItem__NnxIc`,
	"buttonSend": `AuthVerification_buttonSend__ChIG0`,
	"buttonPrev": `AuthVerification_buttonPrev__NIeM4`
};
export default ___CSS_LOADER_EXPORT___;
