/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "./ProgramButtons.scss";
import download from "../../images/Download.svg";
import { useSelector, useDispatch } from "react-redux";
import { getEvents } from "../../api/events/events";
import schedule from "../../docs/schedule.pdf";
import { setActiveButton } from "../../redux/button/button";

function ProgramButtons({ event }) {
  const { tabs } = useSelector((state) => state.event);
  const { activeButton } = useSelector((state) => state.button);
  //   const [activeButton, setActiveButton] = useState("Main")
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");

  return (
    <>
      {event === 1 ? (
        <div className="program__buttons">
          <div className="program__buttons-box">
            {tabs.map((item) => (
              <button
                className={`program__button ${
                  activeButton === item.title && "program__button_active"
                }`}
                onClick={() => {
                  dispatch(setActiveButton(item.title));
                  getEvents(token, dispatch, item.title);
                }}
                key={item.id}
              >
                {item.title}
              </button>
            ))}
          </div>
          <a className="program__download" href={schedule} download="schedule">
            <img src={download} alt="download" />
            Скачать расписание
          </a>
        </div>
      ) : (
        <>
          {tabs.map((btn, _idx) => (
            <button
              className={`ml-[8px] rounded-[8px] py-[14px] px-5 font-medium text-[17px] leading-[24px] flex items-center justify-center cursor-pointer xl:whitespace-normal whitespace-nowrap ${
                btn.title == activeButton
                  ? "bg-[#1D2023] text-[#FAFAFA]"
                  : "bg-transparent text-[#1D2023]"
              }`}
              key={btn.id}
              onClick={() => {
                dispatch(setActiveButton(btn.title));
                getEvents(token, dispatch, btn.title);
              }}
            >
              {btn.title}
            </button>
          ))}
        </>
      )}
    </>
  );
}

export default ProgramButtons;
