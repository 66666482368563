import { FC, useCallback, useEffect, useState } from 'react'

import { CheckIcon, TimerIcon } from '../../config/icons'
import { useQuiz } from '../../context/QuizContext'
import { useTimer } from '../../hooks'
import { PageCenter } from '../../styles/Global'
import ModalWrapper from '../ui/ModalWrapper'
import Question from './Question'
import QuizHeader from './QuizHeader'
import {
  QuizContainer,
  ButtonWrapper,
  NextButton,
  PrevButton,
} from './styled'
import { useNavigate } from 'react-router-dom'
import { AnswerType, Choice } from '../../data/QuizQuestions'
import { sendAnswer, sendQuizCompleted } from '../../api/quiz/quiz'
import { useDispatch, useSelector } from 'react-redux'
import { useLogBot } from '../hooks/useLogBot'

const QuestionScreen: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const token = localStorage.getItem('accessToken')
  const { user } = useSelector((state: any) => state?.user)
  const {
    questions,
    totalQuestions
  } = useSelector((state: any) => state?.quiz);
  const [activeQuestion, setActiveQuestion] = useState<number>(0)
  const [selectedAnswer, setSelectedAnswer] = useState<AnswerType[]>([])
  const [showTimerModal, setShowTimerModal] = useState<boolean>(false)
  const [showResultModal, setShowResultModal] = useState<boolean>(false)

  // Логирование ошибок
  const [errorStatus, setErrorStatus] = useState<string | number | undefined>('');
  const [isDisableNext, setIsDisableNext] = useState(true);

  const {
    quizDetails,
    result,
    setResult,
    setCurrentScreen,
    timer,
    setTimer,
    setEndTime,
  } = useQuiz()

  const currentQuestion = questions[activeQuestion]

  const { sendLogBot } = useLogBot()

  if (!currentQuestion || !currentQuestion?.question) {
    setErrorStatus('Вопрос не загружен');
  }

  useEffect(() => {
    setIsDisableNext(selectedAnswer.length === 0)
  }, [selectedAnswer])

  const { question, type, quizAnswers, code, image } = currentQuestion

  useEffect(() => {
    if (!currentQuestion || errorStatus) {
      sendLogBot({
        message: {
          userId: user?.id,
          questions: questions?.map((item: any) => item?.id),
          activeQuestion: activeQuestion,
          status: errorStatus || 'Question is empty',
        }
      })
    }
  }, [currentQuestion, errorStatus])

  const handleQuizComplete = useCallback(async () => {
    await sendQuizCompleted({
      accessToken: token,
      dispatch,
    });
  }, [token]);

  const onClickNext = async () => {
    setIsDisableNext(true) // Дизейблим кнопку "Далее", чтобы предотвратить артефакт с повторным нажатием

    if (!selectedAnswer ||
      !Array.isArray(selectedAnswer) ||
      !selectedAnswer[0]?.id) return

    const response = await sendAnswer({
      accessToken: token,
      quizAnswerId: selectedAnswer[0]?.id,
    })

    if (response?.status !== 204) {
      // В случае ошибки при отправке ответа отправляем сообщение в телеграм-бот
      setErrorStatus(response?.status);
    }

    setResult([...result, { ...currentQuestion, selectedAnswer }])

    if (activeQuestion !== questions.length - 1) {
      setActiveQuestion((prev) => prev + 1)
    } else {
      await handleQuizComplete();
      // how long does it take to finish the quiz
      const timeTaken = quizDetails.totalTime - timer
      setEndTime(timeTaken)
      setShowResultModal(true)
    }
    setSelectedAnswer([])
  }

  const onClickPrev = () => {
    // TODO добавить логику кнопки Назад
    if (activeQuestion > 0) {
      setActiveQuestion((prev) => prev - 1)
    }
    setSelectedAnswer([])
  }

  const handleAnswerSelection = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, checked } = e.target
    const selected = currentQuestion.quizAnswers?.data.find((item: Choice) => item.id === name)

    if (selected) {
      setSelectedAnswer([selected])
    }
  }

  const handleModal = () => {
    // Скрываем окно результата
    // setCurrentScreen(ScreenTypes.ResultScreen)
    navigate('/account')
    document.body.style.overflow = 'auto'
  }

  // to prevent scrolling when modal is opened
  useEffect(() => {
    if (showTimerModal || showResultModal) {
      document.body.style.overflow = 'hidden'
    }
  }, [showTimerModal, showResultModal])

  // timer hooks, handle conditions related to time
  useTimer(timer, quizDetails, setEndTime, setTimer, setShowTimerModal, showResultModal)

  return (
    <PageCenter>
      <QuizContainer selectedAnswer={selectedAnswer.length > 0}>
        <QuizHeader
          activeQuestion={activeQuestion}
          totalQuestions={totalQuestions}
          timer={timer}
        />
        <Question
          question={question}
          code={code}
          image={image}
          answers={quizAnswers?.data}
          type={type}
          handleAnswerSelection={handleAnswerSelection}
          selectedAnswer={selectedAnswer}
        />
        <ButtonWrapper>
          {
            // Скрываем кнопку Назад
          }
          {activeQuestion < 0 && (
            <PrevButton onClick={onClickPrev}>
              Назад
            </PrevButton>
          )}
          <NextButton onClick={onClickNext} disabled={isDisableNext}>
            {activeQuestion === questions.length - 1 ? 'Завершить' : 'Далее'}
          </NextButton>
        </ButtonWrapper>
      </QuizContainer>
      {/* timer or finish quiz modal*/}
      {(showTimerModal || showResultModal) && (
        <ModalWrapper
          title={showResultModal ? 'Готово!' : 'Время вышло!'}
          subtitle={`Ваши ответы на модерации. Ожидайте новостей на email`}
          onClick={handleModal}
          icon={showResultModal ? <CheckIcon /> : <TimerIcon />}
          buttonTitle="На главную"
        />
      )}
    </PageCenter>
  )
}

export default QuestionScreen
