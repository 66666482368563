/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { scheduleToggle } from "../../api/events/events";
import "./ProgramCard.scss";
import clock from "../../images/Clock.svg";
import bookmarkInactive from "../../images/BookmarkInactive.svg";
import bookmark from "../../images/Bookmark2.svg";
import Speaker from "../Speaker/Speaker";
import DOMPurify from "dompurify";
import { ReactComponent as Time } from "../../images/svg/Time.svg";

function ProgramCard({ item, index }) {
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const [isAdded, setIsAdded] = useState(item.is_added);
  const timeStart = (myDate) =>
    new Date(myDate).toLocaleTimeString("en", {
      timeStyle: "short",
      hour12: false,
      timeZone: "Europe/Moscow",
    });

  return (
    <div className="program__card" id={`event-${item.id}`}>
      <div className="program__info-box">
        <div className="program__left">
          <span className="program__span program__span_time">
            <Time />
            <span className="program__timestart">
              {timeStart(item.start_date)}
            </span>
          </span>
          {item.tags.map((tag) => (
            <span className="program__spanRight">{tag}</span>
          ))}
        </div>
        <button className="program__bookmark">
          <img
            src={isAdded === true ? bookmark : bookmarkInactive}
            alt="bookmark"
            onClick={() => {
              scheduleToggle(token, dispatch, item.id, setIsAdded, isAdded);
            }}
          />
        </button>
      </div>
      <h3 className="program__header">{item.title}</h3>
      <p
        className="program__text"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(item.description),
        }}
      ></p>
      <ul className="program__speakers">
        {item.speakers.map((card) => (
          <Speaker index={index} key={card.id} card={card} id={item.id} />
        ))}
      </ul>
    </div>
  );
}

export default ProgramCard;
