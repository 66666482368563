// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Popup_popup__PugWK {
  box-sizing: border-box;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  width: 520px;
  background-color: #FFFFFF;
  border-radius: 32px;
  position: absolute;
  margin: 0 auto;
  padding: 30px;
  flex-wrap: wrap;
}
.Popup_popup__logoImg__itygV {
  position: absolute;
  top: -108px;
  left: 73px;
}

@media (max-width: 520px) {
  .Popup_popup__PugWK {
    top: 80px;
    max-width: 400px;
    width: 100%;
    position: relative;
    padding: 0;
    box-shadow: none;
    padding: 20px;
  }
  .Popup_popup__logoImg__itygV {
    left: 50%;
    transform: translate(-50%, 0);
    top: -80px;
    max-width: 280px;
    width: 100%;
  }
  .Popup_popup__logoImg__itygV img {
    margin: 0 auto;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Popup/Popup.module.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,eAAA;EACA,YAAA;EAEA,yBAAA;EACA,mBAAA;EACA,kBAAA;EACA,cAAA;EAEA,aAAA;EACA,eAAA;AADF;AAIE;EACE,kBAAA;EACA,WAAA;EACA,UAAA;AAFJ;;AAiBA;EACE;IACE,SAAA;IACA,gBAAA;IACA,WAAA;IACA,kBAAA;IACA,UAAA;IACA,gBAAA;IACA,aAAA;EAdF;EAgBE;IACE,SAAA;IACA,6BAAA;IACA,UAAA;IACA,gBAAA;IACA,WAAA;EAdJ;EAeI;IACE,cAAA;EAbN;AACF","sourcesContent":[".popup {\n  box-sizing: border-box;\n  z-index: 999;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  max-width: 100%;\n  width: 520px;\n\n  background-color: #FFFFFF;\n  border-radius: 32px;\n  position: absolute;\n  margin: 0 auto;\n  // box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);\n  padding: 30px;\n  flex-wrap: wrap;\n\n\n  &__logoImg {\n    position: absolute;\n    top: -108px;\n    left: 73px;\n  }\n}\n\n// @media (max-width: 850px) {\n//   .popup {\n//     width: 450px;\n//     position: relative;\n//     &__logoImg {\n//       // width: 200px;\n//       // top: -80px;\n//     }\n//   }\n// }\n\n@media (max-width: 520px) {\n  .popup {\n    top: 80px;\n    max-width: 400px;\n    width: 100%;\n    position: relative;\n    padding: 0;\n    box-shadow: none;\n    padding: 20px;\n\n    &__logoImg {\n      left: 50%;\n      transform: translate(-50%, 0);\n      top: -80px;\n      max-width: 280px;\n      width: 100%;\n      img {\n        margin: 0 auto;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": `Popup_popup__PugWK`,
	"popup__logoImg": `Popup_popup__logoImg__itygV`
};
export default ___CSS_LOADER_EXPORT___;
