import React from "react";
import "../globalStyles/global.scss";
import {
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import AuthPopupSetNewPassword from "../components/Auth/AuthPopupSetNewPassword/AuthPopupSetNewPassword";
import AuthPopupLogin from "../components/Auth/AuthPopupLogin/AuthPopupLogin";
import AuthPopupPasswordRecovery from "../components/Auth/AuthPopupPasswordRecovery/AuthPopupPasswordRecovery";
import AuthPopupRegistration from "../components/Auth/AuthRegistration/AuthPopupRegistration";
import Account from "../routes/Account/Account";
import Leaderboard from "../routes/Leaderboard/Leaderboard";
import Notifications from "../routes/Notifications/Notifications";
import Event from "../routes/Event/Event";
import Mistake from "../routes/Mistake/Mistake";
import { BroadcastAsync } from "../routes/Broadcast/Broadcast.async";
import AuthByPhone from "../components/Auth/AuthByPhone/AuthByPhone";
import Game from "./Game/Game";
import Seminar from "./Seminar/Seminar";
import Bookmarks from "./Bookmarks/Bookmarks";

function AppRoutes() {
  const isLogin = localStorage.getItem("login");

  return (
    <Routes>
      <Route path="*" element={<Mistake type={404} />} />
      <Route path="/500" element={<Mistake type={500} />} />
      <Route path="/email-confirm/*" element={<AuthByPhone />} />
      <Route path="/login" element={<AuthByPhone />} />
      <Route
        path="/"
        element={
          isLogin ? (
            <Navigate replace to="/account" />
          ) : (
            <Navigate replace to="/login" />
          )
        }
      />
      <Route
        path="/recover-password"
        element={<AuthPopupPasswordRecovery />}
      />
      <Route path="/password-reset/*" element={<AuthPopupSetNewPassword />} />
      <Route path="/new-password" element={<AuthPopupSetNewPassword />} />
      <Route path="/register" element={<AuthPopupRegistration />} />

      {isLogin && (
        <>
          <Route path="/account" element={<Account />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/event" element={<Event />} />
          <Route path="/seminar" element={<Seminar />} />
          <Route path="/bookmark" element={<Bookmarks />} />
          <Route path="/broadcast" element={<BroadcastAsync />} />
          <Route path="/game" element={<Game />} />
        </>
      )}
    </Routes>
  );
}

export default AppRoutes;
