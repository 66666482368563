import axios from "axios";
import { config } from "../../config/config";
import { notifyErrors } from "../helper/errorResolver";

export async function sendLogToBot({ message }) {
  const telegramToken = config.telegram.logBotApiKey;
  try {
    const response = await axios(`https://api.telegram.org/bot${telegramToken}/sendMessage`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        chat_id: config.telegram.logBotChatId,
        text: message,
      },
    });
    return response
  } catch (error) {
    notifyErrors({ error })
  }
}
