import React  from "react";
import "./Promo.scss";
import { useNavigate } from "react-router-dom";

export const PromoProfileCompleted = () => {
  const navigate = useNavigate();
  const handleClickPromo = () => {
    navigate('/game');
  }

  return (
    <div className="promo__info">
      <div
        className="promo__box promo__box_online promo-verified"
      >
        <h3 className="promo__header">Осталось пройти небольшой&nbsp;квиз</h3>
        <div className="score__info-box">
          <div className="score__span">
            Ответь на все вопросы за&nbsp;15 минут, чтобы мы
            убедились, что мероприятие точно тебе подходит.   <br />Важно: у&nbsp;тебя есть всего одна попытка
          </div>
          <button
            className="score__button"
            onClick={(e) => handleClickPromo()}
          >
            Пройти квиз
          </button>
        </div>
      </div>
    </div>
  );
}
