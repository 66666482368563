// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main {
  display: flex;
  flex-direction: row;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
}

.main__info {
  width: 100%;
  display: flex;
  gap: 20px;
  flex-direction: row;
}

.main__info_online {
  flex-direction: row;
}

@media screen and (max-width: 1440px) {
  .main__info {
    flex-direction: column;
  }
  .right_column {
    width: 100%;
    display: block;
  }
  .schedule {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/routes/Routes.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EAEA,wBAAA;EAAA,mBAAA;EACA,WAAA;AAAJ;;AAGA;EACI,WAAA;EACA,aAAA;EACA,SAAA;EACA,mBAAA;AAAJ;;AAGA;EACI,mBAAA;AAAJ;;AAGA;EACI;IACI,sBAAA;EAAN;EAEE;IACI,WAAA;IACA,cAAA;EAAN;EAEE;IACI,WAAA;EAAN;AACF","sourcesContent":[".main {\n    display: flex;\n    flex-direction: row;\n    // min-height: calc(100vh - 202px);\n    height: fit-content;\n    width: 100%;\n}\n\n.main__info {\n    width: 100%;\n    display: flex;\n    gap: 20px;\n    flex-direction: row;\n}\n\n.main__info_online {\n    flex-direction: row;\n}\n\n@media screen and (max-width: 1440px) { \n    .main__info {\n        flex-direction: column;\n    }\n    .right_column{\n        width: 100%;\n        display: block;\n    }\n    .schedule{\n        width: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
