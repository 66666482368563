import React  from "react";
import "./Promo.scss";

export const PromoComplete = () => {
  return (
    <div className="promo__info">
      <div
        className="promo__box promo__box_online promo-complete"
      >
        <h3 className="promo__header">Анкета заполнена</h3>
        <div className="score__info-box">
          <div className="score__span">
            Спасибо за ответы на все вопросы.
            <br />Дождись подтверждения заявки.
            <br />Подтверждение заявки займёт до 7 рабочих дней.
            <br />Остаёмся на связи
          </div>
        </div>
      </div>
    </div>
  );
}
