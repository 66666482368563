import styled from "styled-components";

export const ActiveQuestionNo = styled.span`
`

export const TotalQuestionNo = styled.span`
`

export const QuizProgress = styled.div`
  font-size: 14px;
  color: #969FA8;
  margin-top: 21px;
  margin-bottom: 2px;
`

export const QuizHeading = styled.h2`
  font-size: 24px;
  font-weight: 500;
`
