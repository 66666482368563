// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FirstPopupRegistration_firstPopupRegistration__K3iwW {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.FirstPopupRegistration_firstPopupRegistration__header__q4DW0 {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}
.FirstPopupRegistration_firstPopupRegistration__backLink__M1xSG {
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 35px;
  padding: 0;
}
.FirstPopupRegistration_firstPopupRegistration__title__3\\+tub {
  display: inline-block;
  font-weight: 500;
  margin-bottom: 4px;
  font-size: 24px;
  line-height: normal;
  font-family: "MTS Compact";
}
.FirstPopupRegistration_firstPopupRegistration__subtitle__\\+XISP {
  display: inline-block;
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  opacity: 0.5;
  margin-bottom: 30px;
}
.FirstPopupRegistration_firstPopupRegistration__formWrapper__R7B8c {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.FirstPopupRegistration_firstPopupRegistration__wrapperButtonFooter__N9Roi {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.FirstPopupRegistration_firstPopupRegistration__wrapperButtonFooter__N9Roi button {
  margin: unset;
}
.FirstPopupRegistration_firstPopupRegistration__wrapperButtonFooter__signIn__z\\+fl9 {
  padding-right: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/Auth/AuthRegistration/FirstPopupRegistration/FirstPopupRegistration.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;AACE;EACE,kBAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;AACE;EACE,kBAAA;EACA,OAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;AACJ;AAGE;EACE,qBAAA;EACA,gBAAA;EACA,kBAAA;EACA,eAAA;EACA,mBAAA;EACA,0BAAA;AADJ;AAIE;EACE,qBAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,YAAA;EACA,mBAAA;AAFJ;AAKE;EACE,WAAA;EACA,aAAA;EACA,sBAAA;AAHJ;AAME;EACE,aAAA;EACA,uBAAA;EACA,gBAAA;AAJJ;AAMI;EACE,aAAA;AAJN;AAOI;EACE,mBAAA;AALN","sourcesContent":[".firstPopupRegistration {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  &__header {\n    position: relative;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    margin-bottom: 24px;\n  }\n  &__backLink {\n    position: absolute;\n    left: 0;\n    top: 0;\n    width: 24px;\n    height: 35px;\n    padding: 0;\n  }\n\n\n  &__title {\n    display: inline-block;\n    font-weight: 500;\n    margin-bottom: 4px;\n    font-size: 24px;\n    line-height: normal;\n    font-family: \"MTS Compact\";\n  }\n\n  &__subtitle {\n    display: inline-block;\n    color: #000;\n    text-align: center;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 24px;\n    opacity: 0.5;\n    margin-bottom: 30px;\n  }\n\n  &__formWrapper {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n  }\n\n  &__wrapperButtonFooter {\n    display: flex;\n    justify-content: center;\n    margin-top: 10px;\n\n    button {\n      margin: unset;\n    }\n\n    &__signIn {\n      padding-right: 20px;\n\n    }\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"firstPopupRegistration": `FirstPopupRegistration_firstPopupRegistration__K3iwW`,
	"firstPopupRegistration__header": `FirstPopupRegistration_firstPopupRegistration__header__q4DW0`,
	"firstPopupRegistration__backLink": `FirstPopupRegistration_firstPopupRegistration__backLink__M1xSG`,
	"firstPopupRegistration__title": `FirstPopupRegistration_firstPopupRegistration__title__3+tub`,
	"firstPopupRegistration__subtitle": `FirstPopupRegistration_firstPopupRegistration__subtitle__+XISP`,
	"firstPopupRegistration__formWrapper": `FirstPopupRegistration_firstPopupRegistration__formWrapper__R7B8c`,
	"firstPopupRegistration__wrapperButtonFooter": `FirstPopupRegistration_firstPopupRegistration__wrapperButtonFooter__N9Roi`,
	"firstPopupRegistration__wrapperButtonFooter__signIn": `FirstPopupRegistration_firstPopupRegistration__wrapperButtonFooter__signIn__z+fl9`
};
export default ___CSS_LOADER_EXPORT___;
