import { FC } from 'react'
import { BookmarkButtonStyle } from './styled'
import { BookmarkFillIcon } from "../Icons/BookmarkFillIcon";
import { BookmarkOutlineIcon } from "../Icons/BookmarkOutlineIcon";

interface BookmarkButtonTypes {
  isSelected: string
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  outline?: boolean
  bold?: boolean
  big?: boolean
  disabled?: boolean
  className?: string
}

const BookmarkButton: FC<BookmarkButtonTypes> = ({
  isSelected,
  onClick,
  outline,
  bold,
  big,
  disabled,
  className,
}) => {
  return (
    <BookmarkButtonStyle
      onClick={onClick}
      outline={outline}
      bold={bold}
      big={big}
      disabled={disabled}
      className={className}
    >
      {isSelected && <BookmarkFillIcon />}
      {!isSelected && <BookmarkOutlineIcon />}
    </BookmarkButtonStyle>
  )
}

export default BookmarkButton
