import { FC } from 'react'

import { Refresh } from '../../config/icons'
import { useQuiz } from '../../context/QuizContext'
import { Flex } from '../../styles/Global'
import { refreshPage } from '../../utils/helpers'
import {
  ResultScreenContainer,
  InnerContainer,
} from './styled'

import Button from '../ui/Button'
import ResultOverview from './ResultOverview'

const ResultScreen: FC = () => {
  const { result } = useQuiz()

  const onClickRetry = () => {
    refreshPage()
  }

  return (
    <ResultScreenContainer>
      <InnerContainer>
        <ResultOverview result={result} />
      </InnerContainer>
      <Flex flxEnd>
        <Button
          text="На главную"
          onClick={onClickRetry}
          icon={<Refresh />}
          iconPosition="left"
          bold
        />
      </Flex>
    </ResultScreenContainer>
  )
}

export default ResultScreen
