/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import S from "./EventLIstItem.module.scss";
import { EventClockIcon } from "../ui/Icons/EventClockIcon";
import DOMPurify from "dompurify";
import {getImagePath} from "../../api/helper/strapiBridge";

function EventListItem({ item }) {
  return (
    <div className={S.eventListItem} id={`event-${item.id}`}>
      <div className={S.eventListItem__schedule}>
        <EventClockIcon className={S.eventListItem__scheduleIcon} />{item.schedule}
      </div>
      <div
        dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item.title) }}
        className={S.eventListItem__title}
      >
      </div>
      <div className={S.eventListItem__photo}>
        {item.image && (
          <img src={getImagePath(item.image?.url) || ''} alt={item.title} />
        )}
      </div>
    </div>
  );
}

export default EventListItem;
