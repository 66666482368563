import React, {useEffect, useState} from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Modal from "../../components/Modal/Modal";
import Menu from "../../components/Menu/Menu";
import CentralColumn from "../../components/CentralColumn/CentralColumn";
import { useSelector, useDispatch } from "react-redux";
import { getProfile } from "../../api/profile/profile";
import { setUser } from "../../redux/user/user";
import "./../Routes.scss";
import QuizMain from "../../components/QuizMain";
import QuizProvider from '../../context/QuizProvider'

function Game({ setIsCodeSucess }) {
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const { isOpenModal, burgerOpened } = useSelector(
    (state) => state.modal
  );
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    getProfile(token, dispatch, setUser);
  }, [token]);

  return (
    <>
      <Header />
      <main className={`main ${burgerOpened ? "overflow-hidden" : ""}`}>
        <QuizProvider>
          <QuizMain />
        </QuizProvider>
      </main>
      <Footer activeMenu={'game'} />
      {isOpenModal && <Modal />}
    </>
  );
}

export default Game;
