import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getNotificationList } from "../../api/notification/notification";
import "./CentralColumn.scss";
import { handleMouseDown } from "../../utils/MouseDown";
import Notification from "../Notification/Notification";

function NotificationsColumn() {
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const [notifs, setNotifs] = useState([]);

  useEffect(() => {
    getNotificationList(token, setNotifs, dispatch);
  }, []);

  const [isCodeSucess, setIsCodeSucess] = useState(false);

  useEffect(() => {
    getNotificationList(token, setNotifs);
  }, []);

  useEffect(() => {
    isCodeSucess &&
      window.notificationHeaderCode &&
      window.notificationHeaderCode();
  }, [isCodeSucess]);

  useEffect(() => {
    let timer1 = setTimeout(() => {
      window.notificationSetCodeCard && window.notificationSetCodeCard();
    }, 3000);
    return () => {
      clearTimeout(timer1);
    };
  }, []);

  return (
    <>
      <div className={`column mt-[8px] lg:mt-0`}>
        <div
          onMouseDown={(e) => handleMouseDown(e, setIsCodeSucess, "notice")}
          className={`text-[#1D2023] text-[20px] lg:text-[36px] leading-[28px] lg:leading-[40px] bg-white font-medium p-[24px] rounded-[20px] font-wide mb-[32px] cursor-pointer desktop`}
        >
          Уведомления
        </div>
        <div
          className={`text-[#1D2023] text-[20px] lg:text-[36px] leading-[28px] lg:leading-[40px] bg-white font-medium p-[24px] rounded-[20px] font-wide mb-[32px] mobile`}
        >
          Уведомления
        </div>
        {isCodeSucess && (
          <div
            id="notification_child"
            className={`text-[#FF0032] text-[20px] lg:text-[36px] leading-[28px] lg:leading-[40px] bg-white font-medium p-[24px] rounded-[20px] font-wide mb-[32px] h-[88px]`}
          ></div>
        )}
        <ul className="overflow-auto lg:overflow-scroll h-auto lg:h-[calc(100vh-240px)] no-scrollbar">
          {notifs.map((notif, index) => (
            <Notification
              key={notif?.id}
              card={notif}
              indexNotif={index}
              setNotifs={setNotifs}
            />
          ))}
        </ul>
      </div>
    </>
  );
}

export default NotificationsColumn;
