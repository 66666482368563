import { PositionsEnum } from "../../constants/enum";
import { useEffect, useState } from "react";
import { getProfileQr, getProfileQrImage } from "../../api/profile/profile";

export const stackDisablePositions = [
  PositionsEnum.CyberSecurity,
  PositionsEnum.DevOps,
  PositionsEnum["TechLead/TeamLead"],
];
export const stackDisableValue = "Без стэка"

export const useProfile = ({ user }) => {
  const accessToken = localStorage.getItem("accessToken");
  const [isStackAvailable, setIsStackAvailable] = useState(false);
  const [qrInline, setQrInline] = useState('')

  const getUserQr = async () => {
    const responseQr = await getProfileQr(accessToken)
    return responseQr?.data
  }

  useEffect(() => {
    if (user?.position) {
      setIsStackAvailable(!stackDisablePositions.includes(user?.position));
    }
  }, [user.position]);

  const getUserQrImage = async () => {
    const responseQrImage = await getProfileQrImage(accessToken);
    return responseQrImage?.data;
  };

  return {
    isStackAvailable,
    setIsStackAvailable,
    accessToken,
    getUserQr,
    getUserQrImage,
    qrInline,
    setQrInline,
  }
}
