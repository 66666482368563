import {emailRegex, regexOnlyCirillica} from "../../../constants/constants";

export const useAuthValidator = () => {

  const phoneValidation = (rule: any, value: any) => {
    let onlyphone = value?.replace(/[^0-9]/g, "");
    if (onlyphone?.length !== 11) {
      return Promise.reject(new Error("Номер введен не полностью"));
    } else {
      return Promise.resolve();
    }
  };

  const emailValidator = (rule: any, value: any) => {
    if (!emailRegex.test(value)) {
      return Promise.reject(new Error("Email введён не корректно"));
    } else {
      return Promise.resolve();
    }
  };

  const lastNameValidator = (rule: any, value: any) => {
    if (value?.length === 0) {
      return Promise.reject(new Error("Обязательное поле"));
    } else if (value?.length > 50) {
      return Promise.reject(new Error("Не более 50 символов"));
    } else if (!regexOnlyCirillica.test(value)) {
      return Promise.reject(new Error("Поле должно содержать русские буквы"));
    } else {
      return Promise.resolve();
    }
  };

  return {
    phoneValidation,
    emailValidator,
    lastNameValidator,
  }
}
