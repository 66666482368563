import React, { useState } from 'react';
import './AuthByPhone.module.scss';
import S from './AuthByPhone.module.scss';
import { Button } from 'antd';
import LoginPage from "../../LoginPage/LoginPage";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { sendCode, verifyCode } from '../../../api/auth/auth';
import { toast } from "react-toastify";
import { cleanPhone } from "../../../utils/helpers";
import { useAuthCountdown } from "../hooks/useAuthCountdown";
import { ChevronLeft } from "../../ui/Icons/ChevronLeft";
import { setIsLogin } from "../../../redux/login/login";
import { AuthVerification } from "../AuthVerification/AuthVerification";
import AuthPhoneForm from "../AuthPhoneForm/AuthPhoneForm";
import { config } from "../../../config/config";

const AuthByPhone = () => {
  const [phone, setPhone] = useState('');
  const [step, setStep] = useState(1);
  const [isShowCountdown, setIsShowCountdown] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isRegisterAvailable = config.service.isRegisterAvailable;

  const {
    setIsButtonSubmitDisabled,
  } = useAuthCountdown({ totalTime: 45 })

  const onSendCode = ({ phone }) => {
    setPhone(phone);
    setStep(2);
    setIsShowCountdown(true);
  };

  const handleSendCode = async () => {
    const response = await sendCode({
      phone: cleanPhone(phone),
      toast
    })
    if (response.success) {
      onSendCode({ phone })
    }
  }

  const handleVerifyAuth = async (values) => {
    setIsShowCountdown(true)
    setIsButtonSubmitDisabled(true)
    const { code } = values

    const response = await verifyCode({
      // email: 'o.karpov@tsekh.design',
      // password: 'Tsekh19edet!',
      phone: cleanPhone(phone),
      code,
      dispatch,
      toast
    });
    if (response?.data?.token) {
      localStorage.setItem("accessToken", response?.data?.token);
      localStorage.setItem("login", true);
      dispatch(setIsLogin(true));
      navigate('/account');
    }
  };

  const goToRegister = () => {
    setStep(1)
    navigate('/register')
  }

  return (
    <LoginPage>
      <div className={S.wrapperAuthByPhone}>
        <div className={S.wrapperAuthByPhone__header}>
          {step > 1 && (
            <Button
              type="text"
              onClick={() => setStep(1)}
              className={S.wrapperAuthByPhone__backLink}
            >
              <ChevronLeft />
            </Button>
          )}
          <h3 className={S.wrapperAuthByPhone__title}>Вход</h3>
        </div>
        {step === 1 && (
          <AuthPhoneForm onSendCode={onSendCode} />
        )}

        {step === 2 && (
          <AuthVerification
            phone={phone}
            goToAuth={goToRegister}
            submitText="Войти"
            totalTime={45}
            isShowCountdown={isShowCountdown}
            repeatSendCode={handleSendCode}
            onSubmit={handleVerifyAuth}
          />
        )}
        {isRegisterAvailable && (
          <div className={S.wrapperAuthByPhone__footer}>
            <button
              className='createAccount'
              onClick={() => navigate('/register')}
            >
              Создать аккаунт
            </button>
          </div>
        )}
      </div>
    </LoginPage>
  )
}

export default AuthByPhone
