import React, { useState } from "react";
import LoginPage from "../../LoginPage/LoginPage";
import FirstPopupRegistration from "./FirstPopupRegistration/FirstPopupRegistration";
import SecondPopupRegistration from "./SecondPopupRegistration/SecondPopupRegistration";
import ThirdPopupRegistration from "./ThirdPopupRegistration/ThirdPopupRegistration";
import FourthPopupRegistration from "./FourthPopupRegistration/FourthPopupRegistration";
import { useSelector } from "react-redux";
import { config } from "../../../config/config";
import {Link} from "react-router-dom";

const AuthPopupRegistration = () => {
  const [formData, setFormData] = useState({
    email: "",
    formatEvent: "online",
    firstName: "",
    lastName: "",
    phone: "",
    password: "",
    confirmPassword: "",
    position: "",
    skills: "",
    city: "",
    isPersonalDataPolicyChecked: false,
    receive_advertising: false,
    level: "",
  });
  const { registrationStage } = useSelector((state: any) => state.form);
  const isRegisterAvailable = config.service.isRegisterAvailable;
  // const [registrationStage, setRegistrationStage] = useState('first');

  const handleInputChange = (key?: any, e?: any) => {
    const { name, checked, value } = e.target;
    setFormData({
      ...formData,
      [name ? name : key]:
        key === "isPersonalDataPolicyChecked" || key === "receive_advertising"
          ? checked
          : value,
    });
  };

  return (
    <LoginPage>
      {!isRegisterAvailable && (
        <div className={''}>
          <div className={'w-full text-center text-[24px] font-medium leading-[1.2]'}>
            Регистрация закрыта. <br />Увидимся в следующий раз
          </div>
          <div className={'text-center mt-8'}>
            <Link to={'/login'} className={'text-[#0070E5]'}>Есть аккаунт? Авторизоваться</Link>
          </div>
        </div>
      )}
      {isRegisterAvailable && registrationStage === "first" && (
        <FirstPopupRegistration />
      )}
      {/* {registrationStage === "second" && (
        <SecondPopupRegistration
          handleInputChange={handleInputChange}
          formData={formData}
        />
      )} */}
      {registrationStage === "third" && (
        <ThirdPopupRegistration
          handleInputChange={handleInputChange}
          formData={formData}
        />
      )}
      {registrationStage === "fourth" && (
        <FourthPopupRegistration
          handleInputChange={handleInputChange}
          formData={formData}
        />
      )}
    </LoginPage>
  );
};
export default AuthPopupRegistration;
