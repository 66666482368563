import {useState, useEffect} from 'react';
import './AuthPopupLogin.module.scss';
import s from './AuthPopupLogin.module.scss';
import {Button, Form, Input} from 'antd';
import LoginPage from "../../LoginPage/LoginPage";
import {useNavigate} from "react-router-dom";
import {emailRegex, regexPassword} from "../../../constants/constants";
import { useDispatch, useSelector } from 'react-redux';
import {loginUser} from '../../../api/auth/auth';
import { confirmEmail } from '../../../api/auth/auth';
import { toast } from "react-toastify";

const baseURL = process.env.REACT_APP_BASE_URL

const AuthPopupLogin = () => {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const [password, setPassword] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = window.location?.search?.slice(7);
  const pathname = window.location.pathname;
  const isLogin = localStorage.getItem("login");

  useEffect(() => {
		if (pathname === "/email-confirm/") {
      confirmEmail(token, isLogin, navigate, toast);
    }
	}, []);

  const onFinish = async (values) => {
    await loginUser({
      email,
      password,
      dispatch,
      toast
    });
    // navigate('/account');
  };

  const onFinishFailed = (errorInfo) => {
    console.log('--- Failed:', errorInfo);
  };


  useEffect(() => {
    if (emailRegex.test(email)) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }

  }, [email, password]);

  return (
    <LoginPage>
      <div className={s.wrapperAuthPopupLogin}>
        <h3 className={s.wrapperAuthPopupLogin__title}>Вход</h3>
        <Form
          name="basic"
          initialValues={{remember: true}}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className={s.wrapperAuthPopupLogin__formWrapper}
        >
          <Form.Item
            name={'email'}
            rules={[{
              type: 'email',
              required: true,
              message: 'Неверная электронная почта'
            }]}
            className={s.wrapperAuthPopupLogin__formWrapper__itemInput__email}
          >
            <Input placeholder="Электронная почта" onChange={(e) => setEmail(e.target.value)}/>
          </Form.Item>

          <Form.Item
            name={['password']}
            rules={[
              {required: true, message: 'Неверный пароль'},
              {
                pattern: regexPassword,
                message: 'Введите корректный пароль'
              }
            ]}
            className={s.wrapperAuthPopupLogin__formWrapper__itemInput__password}
          >
            <Input.Password
              style={{background: '#BCC3D080'}}
              placeholder="Пароль"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name={['tel']}
            rules={[
              {required: true, message: 'Неверный телефон'},
              {
                pattern: regexPassword,
                message: 'Введите корректный телефон'
              }
            ]}
            className={s.wrapperAuthPopupLogin__formWrapper}
          >
            <Input
              placeholder="Телефон"
              onChange={(e) => setPhone(e.target.value)}
              mask="+7 999 999 9999"
            />
          </Form.Item>

          <a href="#" className='resetPassword' style={{paddingTop: "7px"}} onClick={() => navigate('/recover-password')}>Забыли пароль?</a>

          <Form.Item>
            <Button type="primary" style={{marginTop:"0px"}} htmlType="submit" disabled={isButtonDisabled}
                    className='buttonSend'>
              Войти
            </Button>
          </Form.Item>
        </Form>

        <button className='createAccount' style={{paddingTop: "5px"}} onClick={() => navigate('/register')}>Создать аккаунт</button>
      </div>
    </LoginPage>

  )
}

export default AuthPopupLogin
