import React, { useState } from 'react';
import logo from '../../images/Layer 9.svg';
import profileImage from '../../images/Avatar.svg';
import burger from '../../images/burger.svg';
import burger_close from '../../images/cross.svg';
import './Header.scss';
import { useSelector, useDispatch } from 'react-redux';
import { setBurgerOpened } from '../../redux/modal/modal';
import { Link } from "react-router-dom";

function Header () {
  const [burgerOpen, setBurgerOpen] = useState(false)
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { burgerOpened } = useSelector((state) => state.modal);
  const baseURL = process.env.REACT_APP_BASE_URL

  const openBurger = () => {
    dispatch(setBurgerOpened(!burgerOpened))
  }

  return (
    <header className='header container'>
      <img
        className={'header__logo lg:h-auto lg:w-auto h-[40px] w-[40px]'}
        alt='logo'
        src={logo}
      />
      <div className='header__info-box'>
          <img
              className='header__avatar'
              alt='avatar'
              src={ user?.photo ? `${baseURL}/${user?.photo}` : profileImage }
          />
          <span className={`header__span lg:block desktop`}> { user?.firstName } </span>
          <button className={`header__burger lg:hidden block ml-[16px] cursor-pointer`} onClick={() => openBurger()}>
            {
              burgerOpened ? (
                <img src={burger_close} alt="burger"/>
              ): (
                <img src={burger} alt="burger"/>
              )
            }
          </button>
          {/* <button
            className={burgerOpened === true ? 'header__button header__button_opened' : 'header__button header__button_closed'}
            onClick={() => dispatch(setBurgerOpened(!burgerOpened))}
          /> */}
      </div>
    </header>
  )
}

export default Header
