// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CountdownRepeat_wrapperCountdown__B2Gj8 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #0070e5;
  margin: 4px 0 27px;
  line-height: 24px;
  cursor: pointer;
}
.CountdownRepeat_wrapperCountdown__counter__of9gb {
  display: inline-block;
  width: 45px;
}`, "",{"version":3,"sources":["webpack://./src/components/Auth/ui/CountdownRepeat.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;AACF;AAAE;EACE,qBAAA;EACA,WAAA;AAEJ","sourcesContent":[".wrapperCountdown {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  color: #0070e5;\n  margin: 4px 0 27px;\n  line-height: 24px;\n  cursor: pointer;\n  &__counter {\n    display: inline-block;\n    width: 45px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperCountdown": `CountdownRepeat_wrapperCountdown__B2Gj8`,
	"wrapperCountdown__counter": `CountdownRepeat_wrapperCountdown__counter__of9gb`
};
export default ___CSS_LOADER_EXPORT___;
