import S from './AuthVerification.module.scss';
import { Button, Form, Input } from 'antd';
import CountdownRepeat from "../ui/CountdownRepeat";
import { useAuthCountdown } from "../hooks/useAuthCountdown";

export const AuthVerification = ({
  submitText = "Отправить",
  onSubmit,
  isShowCountdown = false,
  repeatSendCode,
  totalTime = 45,
}) => {

  const {
    verificationCode,
    setVerificationCode,
    isStartCountdown,
    onFinishCountdown,
    onRepeatSendCode,
    isButtonSubmitDisabled,
    setIsButtonSubmitDisabled,
  } = useAuthCountdown({ totalTime })

  const handleRepeatSendCode = () => {
    onRepeatSendCode(); // Логика счётчика
    repeatSendCode(); // Логика родительского компонента
  }

  return (
    <div className={S.wrapperVerification}>
      <Form.Item
        name={['code']}
        rules={[
          {required: true, message: 'Неверный код'},
          {
            min: 4,
            max: 4,
            message: 'Введите корректный код из смс'
          }
        ]}
        className={S.wrapperVerification__formItem}
      >
        <Input
          value={verificationCode}
          placeholder="Код пароль из СМС*"
          onChange={(e) => setVerificationCode(e.target.value)}
        />
      </Form.Item>
      {isShowCountdown && (
        <CountdownRepeat
          startCountdown={isStartCountdown}
          onFinishCountdown={() => onFinishCountdown()}
          onRepeat={() => handleRepeatSendCode()}
          countMax={totalTime}
        />
      )}
      <Form.Item className={S.wrapperVerification__formItem}>
        <Button
          type="primary"
          style={{marginTop:"0px"}}
          htmlType="submit"
          disabled={isButtonSubmitDisabled}
          className='buttonSend'
          onClick={() => onSubmit({ code: verificationCode })}
        >
          {submitText}
        </Button>
      </Form.Item>
    </div>
  )
}
