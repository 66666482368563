/* eslint-disable no-restricted-globals */
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux';
import { setIsOpenModal, setIsModalAddPhoto } from '../../../redux/modal/modal';
import ReactCrop, {
    centerCrop,
    convertToPixelCrop,
    makeAspectCrop,
  } from "react-image-crop";
import setCanvasPreview from "./setCanvasPreview";
import 'react-image-crop/src/ReactCrop.scss'
import { setUser } from '../../../redux/user/user';
import { putAvatar } from '../../../api/profile/profile';

const ASPECT_RATIO = 1;
const MIN_DIMENSION = 200;


function ModalPhoto () {
    const dispatch = useDispatch();
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [imgSrc, setImgSrc] = useState("");
    const [crop, setCrop] = useState();
    const [error, setError] = useState("");
    const token = localStorage.getItem("accessToken");
    
    const onSelectFile = (e) => {
        const file = e.target.files?.[0];
        if (!file) return;
    
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          const imageElement = new Image();
          const imageUrl = reader.result?.toString() || "";
          imageElement.src = imageUrl;
    
          imageElement.addEventListener("load", (e) => {
            if (error) setError("");
            const { naturalWidth, naturalHeight } = e.currentTarget;
            if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
              setError("Image must be at least 200 x 200 pixels.");
              return setImgSrc("");
            }
          });
          setImgSrc(imageUrl);
        });
        reader.readAsDataURL(file);
    };

    const onImageLoad = (e) => {
        const { width, height } = e.currentTarget;
        const cropWidthInPercent = (MIN_DIMENSION / width) * 100;
    
        const crop = makeAspectCrop(
          {
            unit: "%",
            width: cropWidthInPercent,
          },
          ASPECT_RATIO,
          width,
          height
        );
        const centeredCrop = centerCrop(crop, width, height);
        setCrop(centeredCrop);
    };
    
    return (
        <div className={imgSrc ? "modal__photo modal__photo_big"  : "modal__photo"}>
            { !imgSrc &&
                <>
                    <div className='modal__photo-box'>
                        <h3 className='modal__header-photo'>Загрузка фотографии</h3>
                        <button
                            className='modal__close-photo'
                            onClick={ () => { dispatch(setIsOpenModal(false)); dispatch(setIsModalAddPhoto(false)); }}
                        />
                    </div>
                    <p className='modal__text' style={{textAlign: "left"}}>Вас будет проще узнать, если вы загрузите свою настоящую фотографию.</p>
                    <label className='modal__button-photo'>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={onSelectFile}
                            className="modal__input"
                        />
                        Загрузить фото
                    </label>
                    <span className='modal__span'>Формат файла: png, jpg и gif. Размеры не меньше 200 × 200 точек, объём файла не больше 7 МБ</span>
                </>
            }
            {imgSrc && (

                <>
                    <div className='modal__photo-box'>
                        <h3 className='modal__header-photo'>Кадрирование фото</h3>
                        <button
                            className='modal__close-photo'
                            onClick={ () => { dispatch(setIsOpenModal(false)); dispatch(setIsModalAddPhoto(false)); }}
                        />
                    </div>
                    <p className='modal__text left modal__text_photo'>Выбранная область будет показываться на вашей странице</p>
                    <ReactCrop
                        crop={crop}
                        onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}
                        circularCrop
                        keepSelection
                        aspect={ASPECT_RATIO}
                        minWidth={MIN_DIMENSION}
                    >
                        <img
                        ref={imgRef}
                        src={imgSrc}
                        alt="Upload"
                        // style={{ maxHeight: "336px", maxWidth: "336px" }}
                        onLoad={onImageLoad}
                        />
                    </ReactCrop>
                    <button 
                        className='modal__button modal__button_confirm'
                        onClick={() => {
                            setCanvasPreview(
                                imgRef.current,
                                previewCanvasRef.current,
                                convertToPixelCrop(
                                crop,
                                imgRef.current.width,
                                imgRef.current.height
                                )
                            );
                            const dataUrl = previewCanvasRef.current.toDataURL("image/png");
                            const image = dataUrl.replace(/^data:image\/[a-z]+;base64,/, "");
                            putAvatar(image, token, dispatch, setUser);
                            dispatch(setIsOpenModal(false));
                            dispatch(setIsModalAddPhoto(false));
                        }}
                    >
                        Сохранить
                    </button>
                </>
            )}
            {crop && (
                <canvas
                    ref={previewCanvasRef}
                    className="mt-4"
                    style={{
                        display: "none",
                        border: "1px solid black",
                        objectFit: "contain",
                        width: 255.82,
                        height:  255.82,
                    }}
                />
            )}
        </div>
    )
}

export default ModalPhoto;