import { useQuiz } from '../../context/QuizContext'
import { quizTopics } from '../../data/quizTopics'

import {
  CenterCardContainer,
  PageCenter,
} from '../../styles/Global'
import { ScreenTypes } from '../../types'

import {
  Heading,
  DetailText,
  SelectButtonContainer,
  ProfileWrapper,
  BoxHeader,
  NextButton,
} from './styled'
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProfileForm } from '../ProfileForm/ProfileForm';
import { getQuiz } from '../../api/quiz/quiz';
import { setQuizQuestions } from '../../redux/quiz/quiz';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { errorMessageResolve, toast_icon } from '../../api/helper/errorResolver';

const QuizTopicsScreen: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isReadyToQuiz, setIsReadyToQuiz] = useState(false);
  const { user } = useSelector((state: any) => state.user);
  const token = localStorage.getItem('accessToken');

  const { setCurrentScreen } = useQuiz();

  const goToQuizDetailsScreen = async () => {
    const questions = await getQuizQuestions()
    if (!questions) {
      return
    }
    // Если уже проходил квиз, перенаправляем на главную
    if (typeof questions?.data?.message === 'string' &&
      questions?.data?.message.indexOf('Quiz passed') > -1
    ) {
      toast.warn(errorMessageResolve(questions?.data?.message), {
        position: 'bottom-center',
        autoClose: 7000,
        closeOnClick: true,
        closeButton: false,
        icon: ({theme, type}) => toast_icon,
      });
      navigate('/account')
      return null
    }

    // TODO убрать в api вызов dispatch
    setQuizQuestions(questions)
    setCurrentScreen(ScreenTypes.QuestionScreen)
  }
  const getQuizQuestions = async () => {
    const response = getQuiz({
      accessToken: token,
      dispatch,
    });
    return response
  };

  // @ts-ignore
  const handleProfileSubmit = (values) => {
    setIsReadyToQuiz(true);
  }

  useEffect(() => {
    if (user?.company && user?.address) {
      setIsReadyToQuiz(true);
    } else {
      setIsReadyToQuiz(false);
    }
  }, [user]);

  return (
    <PageCenter light justifyCenter>
      <CenterCardContainer>
        {isReadyToQuiz && (
          <SelectButtonContainer>
            <BoxHeader>На прохождение анкеты даётся 15 минут</BoxHeader>
            <div style={{ marginBottom: "15px", fontSize: "18px" }}>У тебя только одна попытка.</div>
            <div style={{ marginBottom: "25px", fontSize: "18px" }}>Нажмите "Далее", если готовы</div>
            <NextButton
              type="primary"
              htmlType="button"
              className="profile__send"
              onClick={goToQuizDetailsScreen}
            >
              Далее
            </NextButton>
          </SelectButtonContainer>
        )}
        {!isReadyToQuiz && (
          <ProfileWrapper>
            <BoxHeader>Расскажи о себе</BoxHeader>
            <ProfileForm
              onSubmit={handleProfileSubmit}
              submitText="Далее"
              isShowEmail={false}
            />
          </ProfileWrapper>
        )}
      </CenterCardContainer>
    </PageCenter>
  )
}

export default QuizTopicsScreen
