import axios from "axios";
import { setQuizQuestions, setTotalQuestions, setQuizCompleted } from "../../redux/quiz/quiz";
import { apiUrl } from "../../config/env";
import { config } from "../../config/config";
import { notifyErrors } from "../helper/errorResolver";

export async function getQuiz({ accessToken, dispatch }) {
  try {
    const response = await axios(`${apiUrl}/v1${config.api.getQuiz}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    /*
    const response = await axios(`/test.json`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    */
    dispatch(setQuizQuestions(response.data?.data));
    dispatch(setTotalQuestions(response.data?.data?.length));

    return response
  } catch (error) {
    console.log(error);
    notifyErrors({ error })
  }
}

export async function sendQuizCompleted({ accessToken, dispatch }) {
  try {
    const response = await axios(`${apiUrl}/v1${config.api.quizCompleted}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response?.status === 204) {
      dispatch(setQuizCompleted(response.data?.data));
      return { completed: true }
    }
    return { completed: false }
  } catch (error) {
    console.log(error);
    notifyErrors({ error })
    // TODO удалить после ввода эндпоинта в работу
    return { completed: false }
  }
}

export async function sendAnswer({ accessToken, quizAnswerId }) {
  try {
    const response = await axios(`${apiUrl}/v1${config.api.quizAnswer}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        data: { quizAnswerId },
      },
    });
    return response
  } catch (error) {
    notifyErrors({ error })
  }
}

export async function sendLogToBot({ message }) {
  const telegramToken = '7299575455:AAEVBx_CrmriU07jemhcOv5Bq_8-tEkel_k';
  try {
    const response = await axios(`https://api.telegram.org/bot${telegramToken}/sendMessage`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        chat_id: "329901226",
        text: message,
      },
    });
    return response
  } catch (error) {
    notifyErrors({ error })
  }
}
