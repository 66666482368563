import axios from "axios";
import { setRegistrationStage } from "../../redux/form/form";
import { setIsLogin } from "../../redux/login/login";
import ym from "react-yandex-metrika";
import { apiUrl } from "../../config/env";
import { config } from "../../config/config";
import { errorMessageResolve, notifyErrors, toast_icon } from "../helper/errorResolver";
import { getStorageUtm } from "../helper/utm";

const baseUrl = process.env.REACT_APP_BASE_URL;

export async function postEmail(email, dispatch, navigate, toast) {
  try {
    const response = await axios(`${baseUrl}/api/check-email/`, {
      method: "POST",
      skipAuthRefresh: true,
      data: {
        email: email,
      },
    });
    if (typeof response === "function") {
      navigate("/login");
      toast.warn(
        "Пользователь с такой электронной почтой уже зарегистрирован.\r\nВойдите или восстановите пароль.",
        {
          position: "bottom-center",
          autoClose: false,
          closeOnClick: true,
          closeButton: false,
          icon: ({ theme, type }) => toast_icon,
        }
      );
    } else {
      console.log("--- auth response", response);
      try {
        ym("reachGoal", "registerOnlyEmail");
        window.ar_callCounter("daleye_na_stranitse_registratsii");
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setRegistrationStage("third"));
      }
    }
  } catch (err) {
    if (err.response.status == 400) {
      toast.warn(
        "Пользователь с такой электронной почтой уже зарегистрирован.\r\nВойдите или восстановите пароль.",
        {
          position: "bottom-center",
          autoClose: false,
          closeOnClick: true,
          closeButton: false,
          icon: ({ theme, type }) => toast_icon,
        }
      );
    }
    console.log(err);
  }
}

export async function registerUser(formData, dispatch, toast) {
  try {
    let utms = "";
    for (const element of [
      "utm_source",
      "utm_medium",
      "utm_campaign",
      "utm_term",
      "utm_content",
    ]) {
      utms = utms + element + "=" + localStorage.getItem(element) + "&";
    }
    const response = await axios(`${baseUrl}/api/register/`, {
      method: "POST",
      skipAuthRefresh: true,
      data: {
        // level: formData?.level.toUpperCase(),
        // role: formData?.role.toUpperCase(),
        email: formData?.email,
        // phone: "+" + formData?.phone.replace(/[^0-9]/g, ""),
        password: formData?.password,
        // first_name: formData?.firstName,
        // last_name: formData?.lastName,
        // event_format: formData?.formatEvent.toUpperCase(),
        repeat_password: formData?.confirmPassword,
        utm: utms,
        receive_advertising: formData?.receive_advertising,
      },
    });
    localStorage.setItem("accessToken", response.data.access);
    localStorage.setItem("login", true);
    dispatch(setRegistrationStage("first"));
    toast.warn(
      "На указанную электронную почту было направлено письмо со ссылкой для подтверждения регистрации.",
      {
        position: "bottom-center",
        autoClose: false,
        closeOnClick: true,
        closeButton: false,
        icon: ({ theme, type }) => toast_icon,
      }
    );
    // localStorage.setItem("refreshToken", response.data.refresh);
    ym("reachGoal", "registerUser");
  } catch (err) {
    let messages = "";
    try {
      for (var key in err.response.data) {
        if (typeof err.response.data[key] === "string") {
          messages += err.response.data[key] + " ";
        } else {
          messages += err.response.data[key][0] + " ";
        }
      }
      console.log(err.response);
      toast.warn(messages, {
        position: "bottom-center",
        autoClose: false,
        closeOnClick: true,
        closeButton: false,
        icon: ({ theme, type }) => toast_icon,
      });
    } catch (err2) {
      console.log(err2);
      toast.warn("Что-то пошло не так, уже разбираемся.", {
        position: "bottom-center",
        autoClose: false,
        closeOnClick: true,
        closeButton: false,
        icon: ({ theme, type }) => toast_icon,
      });
    }
  }
}

export async function loginUser(email, password, dispatch, toast) {
  try {
    const response = await axios(`${baseUrl}/api/login/`, {
      method: "POST",
      skipAuthRefresh: true,
      data: {
        email: email,
        password: password,
      },
    });
    if (typeof response === "function") {
      toast.warn(
        "Неверный email или пароль.\r\nПроверьте корректность отправляемых данных.",
        {
          position: "bottom-center",
          autoClose: false,
          closeOnClick: true,
          closeButton: false,
          icon: ({ theme, type }) => toast_icon,
        }
      );
    } else {
      ym("reachGoal", "login");
      console.log(response);
      dispatch(setIsLogin(true));
      localStorage.setItem("login", true);
      localStorage.setItem("accessToken", response.data.access);
      localStorage.setItem("refreshToken", response.data.refresh);
      window.location.replace(window.location.origin + "/account");
    }
  } catch (err) {
    if (typeof err.response.data.detail === "string") {
      toast.warn(err.response.data.detail, {
        position: "bottom-center",
        autoClose: false,
        closeOnClick: true,
        closeButton: false,
        icon: ({ theme, type }) => toast_icon,
      });
    } else {
      toast.warn(err.response.data.detail[0], {
        position: "bottom-center",
        autoClose: false,
        closeOnClick: true,
        closeButton: false,
        icon: ({ theme, type }) => toast_icon,
      });
    }
  }
}

export async function registerByPhone({ user }) {
  try {
    const response = await axios(`${apiUrl}/v1${config.api.authRegister}`, {
      method: "POST",
      skipAuthRefresh: true,
      data: {
        data: { ...user, ...{ utm: getStorageUtm() } },
      },
    });
    return response?.data
  } catch (error) {
    notifyErrors({ error })
  }
}

export async function sendCode({ phone, toast }) {
  try {
    const response = await axios(`${apiUrl}/v1${config.api.authSendCode}`, {
      method: "POST",
      skipAuthRefresh: true,
      data: {
        data: {
          phone: phone,
        },
      }
    });
    if (response?.status === 204) {
      return { success: true }
    }
    // TODO добавить обработку ошибки
    return { success: false }
  } catch (error) {
    const message = errorMessageResolve(error.response?.data?.message)

    notifyErrors({ error })
    return { success: false, message: message }
  }
}

export async function verifyCode({ code, phone, dispatch, toast }) {
  try {
    const url = 'https://api.truetechcamp.ru/v1/auth/verify-code'
    // `${baseUrl}/v1${config.api.authVerifyCode}`
    const response = await axios(url, {
      method: "POST",
      skipAuthRefresh: true,
      data: {
        data: { phone, code },
      },
    });

    return response.data
  } catch (error) {
    notifyErrors({ error })
  }
}

export async function confirmEmail(token, isLogin, navigate, toast) {
  try {
    const response = await axios(`${baseUrl}/api/email-confirm/${token}/`, {
      method: "GET",
      skipAuthRefresh: true,
    });
    if (isLogin) {
      ym("reachGoal", "confirmEmail");
      toast.warn(
        "Почта подтверждена. Вы были перенаправлены в личный кабинет. Вы можете входить повторно со своими данными или восстановить пароль, если он был забыт.",
        {
          position: "bottom-center",
          autoClose: false,
          closeOnClick: true,
          closeButton: false,
          icon: ({ theme, type }) => toast_icon,
        }
      );
      navigate("/account");
    } else {
      navigate("/login");
    }
  } catch (err) {
    console.log(err);
  }
}

export async function resetPassword(email, toast) {
  try {
    const response = await axios(`${baseUrl}/api/password-reset/`, {
      method: "POST",
      skipAuthRefresh: true,
      data: {
        email: email,
      },
    });
    ym("reachGoal", "resetPassword");
    return true;
  } catch (err) {
    console.log(err);
    // if (err.response.status === 404) {
    toast.warn(
      "Пользователь с такой почтой не найден. Проверьте введенный email-адрес или создайте аккаунт.",
      {
        position: "bottom-center",
        autoClose: false,
        closeOnClick: true,
        closeButton: false,
        icon: ({ theme, type }) => toast_icon,
      }
    );
    // }
    return false;
  }
}

export async function changePassword(
  token,
  newPassword,
  newPasswordRepeat,
  navigate,
  toast
) {
  try {
    const response = await axios(`${baseUrl}/api/password-reset/${token}`, {
      method: "PUT",
      skipAuthRefresh: true,
      data: {
        password: newPassword,
        repeat_password: newPasswordRepeat,
      },
    });
    ym("reachGoal", "changePassword");
    toast.warn(
      "Пароль изменен. Теперь вы можете совершить вход при помощи своего пароля.",
      {
        position: "bottom-center",
        autoClose: false,
        closeOnClick: true,
        closeButton: false,
        icon: ({ theme, type }) => toast_icon,
      }
    );
    navigate("/login");
  } catch (err) {
    console.log(err);
  }
}

export async function refreshToken(token) {
  try {
    const response = await axios(`${baseUrl}/api/refresh/`, {
      method: "POST",
      data: {
        refresh: token,
      },
    });
    localStorage.setItem("accessToken", response.data.access);
  } catch (err) {
    console.log(err);
  }
}

export async function getCodenrockToken(token) {
  try {
    const response = await fetch(`${baseUrl}/api/access-tokens`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      const data = await response.json();
      localStorage.setItem("login", true);
      localStorage.setItem("accessToken", data.access);
      localStorage.setItem("refreshToken", data.refresh);
      window.location.replace(window.location.origin + "/account");
    } else {
      window.location.replace(window.location.origin);
    }
  } catch (err) {
    console.log(err);
  }
}
