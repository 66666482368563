/* eslint-disable no-restricted-globals */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setIsOpenModal, setIsModalQr } from "../../../redux/modal/modal";
import { useSelector } from "react-redux";
import { useProfile } from "../../hooks/useProfile";
import DOMPurify from "dompurify";

function ModalQr() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { getUserQr, qrInline, setQrInline } = useProfile({ user });

  useEffect(() => {
    getUserQr().then((res) => {
      setQrInline(res)
    })
  }, [])

  return (
    <div className="modal__new-photo">
      <div className="modal__photo-box">
        <h3 className="modal__header-photo">
          QR
        </h3>
        <button
          className="modal__close-photo"
          onClick={() => {
            dispatch(setIsOpenModal(false));
            dispatch(setIsModalQr(false));
          }}
        />
      </div>
      <div className="modal__image-box">
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(qrInline) }}></div>
      </div>
    </div>
  );
}

export default ModalQr;
