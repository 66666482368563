import {useState, useEffect, useCallback} from 'react';
import S from './CountdownRepeat.module.scss';

const CountdownRepeat = ({
  countMax = 45,
  onFinishCountdown,
  onRepeat,
  startCountdown = false,
}) => {
  const [timer, setTimer] = useState(countMax);
  const timeOutCallback = useCallback(() =>
    setTimer(currTimer => currTimer - 1), []);

  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);

  const [isShowRepeat, setIsShowRepeat] = useState(false)

  const handleRepeat = () => {
    setTimer(countMax)
    setIsShowRepeat(false)
    onRepeat()
  }

  useEffect(() => {
    if (timer < 1) {
      onFinishCountdown(true)
      setIsShowRepeat(true)
    }
  }, [timer, onFinishCountdown, setIsShowRepeat])

  useEffect(() => {
    if (startCountdown) {
      setTimer(countMax)
    }
  }, [startCountdown, setTimer, countMax])

  return (
    <div className={S.wrapperCountdown}>
      {timer > 0 && (
        <div>
          Повторить попытку через <span className={S.wrapperCountdown__counter}>
          00:{timer > 9 ? timer : `0${timer}`}
        </span>
        </div>
      )}
      {isShowRepeat && (
        <div onClick={() => handleRepeat()}>Отправить повторно</div>
      )}
    </div>
  )
}

export default CountdownRepeat
