import React from "react";
import "./CentralColumn.scss";
import "./EventsColumn.scss";
import SeminarList from "../Seminar/SeminarList";
import { useNavigate } from "react-router-dom";
import SeminarTabs from "../Seminar/SeminarTabs";
import SeminarBookmarks from "../Seminar/SeminarBookmarks";

function BookmarksColumn() {
  const navigate = useNavigate();

  const handleChangeTab = (tab) => {
    navigate(tab);
  }

  return (
    <>
      <div className={`column mt-[8px] lg:mt-0`}>
        <div
          className={`events-header text-[#1D2023] text-[20px] bg-white p-[24px] rounded-[20px] mb-[20px]`}
        >
          <h2 className={`text-[#1D2023] text-[30px] leading-[28px] font-medium`}>
            Моё расписание
          </h2>
        </div>
        <SeminarBookmarks />
      </div>
    </>
  );
}

export default BookmarksColumn;
