import React, {useEffect, useState} from "react";
import {Button, Cascader, Checkbox, Form, Input} from "antd";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { MaskedInput } from "antd-mask-input";
import S from './RegisterForm.module.scss';
import { useAuthValidator } from "../Auth/hooks/useAuthValidator";
import { cleanPhone } from "../../utils/helpers";
import { positionsEnumOptions } from "../../constants/enum";
import { config } from "../../config/config";

export const RegisterForm = ({
  formSubmitted, // () => UserRegister
  submitText = "Сохранить"
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [position, setPosition] = useState(user?.position ? user?.position : "");
  const [grade, setGrade] = useState(user?.grade ? user?.grade : "");

  const [phone, setPhone] = useState(user?.phone ? user?.phone : "");
  // const accessToken = localStorage.getItem("accessToken");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
  const [formData, setFormData] = useState({
    isPersonalDataPolicyChecked: false,
  });

  const [form] = Form.useForm();

  const {
    phoneValidation,
    emailValidator,
    lastNameValidator,
  } = useAuthValidator();

  const handleInputChange = (key, e) => {
    const { name, checked, value } = e.target;
    setFormData({
      ...formData,
      [name ? name : key]:
        key === "isPersonalDataPolicyChecked" ? checked : value,
    });

  };

  const handleChangeSpecialization = (value) => {
    setPosition(value);
  };
  const handleChangeLevel = (value) => {
    setGrade(value);
  };

  const onFinish = (values) => {
    const { grade, position, phone, ...attrs } = values;
    formSubmitted({
      ...attrs,
      grade: Array.isArray(grade) && grade[0] ? grade[0] : '',
      position: Array.isArray(position) && position[0] ? position[0] : '',
      phone: cleanPhone(phone),
      dispatch
    });
  };

  useEffect(() => {
    if (formData.isPersonalDataPolicyChecked) {
      setIsSubmitDisabled(false)
    } else {
      setIsSubmitDisabled(true)
    }
  }, [formData.isPersonalDataPolicyChecked])

  return (
    <div className={S.register}>
      <Form
        form={form}
        name="register"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="firstName"
          rules={[
            {
              type: "string",
              validator: lastNameValidator
            },
          ]}
          className={S.register__formItem}
        >
          <Input
            placeholder="Имя*"
            name="firstName"
            onChange={(e) => handleInputChange('firstName', e)}
          />
        </Form.Item>
        <Form.Item
          name="lastName"
          rules={[
            {
              type: "string",
              validator: lastNameValidator
            },
          ]}
          className={S.register__formItem}
        >
          <Input
            placeholder="Фамилия*"
            name="lastName"
            onChange={(e) => handleInputChange('lastName', e)}
          />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            { validator: emailValidator }
          ]}
          className={S.register__formEmail}
        >
          <Input
            placeholder="Email*"
            name="email"
            onChange={(e) => handleInputChange('email', e)}
          />
        </Form.Item>
        <div className={S.register__itemNote}>
          Будет использоваться для рассылки приглашений и новостей о мероприятии
        </div>
        <Form.Item
          name="phone"
          rules={[{ validator: phoneValidation }]}
          className={S.register__formItem}
        >
          <MaskedInput
            mask={'+7 (000) 000-0000'}
            onChange={(e) => setPhone(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          name="position"
          className={S.account__formItem}
          rules={[
            {
              type: "array",
              required: true,
              message: "Обязательное поле",
              whitespace: true,
            },
          ]}
          validateStatus={!!position ? "" : "error"}
          help={!!position ? "" : "Обязательное поле"}
        >
          <Cascader
            style={{ width: "100%" }}
            placeholder="Роль*"
            onChange={handleChangeSpecialization}
            options={positionsEnumOptions}
          />
        </Form.Item>

        <Form.Item
          name="grade"
          className={S.register__formItem}
          rules={[
            {
              type: "array",
              required: true,
              message: "Обязательное поле",
              whitespace: true,
            },
          ]}
          validateStatus={!!grade ? "" : "error"}
          help={!!grade ? "" : "Обязательное поле"}
        >
          <Cascader
            style={{ width: "100%" }}
            placeholder="Уровень*"
            onChange={handleChangeLevel}
            options={[
              { value: "Junior", label: "Junior" },
              { value: "Middle", label: "Middle" },
              { value: "Senior", label: "Senior" },
            ]}
          />
        </Form.Item>

        <Form.Item className={S.register__formTerms}>
          <div className={S.account__box}>
            <Checkbox
              name="isPersonalDataPolicyChecked"
              className="account__check"
              onChange={(e) =>
                handleInputChange("isPersonalDataPolicyChecked", e)
              }
            />
            <span className={S.register__terms}>
              {" "}Я подтверждаю согласие с <a
              href={config.privacyDoc}
              target="_blank"
              style={{ color: "#0070E5", textDecoration: "underline" }}
            >
                {" "}Политикой обработки персональных данных
              </a>{" "}
              и даю согласие на обработку моих персональных данных в порядке  и на условиях, указанных в Политике
            </span>
          </div>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="buttonSend"
            disabled={isSubmitDisabled}
          >
            {submitText}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
