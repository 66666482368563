import React, {useState} from 'react';
import s from "./FirstPopupRegistration.module.scss";
import { RegisterForm } from "../../../RegisterForm/RegisterForm";
import { Button } from "antd";
import { ChevronLeft } from "../../../ui/Icons/ChevronLeft";
import { AuthVerification } from "../../AuthVerification/AuthVerification";
import { cleanPhone } from "../../../../utils/helpers";
import { toast } from "react-toastify";
import {registerByPhone, sendCode, verifyCode} from "../../../../api/auth/auth";
import {useAuth} from "../../hooks";
import ym from "react-yandex-metrika";
import { mailMetric } from "../../../../config/env";

const FirstPopupRegistration = () => {
  const [step, setStep] = useState(1);
  const [user, setUser] = useState({})
  const [isShowCountdown, setIsShowCountdown] = useState(false)

  const { onUserLogin, navigate } = useAuth();

  const goToAuth = () => {
    setStep(1)
    navigate('/login')
  }

  const handleSendCode = async (phoneNumber) => {
    await sendCode({
      phone: cleanPhone(phoneNumber),
      toast
    })
  }

  const handleRegisterForm = async (values) => {
    // Mail metric
    // Клик по кнопкам: «Далее» внутри форм регистрации
    window._tmr.push({ type: 'reachGoal', id: mailMetric, goal: 'first_step_registration'});

    setUser(values)
    const responseRegister = await registerByPhone({ user: values, toast })

    if (!responseRegister?.data?.id) {
      return
    }

    const { phone } = values
    await handleSendCode(phone)
    ym("reachGoal", "step1_registration");

    setStep(2)
    setIsShowCountdown(true)
  }

  const handleVerifyRegister = async (values) => {
    // Mail Counter
    // Клик по кнопке: «Завершить регистрацию» внутри формы регистрации
    window._tmr.push({ type: 'reachGoal', id: mailMetric, goal: 'completed_registration'});

    const response = await verifyCode({
      ...values,
      phone: user.phone,
      toast
    })
    if (response?.data?.token) {
      ym("reachGoal", "step2_registration");
      onUserLogin({ token: response.data.token })
    }
  }

  return (
    <div className={s.firstPopupRegistration}>
      <div className={s.firstPopupRegistration__header}>
        {step > 1 && (
          <Button
            type="text"
            onClick={() => setStep(1)}
            className={s.firstPopupRegistration__backLink}
          >
            <ChevronLeft />
          </Button>
        )}
        <h3 className={s.firstPopupRegistration__title}>Регистрация</h3>
      </div>
      {step === 1 && (
        <RegisterForm formSubmitted={handleRegisterForm} submitText="Далее" />
      )}
      {step === 2 && (
        <AuthVerification
          phone={user.phone}
          goToAuth={goToAuth}
          submitText="Завершить регистрацию"
          totalTime={45}
          repeatSendCode={() => handleSendCode(user.phone)}
          isShowCountdown={isShowCountdown}
          onSubmit={handleVerifyRegister}
        />
      )}

      <div className={s.firstPopupRegistration__wrapperButtonFooter}>
        <button
          className='createAccount'
          onClick={() => navigate('/login')}
        >
          Уже есть аккаунт
        </button>
      </div>
    </div>
  );
};

export default FirstPopupRegistration;
