import React  from "react";
import "./Promo.scss";

export const PromoPerform = () => {
  return (
    <div className="promo__info">
      <div
        className="promo__box promo__box_online promo-perform"
      >
        <h3 className="promo__header">Анкета заполнена не&nbsp;полностью</h3>
        <div className="score__info-box">
          <div className="score__span">
            Мы видим, что ты ответил не&nbsp;на&nbsp;все вопросы,
            хотя время ещё не&nbsp;закончилось.
            <br />Сообщи в&nbsp;поддержку, если произошла <span style={{ whiteSpace: "nowrap" }}>какая-то ошибка</span>
          </div>
        </div>
      </div>
    </div>
  );
}
