// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.approve-notice {
  font-size: 17px;
  padding: 20px;
  font-family: "MTS Compact";
}
.approve-notice h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 9px;
}
.approve-notice .notice__text {
  padding-bottom: 24px;
  color: #626C77;
  line-height: 24px;
  font-weight: 400;
  line-height: 1.4;
}
.approve-notice button {
  border-radius: 8px;
  height: 44px;
}`, "",{"version":3,"sources":["webpack://./src/components/RightColumn/ui/ApproveComing/ApproveComing.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,aAAA;EACA,0BAAA;AACF;AAAE;EACE,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;AAEJ;AACE;EACE,oBAAA;EACA,cAAA;EACA,iBAAA;EACA,gBAAA;EACA,gBAAA;AACJ;AACE;EACE,kBAAA;EACA,YAAA;AACJ","sourcesContent":[".approve-notice {\n  font-size: 17px;\n  padding: 20px;\n  font-family: \"MTS Compact\";\n  h3 {\n    font-size: 20px;\n    font-weight: 500;\n    line-height: 1.2;\n    margin-bottom: 9px;\n  }\n\n  .notice__text {\n    padding-bottom: 24px;\n    color: #626C77;\n    line-height: 24px;\n    font-weight: 400;\n    line-height: 1.4;\n  }\n  button {\n    border-radius: 8px;\n    height: 44px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
