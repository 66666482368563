import axios from "axios";
import { setRegistrationStage } from "../../redux/form/form";
import { setUser } from "../../redux/user/user";
import ym from "react-yandex-metrika";
import { loadJS } from "../game/load";
import { apiUrl } from "../../config/env";
import { config } from "../../config/config";
import { notifyErrors } from "../helper/errorResolver";
import { updateFavoriteList } from "../../redux/seminar/seminar";

const baseUrl = process.env.REACT_APP_BASE_URL;
const toast_icon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.68597 4.68743C3.3731 6.00031 3.27575 7.3083 3.08105 9.92429C3.03058 10.6024 3 11.3019 3 12.0015C3 12.701 3.03058 13.4005 3.08105 14.0786C3.27575 16.6946 3.3731 18.0026 4.68597 19.3155C5.99884 20.6284 7.30684 20.7257 9.92282 20.9204C10.601 20.9709 11.3005 21.0015 12 21.0015C12.6995 21.0015 13.399 20.9709 14.0772 20.9204C16.6932 20.7257 18.0012 20.6284 19.314 19.3155C20.6269 18.0026 20.7243 16.6946 20.9189 14.0786C20.9694 13.4005 21 12.701 21 12.0015C21 11.3019 20.9694 10.6024 20.9189 9.92429C20.7243 7.3083 20.6269 6.00031 19.314 4.68743C18.0012 3.37456 16.6932 3.27721 14.0772 3.08252C13.399 3.03205 12.6995 3.00146 12 3.00146C11.3005 3.00146 10.601 3.03205 9.92282 3.08252C7.30684 3.27721 5.99884 3.37456 4.68597 4.68743ZM15.2071 8.79241C15.5976 9.18293 15.5976 9.81609 15.2071 10.2066L13.414 11.9997L15.2071 13.7927C15.5976 14.1833 15.5976 14.8164 15.2071 15.207C14.8166 15.5975 14.1834 15.5975 13.7929 15.207L11.9998 13.4139L10.2071 15.2066C9.81658 15.5971 9.18342 15.5971 8.79289 15.2066C8.40237 14.8161 8.40237 14.1829 8.79289 13.7924L10.5856 11.9997L8.7929 10.207C8.40237 9.81643 8.40237 9.18327 8.7929 8.79274C9.18342 8.40222 9.81658 8.40222 10.2071 8.79274L11.9998 10.5855L13.7929 8.79241C14.1834 8.40188 14.8166 8.40188 15.2071 8.79241Z"
      fill="#FA8A64"
    />
  </svg>
);

export async function putProfileDataRegistration(
  formData,
  accessToken,
  dispatch,
  navigate,
  toast
) {
  try {
    const response = await axios(`${baseUrl}/api/user/profile/`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        stack: formData.stack,
        company: formData.company,
        address: formData.address,
      },
    });
    toast.warn(
      "На указанную электронную почту было направлено письмо со ссылкой для подтверждения регистрации.",
      {
        position: "bottom-center",
        autoClose: false,
        closeOnClick: true,
        closeButton: false,
        icon: ({ theme, type }) => toast_icon,
      }
    );
    await dispatch(setRegistrationStage("first"));
    ym("reachGoal", "profileDataCompletedAfterRegistration");
    navigate("/");
  } catch (err) {
    console.log(err);
  }
}

export async function putProfileData({
   stack,
   company,
   address,
   role,
   firstName,
   lastName,
   phone,
   level,
   accessToken,
   setIsAbout,
   dispatch
 }) {
  try {
    const response = await axios(`${baseUrl}/api/user/profile/`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        stack,
        company,
        address,
        role,
        "first_name": firstName,
        "last_name": lastName,
        phone,
        level
      },
    });
    setIsAbout(false);
    getProfile(accessToken, dispatch, setUser);
  } catch (err) {
    console.log(err);
  }
}

export async function updateProfile({
   user,
   accessToken,
   dispatch,
}) {
  try {
    const response = await axios(`${apiUrl}/v1${config.api.updateProfile}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        data: user
      },
    });
    getProfile(accessToken, dispatch, setUser);
    return response.data
  } catch (error) {
    notifyErrors({ error });
    return { status: 'fail' }
  }
}

export async function getProfile(accessToken, dispatch, setUser) {
  try {
    const response = await axios(`${apiUrl}/v1${config.api.profileInfo}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    // loadJS(response.data.uuid, response.data.activity_start);
    // loadBroadCast();
    dispatch(setUser(response.data?.data));
    dispatch(updateFavoriteList(response.data?.data?.favorites?.map(item => item.event_id)));
  } catch (err) {
    console.log(err);
  }
}

export async function getProfileQr(accessToken) {
  try {
    const response = await axios(`${apiUrl}/v1${config.api.profileQrCode}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response;
  } catch (err) {
    console.log(err);
  }
}

export async function getProfileQrImage(accessToken) {
  try {
    const response = await axios(`${apiUrl}/v1${config.api.profileQrCodePng}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response;
  } catch (err) {
    console.log(err);
  }
}

export async function putAvatar(photo, accessToken, dispatch, setUser) {
  try {
    const response = await axios(`${baseUrl}/api/user/profile/photo/`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        file: photo,
        file_name: "1.png",
      },
    });
    dispatch(setUser(response.data));
  } catch (err) {
    console.log(err);
  }
}

export async function deleteAvatar(accessToken, dispatch, setUser) {
  try {
    const response = await axios(`${baseUrl}/api/user/profile/photo/delete/`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    dispatch(setUser(response.data));
  } catch (err) {
    console.log(err);
  }
}

export async function changeFormat(format, accessToken, dispatch, setUser) {
  try {
    const response = await axios(`${baseUrl}/api/user/profile/event-format/`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        event_format: format,
      },
    });
    getProfile(accessToken, dispatch, setUser);
  } catch (err) {
    console.log(err);
  }
}

export async function getHistory(accessToken, dispatch, setUserHistory) {
  try {
    const response = await axios(
      `${baseUrl}/api/user/profile/balance-history/`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          limit: 15,
          page: 1,
        },
      }
    );
    dispatch(setUserHistory(response.data.results));
  } catch (err) {
    console.log(err);
  }
}

export async function getLeaderboard(
  accessToken,
  dispatch,
  position,
  limit,
  page,
  setLeaders
) {
  try {
    const response = await axios(
      `${baseUrl}/api/user/leaderboard/?get_position=${position}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          limit,
          page,
        },
      }
    );
    dispatch(setLeaders(response.data));
  } catch (err) {
    console.log(err);
  }
}
