/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState} from "react";
import S from "./SeminarLIstItem.module.scss";
import { EventClockIcon } from "../ui/Icons/EventClockIcon";
import DOMPurify from "dompurify";
import BookmarkButton from "../ui/BookmarkButton";
import { useBookmarkSeminar } from "./hooks/useBookmarkSeminar";
import SeminarAssignButton from "./ui/SeminarAssignButton";
import { useDispatch, useSelector } from "react-redux";
import { setIsSeminarInvite, setIsOpenModal } from "../../redux/modal/modal";
import Modal from "../Modal/Modal";
import { setAssignAction, setAssignSeminar } from "../../redux/seminar/seminar";
import {getImagePath} from "../../api/helper/strapiBridge";

function SeminarListItem({ seminar }) {
  const dispatch = useDispatch();
  const { isFavoriteSeminar, updateFavoriteSeminar } = useBookmarkSeminar({ seminar });
  const [isUserAssigned, setIsUserAssigned] = useState(false);
  const { isOpenModal } = useSelector(state => state.modal);
  const { assignList } = useSelector((state) => state.seminar);

  const handleShowModalAssign = () => {
    const isAssigned = assignList.some((item) => item === seminar.id);
    dispatch(setAssignAction(isAssigned ? 'reject' : 'invite'));
    dispatch(setAssignSeminar(seminar)); // Связка модалки для записи на воркшоп с текущим семинаром

    dispatch(setIsOpenModal(true));
    dispatch(setIsSeminarInvite(true));
  };

  useEffect(() => {
    if (assignList) {
      setIsUserAssigned(assignList.some((item) => item === seminar.id));
    }
  }, [assignList, setIsUserAssigned, assignList]);

  return (
    <div className={S.seminarListItem} id={`seminar-${seminar.id}`}>
      <div className={S.seminarListItem__header}>
        <BookmarkButton
          className={S.seminarListItem__bookmark}
          isSelected={isFavoriteSeminar}
          onClick={() => updateFavoriteSeminar()}
        />
        <div className={S.seminarListItem__scheduleWrapper}>
          <div className={S.seminarListItem__schedule}>
            <EventClockIcon className={S.seminarListItem__scheduleIcon} />{seminar.schedule}
          </div>
          {seminar?.count >=0 && (
            <SeminarAssignButton
              isAssigned={isUserAssigned}
              onClick={() => handleShowModalAssign()}
            />
          )}
        </div>
        <div
          dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(seminar.title) }}
          className={S.seminarListItem__title}
        >
        </div>
      </div>
      <div className={S.seminarListItem__curator}>
        <div className={S.seminarListItem__photo}>
          {seminar.curator_photo && (
            <img src={getImagePath(seminar.curator_photo?.url)} alt={seminar.title} />
          )}
        </div>
        <div className={S.seminarListItem__description}>
          <div className={S.seminarListItem__curatorTitle}>
            <span className={S.seminarListItem__curatorName}>{seminar.curator_name}</span>
            <span className={S.seminarListItem__curatorJob}>{seminar.curator_job}</span>
          </div>
          <div className={S.seminarListItem__curatorDescription}>
            {seminar.curator_description}
          </div>
        </div>
      </div>
      {isOpenModal && <Modal />}
    </div>
  );
}

export default SeminarListItem;
