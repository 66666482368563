import { useState, useEffect } from 'react';
import S from '../AuthByPhone/AuthByPhone.module.scss';
import { Button, Form } from 'antd';
import { regexPassword } from "../../../constants/constants";
import { sendCode } from '../../../api/auth/auth';
import { toast } from "react-toastify";
import { MaskedInput } from "antd-mask-input";
import { cleanPhone } from "../../../utils/helpers";

const AuthByPhone = ({ onSendCode }) => {
  const [phone, setPhone] = useState('');

  const [isButtonNextDisabled, setIsButtonNextDisabled] = useState(true);

  const loginConfirmPhone = async () => {
    const response = await sendCode({
      phone: cleanPhone(phone),
      toast
    })
    if (response.success) {
      onSendCode({ phone })
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('--- Failed:', errorInfo);
  };

  useEffect(() => {
    // После ввода телефона доступен переход на шаг 2
    if (cleanPhone(phone).length === 12) {
      setIsButtonNextDisabled(false);
    } else {
      setIsButtonNextDisabled(true);
    }
  }, [phone, setIsButtonNextDisabled]);

  return (
    <Form
      name="basic"
      initialValues={{remember: true}}
      autoComplete="off"
      className={S.wrapperAuthByPhone__formWrapper}
      onFinish={loginConfirmPhone}
      onFinishFailed={onFinishFailed}
    >
      <>
        <Form.Item
          rules={[
            {required: true, message: 'Неверный телефон'},
            {
              pattern: regexPassword,
              message: 'Введите корректный телефон'
            }
          ]}
          className={S.wrapperAuthByPhone__formWrapper__formItem}
        >
          <MaskedInput mask={'+7 (000) 000-0000'} onChange={(e) => setPhone(e.target.value)} />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            style={{marginTop:"0px"}}
            htmlType="submit"
            disabled={isButtonNextDisabled}
            className='buttonSend'
          >
            Далее
          </Button>
        </Form.Item>
      </>
    </Form>
  )
}

export default AuthByPhone
