import { strapiMediaUrl } from "../../config/env";

export const formatList = (data) => {
  return Array.isArray(data) && data.map((item) => {
    const { attributes, ...attrs } = item
    return { ...getFormatAttributes(attributes), ...attrs }
  })
}

export const getImagePath = (imageUrl) => {
  return `${strapiMediaUrl}${imageUrl}`
}

export const getFormatAttributes = (attributes) => {
  Object.keys(attributes).map((key) => {
    if (attributes[key]?.data?.attributes) {
      attributes[key] = attributes[key]?.data?.attributes
    }
  })
  return attributes
}
