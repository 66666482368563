import styled, { css } from "styled-components";
import { device } from "../../../styles/BreakPoints";

export const AnswerStyle = styled.div<{ highlightAnswer: boolean }>`
  font-size: 14px;
  color: #1D2023;
  font-weight: 400;
  cursor: pointer;
  position: relative;
  ${({ highlightAnswer }) =>
  highlightAnswer &&
  css`
      transition: border 0.2s ease-in;
    `}
  @media ${device.md} {
    font-weight: 500;
  }
  input {
    visibility: hidden;
    margin: 0px 12px 0px 0px;
    width: 16px;
    height: 16px;
  }
  input + .radio-label {
    display: block;
    content: "";
    position: absolute;
    left: 0;
    top: 8px;
    width: 16px;
    height: 16px;
    background-image: url(/images/icons/radio.svg);
    background-repeat: no-repeat;
    background-position: left top;
  }
  
  input:checked + .radio-label {
    background-image: url(/images/icons/radio-checked.svg);
  }
  .choice {
    display: block;
    border-bottom: 1px solid rgba(188, 195, 208, 0.5);
    padding-bottom: 4px;
    padding-top: 4px;
    width: 100%;
  }
`

export const AnswerLabel = styled.label`
  padding: 0;
  display: flex;
  cursor: pointer;
  @media ${device.md} {
    padding: 0;
  }
`

export const ChoiceLabel = styled.span``
