/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import S from "./Event.module.scss";
import EventListItem from "./EventListItem";
import { getEvents } from "../../api/events/events";
import { getAccessToken } from "../../utils/helpers";

function EventList({ item, index }) {
  const dispatch = useDispatch();
  const accessToken = getAccessToken();
  const [eventsItems, setEventItems] = useState([]);

  useEffect(() => {
    getEvents(accessToken, dispatch).then((data) => {
      setEventItems(data);
    });
  }, [])

  return (
    <div className={S.EventList}>
      {eventsItems && eventsItems?.map((item) => {
          return (
            <EventListItem item={item} key={item.id} />
          )
        })
      }
    </div>
  );
}

export default EventList;
