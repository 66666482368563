import React, {useEffect, useState} from 'react';
import s from "./AuthPopupPasswordRecovery.module.scss";
import {Button, Form, FormProps, Input} from "antd";
import {FieldType} from "../../../types";
import {useNavigate} from "react-router-dom";
import LoginPage from "../../LoginPage/LoginPage";
import backIcon from '../../../images/backIcon.svg'
import { resetPassword } from '../../../api/auth/auth';
import { toast } from "react-toastify";

const AuthPopupPasswordRecovery = () => {
  const [email, setEmail] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [emailSubmissionStage, setEmailSubmissionStage] = useState('first')
  const [newPasswordForm, setNewPasswordForm] = useState(true)
  const [count, setCount] = useState(30);
  const [isRunning, setIsRunning] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isRunning) return
    const timer = setInterval(() => {
      setCount(prevCount => prevCount - 1);
    }, 1000);

    return () => {
      setIsRunning(false)
      clearInterval(timer)
    };
  }, [isRunning]);

  useEffect(() => {
    setIsButtonDisabled(email.length < 3)
  }, [email]);

  useEffect(() => {
    setEmailSubmissionStage('first')
    setNewPasswordForm(false)
  }, []);

  const onFinish = (values) => {
    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onBackForm = () => {
    setEmail('')
    setEmailSubmissionStage('first')
  }

  const sendEmail = async () => {
    let q = await resetPassword(email, toast)
    if (q) {
      setIsRunning(true)
      setCount(30)
      emailSubmissionStage === 'first' && setEmailSubmissionStage('two')
    }
  }

  return (
    <LoginPage>
      <div className={s.authPopupPasswordRecovery}>
        {newPasswordForm
          ? <div>Новый пароль</div>
          : <>
            <div className={s.authPopupPasswordRecovery__wrapperTitle}>
              {emailSubmissionStage !== 'first' &&
                <button className={s.authPopupPasswordRecovery__wrapperTitle__backButton} onClick={() => onBackForm()}>
                  <img
                    src={backIcon}
                    alt="backButton"/>
                </button>
              }
              <h3 className={s.authPopupPasswordRecovery__title}>Восстановление пароля</h3>
            </div>

            {emailSubmissionStage === 'first' && <span className={s.authPopupPasswordRecovery__subtitle} style={{marginBottom:"35px"}}>Отправим ссылку на восстановление вам на почту</span>}
            {emailSubmissionStage === 'two' &&
              <span style={{marginTop:"0px"}}
                className={s.authPopupPasswordRecovery__subtitle}>Отправили ссылку на создание нового пароля на <br/>{email}</span>}

            <Form
              name="basic"
              initialValues={{remember: true}}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              className={s.authPopupPasswordRecovery__formWrapper}
            >
              {emailSubmissionStage === 'first'
                && <>
                  <Form.Item name={'email'}
                             rules={[{type: 'email', required: true, message: 'Неверная электронная почта'}]}
                             className={s.authPopupPasswordRecovery__formWrapper__itemInput__email}>
                    <Input placeholder="Электронная почта" onChange={(e) => setEmail(e.target.value)}/>
                  </Form.Item>


                  <Button type="primary" style={{marginTop:"10px"}} htmlType="submit" disabled={isButtonDisabled}
                          className='buttonSend'
                          onClick={() => sendEmail()}>
                    Отправить
                  </Button>
                </>
              }

              {emailSubmissionStage === 'two' && <Button type="primary" htmlType="submit" disabled={count > 0}
                                                         className='buttonSend'
                                                         onClick={() => sendEmail()}>
                {count > 0 && `Отправить повторно 00:${count < 10 ? '0': ''}${count}`}
                {count <= 0 && 'Отправить повторно'}
              </Button>}

              <div className={s.authPopupPasswordRecovery__wrapperButtonFooter}>
                {emailSubmissionStage === 'first' ?
                  <>
                    <button className='resetPassword'
                              onClick={() => navigate('/login')}>Вспомнил пароль
                    </button>
                    <button className='createAccount'
                            onClick={() => navigate('/register')}>Создать
                      аккаунт
                    </button>
                  </>
                  :
                  <button style={{paddingLeft:"0px", paddingTop:"76px"}} className='createAccount'
                          onClick={() => navigate('/register')}>Создать
                    аккаунт
                  </button>
                }
              </div>
            </Form>
          </>
        }
      </div>
    </LoginPage>
  );
};
export default AuthPopupPasswordRecovery;
