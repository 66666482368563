/* eslint-disable no-restricted-globals */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { setIsOpenModal, setIsModalAddPhoto } from "../../../redux/modal/modal";
import { useSelector } from "react-redux";
import { setAvatar, setUser } from "../../../redux/user/user";
import { deleteAvatar } from "../../../api/profile/profile";

function ModalChangePhoto() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const baseURL = process.env.REACT_APP_BASE_URL;
  const accessToken = localStorage.getItem("accessToken");

  React.useEffect(() => {
    let timer1 = setTimeout(() => {
      window.newPhotoCode && window.newPhotoCode();
    }, 3000);
    return () => {
      clearTimeout(timer1);
    };
    
  }, []);

  return (
    <div className="modal__new-photo">
      <div className="modal__photo-box">
        <h3 className="modal__header-photo">Фото профиля</h3>
        <button
          className="modal__close-photo"
          onClick={() => {
            dispatch(setIsOpenModal(false));
            dispatch(setIsModalAddPhoto(false));
          }}
        />
      </div>
      <div className="modal__image-box">
        <img
          className="modal__avatar"
          src={`${baseURL}/${user?.photo}`}
          alt="avatar"
        />
        <button
          className="modal__delete"
          onClick={() => {
            deleteAvatar(accessToken, dispatch, setUser);
            dispatch(setIsOpenModal(false));
            dispatch(setIsModalAddPhoto(false));
          }}
        />
      </div>
      <button
        className="modal__button modal__button_confirm"
        onClick={() => deleteAvatar(accessToken, dispatch, setUser)}
      >
        Изменить фото
      </button>
    </div>
  );
}

export default ModalChangePhoto;
