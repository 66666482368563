import { combineReducers, configureStore } from '@reduxjs/toolkit'
import form from '../form/form'
import login from '../login/login'
import modal from '../modal/modal'
import user from '../user/user'
import event from '../event/event'
import button from '../button/button'
import game from '../game/game'
import quiz from '../quiz/quiz'
import seminar from '../seminar/seminar'

const rootReducer = combineReducers({
	form,
	login,
	modal,
	user,
	event,
	button,
	game,
	quiz,
	seminar,
})

export const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
		serializableCheck: false,
	  })
})
