import styled from 'styled-components'
import { device } from '../../../styles/BreakPoints'
interface ButtonType {
  outline?: boolean
  bold?: boolean
  big?: boolean
}

export const BookmarkButtonStyle = styled.button.attrs(({ outline, bold, big }: ButtonType) => ({
  outline,
  bold,
  big,
}))`
  font-weight: ${({ bold }) => (bold ? '700' : '500')};
  width: 36px;
  height: 36px;
  color: #fff;
  background: #F2F3F7;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${device.md} {
    tap-highlight-color: transparent;
    -webkit-tap-highlight-color: transparent;
  }
  &:disabled {
    background: ${({ theme }) => theme.colors.disabledButton};
    color: ${({ theme }) => theme.colors.darkGray};
    cursor: not-allowed;
    transform: unset;
    box-shadow: unset;
  }
`
