// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.news {
  font-size: 18px;
  font-weight: 500;
  padding: 20px;
  font-family: "MTS Compact";
}
.news .news__text {
  padding-bottom: 24px;
  font-size: 20px;
  line-height: 24px;
}
.news .news__link {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: block;
  width: 100%;
  padding-top: 15px;
  color: rgb(0, 112, 229);
  font-size: 14px;
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/components/RightColumn/ui/WatchNews/WatchNews.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,aAAA;EACA,0BAAA;AACF;AACE;EACE,oBAAA;EACA,eAAA;EACA,iBAAA;AACJ;AACE;EACE,wCAAA;EACA,cAAA;EACA,WAAA;EACA,iBAAA;EACA,uBAAA;EACA,eAAA;EACA,gBAAA;AACJ","sourcesContent":[".news {\n  font-size: 18px;\n  font-weight: 500;\n  padding: 20px;\n  font-family: \"MTS Compact\";\n\n  .news__text {\n    padding-bottom: 24px;\n    font-size: 20px;\n    line-height: 24px;\n  }\n  .news__link {\n    border-top: 1px solid rgba(0, 0, 0, 0.1);\n    display: block;\n    width: 100%;\n    padding-top: 15px;\n    color: rgba(0, 112, 229, 1);\n    font-size: 14px;\n    font-weight: 400;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
