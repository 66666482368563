import React, { useState } from "react";
import "./Footer.scss";
import { handleMouseDown } from "../../utils/MouseDown";
import { config } from "../../config/config";

function Footer({ activeMenu }) {
  const [isCodeSucess, setIsCodeSucess] = useState(false);

  React.useEffect(() => {
    isCodeSucess && window.footerBlockAddCode && window.footerBlockAddCode();
  }, [isCodeSucess]);

  const [key, setKey] = React.useState(0);

  React.useEffect(() => {
    setKey(key + 1);
  }, [activeMenu]);

  return (
    <footer
      key={key}
      className="footer container flex !flex-col lg:!flex-row !flex-col-reverse"
    >
      <div className="footer_block">
        <div className="footer__copyright lg:order-1 order-2">
          © 2024 ПАО «МТС»{" "}
          <span className="desktopfooter">
            Все права защищены
          </span>
          <span className="mobilefooter">
            Все права защищены
          </span>
        </div>
      </div>
      <div className="footer__box lg:order-2 order-1 flex flex-col lg:flex-row mb-[20px] lg:mb-0">
        <a href="mailto:support@truetechcamp.ru" target="_blank" className="footer__link lg:mb-0 mb-[4px]">
          Обратная связь
        </a>
        <a href="https://t.me/TrueTechCamp" target="_blank" className="footer__link lg:mb-0 mb-[4px]">
          Телеграм-канал
        </a>
        {/* <a href="https://docs.google.com/document/d/1tseyo53JSw9HLs1dQjsybhrINa0Qyzji/" target="_blank" className="footer__link lg:!ml-[71px] !ml-0">
          Пользовательское соглашение
        </a> */}
      </div>
    </footer>
  );
}

export default Footer;
