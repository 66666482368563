import { createSlice } from "@reduxjs/toolkit";

const game = createSlice({
  name: "game",
  initialState: {
    total: null,
    game: false,
    time: "",
    gameTimeD: 1,
  },
  reducers: {
    setTotal(state, action) {
      state.total = action.payload;
    },
    setGame(state, action) {
      state.game = action.payload;
    },
    setTime(state, action) {
      state.time = action.payload;
    },
    setGameTimeD(state, action) {
      state.gameTimeD = action.payload;
    },
  },
});

export default game.reducer;

export const { setGame, setTime, setTotal, setGameTimeD } = game.actions;
