// Преобразование GUID (UUID) в число
export function guidToInt(guid) {
  if (guid) {
    console.log("ok");
    let hexNumbers = guid.split("-").join("");
    let result = parseInt(hexNumbers, 16);
    return result;
  }
}

// Преобразование числа обратно в GUID (UUID)
export function intToGuid(num) {
  let hexString = num.toString(16);
  let guid = hexString.replace(
    /^(.{8})(.{4})(.{4})(.{4})(.{12})$/,
    "$1-$2-$3-$4-$5"
  );
  return guid;
}
