// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leaderboard {
  min-height: 100%;
}

@media screen and (max-width: 460px) {
  .download {
    min-width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/CentralColumn/LeaderboardColumn.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AAGA;EACI;IACI,eAAA;EAAN;AACF","sourcesContent":[".leaderboard {\n    min-height: 100%;\n    // max-height: 981px;\n}\n\n@media screen and (max-width: 460px) { \n    .download {\n        min-width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
