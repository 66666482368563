/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import S from "./SeminarList.module.scss";
import SeminarListItem from "./SeminarListItem";
import { getSeminars } from "../../api/events/events";
import { getAccessToken } from "../../utils/helpers";
import { setFilteredSeminars } from "../../redux/seminar/seminar";
import { Link } from "react-router-dom";
import { useBookmarksSeminar } from "./hooks/useBookmarksSeminar";

function SeminarBookmarks() {
  const dispatch = useDispatch();
  const accessToken = getAccessToken();
  const { bookmarks, favoriteList } = useBookmarksSeminar();

  useEffect(() => {
    getSeminars(accessToken, dispatch).then(() => {
      // Устанавливаем первоначальный фильтр после загрузки семинаров
      dispatch(setFilteredSeminars());
    });
  }, [])

  return (
    <div className={S.SeminarList}>
      {bookmarks?.map((item) => {
          return (
            <SeminarListItem seminar={item} key={item?.id} />
          )
        })
      }
      {(!favoriteList || favoriteList?.length === 0) && (
        <div className={`text-[#1D2023] text-[16px] bg-white p-[24px] rounded-[20px] mb-[20px] w-full`}>
          Чтобы добавить мероприятия, перейдите{' '}
          в <Link to={'/seminar'} className="text-[#0070e5]">Программу активностей</Link>.
        </div>
      )}
    </div>
  );
}

export default SeminarBookmarks;
