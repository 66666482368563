import { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import { setFavorite } from "../../../redux/seminar/seminar";
import { addSeminarBookmark, removeSeminarBookmark } from "../../../api/events/events";
import { getAccessToken } from "../../../utils/helpers";
import { getProfile } from "../../../api/profile/profile";
import { setUser } from "../../../redux/user/user";

export const useBookmarkSeminar = ({ seminar }) => {
  const dispatch = useDispatch();
  const accessToken = getAccessToken();
  const [isFavoriteSeminar, setIsFavoriteSeminar] = useState(false);
  const { favoriteList, seminars } = useSelector((state) => state.seminar);

  const updateFavoriteSeminar = async () => {
    setIsFavoriteSeminar((prev) => !prev);
    // TODO логика на добавление в избранное семинара через api
    const favoriteItem = favoriteList?.find((item) => String(item) === String(seminar.id))
    if (!!favoriteItem) {
      await removeSeminarBookmark({ id: seminar.id, accessToken })
    } else {
      await addSeminarBookmark({ id: seminar.id, accessToken })
    }
    await getProfile(accessToken, dispatch, setUser);
  }

  useEffect(() => {
    if (seminar) {
      const favoriteItem = favoriteList?.find((item) => String(item) === String(seminar.id))
      if (!!favoriteItem) {
        setIsFavoriteSeminar(true);
      }
    }
  }, [favoriteList]);

  return {
    favoriteList,
    isFavoriteSeminar,
    setIsFavoriteSeminar,
    updateFavoriteSeminar,
  }
}
