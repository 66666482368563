/* eslint-disable no-restricted-globals */
import React, {useEffect, useState} from "react";
import { useDispatch } from "react-redux";
import { setIsOpenModal, setIsSeminarInvite } from "../../../redux/modal/modal";
import { useSelector } from "react-redux";
import { setAssignList, setAssignSeminar, clearAssign } from "../../../redux/seminar/seminar";

function ModalSeminarInvite() {
  const dispatch = useDispatch();
  const { assignSeminar, assignAction } =
    useSelector((state) => state.seminar);
  const [modalText, setModalText] = useState('');
  const [buttonText, setButtonText] = useState('');

  const handleConfirm = () => {
    dispatch(setAssignList(assignSeminar.id))
    dispatch(setIsOpenModal(false));
    dispatch(clearAssign()) // Обнуляем семинар для записи
  }

  useEffect(() => {
    if (assignAction === 'invite') {
      setModalText(`Количество мест ограничено. Подтверди свою запись на воркшоп «${assignSeminar?.title}»`)
      setButtonText('Подтвердить')
    } else {
      setModalText(`Отказаться от участия в воркшоп «${assignSeminar?.title}»`)
      setButtonText('Отказаться от участия')
    }
  }, [assignAction, assignSeminar?.title])

  return (
    <div className="modal__container">
      <div className="modal__confirm-box">
        <div className="modal__confirm-text">
          {modalText}
        </div>
        <button
          className="modal__content-close"
          onClick={() => {
            dispatch(setIsOpenModal(false));
            dispatch(setIsSeminarInvite(false));
          }}
        />
      </div>
      {assignSeminar?.id && (
        <div className="modal__footer">
          <button
            className="button-confirm button_send"
            onClick={() => handleConfirm()}
          >
            {buttonText}
          </button>
        </div>
      )}
    </div>
  );
}

export default ModalSeminarInvite;
