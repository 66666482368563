import React from 'react'
import './Modal.scss'
import ModalOffline from './ModalOffline/ModalOffline';
import ModalPhoto from './ModalPhoto/ModalPhoto';
import ModalChangePhoto from './ModalChangePhoto/ModalChangePhoto';
import ModalHistory from './ModalHistory/ModalHistory';
import ModalRules from './ModalRules/ModalRules';
import { useSelector } from 'react-redux';
import ModalCalendar from './ModalCalendar/ModalCalendar';
import ModalSpeaker from './ModalSpeaker/ModalSpeaker';
import ModalQr from "./ModalQr/ModalQr";
import ModalSeminarInvite from "./ModalSeminarInvite/ModalSeminarInvite";

function Modal () {
    const {
        modalOffline,
        modalAddPhoto,
        modalHistory,
        modalRules,
        modalCalendar,
        isModalSpeaker,
        modalQr,
        modalSeminarInvite,
    } = useSelector((state) => state.modal);
    const { user } = useSelector((state) => state.user);

    return (
        <div className='modal modal_opened'>
            { modalOffline === true && <ModalOffline /> }
            { (modalAddPhoto === true && !user?.photo) && <ModalPhoto /> }
            { (modalAddPhoto === true && user?.photo) && <ModalChangePhoto /> }
            { modalQr === true && <ModalQr />}
            { modalHistory === true && <ModalHistory/> }
            { modalRules === true && <ModalRules/> }
            { modalCalendar === true && <ModalCalendar/> }
            { isModalSpeaker === true && <ModalSpeaker/> }
            { modalSeminarInvite === true && <ModalSeminarInvite /> }
        </div>
    )
}

export default Modal;
