import React from "react";
import "./Promo.scss";

export const PromoRefuse = () => {
  return (
    <div className="promo__info">
      <div
        className="promo__box promo__box_online promo-refuse"
      >
        <h3 className="promo__header">Увидимся в другой раз</h3>
        <div className="score__info-box">
          <div className="score__span">
            <p>
              Спасибо за интерес к MTC True Tech Camp.
              <br />В&nbsp;этот раз у&nbsp;нас не получится провести время вместе,
              но ждём тебя на других наших мероприятиях!
            </p>
            <p>
              Обязательно тебе о них сообщим.
              <br />Подпишись на наш telegram-канал,  чтобы&nbsp;быть&nbsp;в&nbsp;курсе новостей
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
