import React from "react";
import "./CentralColumn.scss";
import "./EventsColumn.scss";
import SeminarList from "../Seminar/SeminarList";
import { useNavigate } from "react-router-dom";
import SeminarTabs from "../Seminar/SeminarTabs";
import EventsHeader from "./ui/EventsHeader/EventsHeader";

function EventsColumn() {
  const navigate = useNavigate();

  const handleChangeTab = (tab) => {
    navigate(tab);
  }

  return (
    <>
      <div className={`column mt-[8px] lg:mt-0`}>
        <div
          className={`events-header text-[#1D2023] text-[20px] bg-white p-[24px] rounded-[20px] mb-[20px]`}
        >
          <EventsHeader />
          <div>
            <button
              className={'button_secondary mb-[15px] mr-[10px] width-[156px]'}
              onClick={() => handleChangeTab('/event')}
            >
              Отдыхай
            </button>
            <button className={'button_send'}>
              Изучай
            </button>
          </div>
          <SeminarTabs />
        </div>
        <SeminarList />
      </div>
    </>
  );
}

export default EventsColumn;
