import axios from "axios";
import { getProfile } from "../profile/profile";
import { setUser } from "../../redux/user/user";

const baseUrl = process.env.REACT_APP_BASE_URL

export async function getNotificationList (accessToken, setNotifs) {
	try {
		const response = await axios(`${baseUrl}/api/user/notifications/`, {
			method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
		});
        setNotifs(response.data);
	} catch (err) {
		console.log(err)
	}
}
export async function setNotificationSeen (accessToken, id, dispatch) {
	try {
		const response = await axios(`${baseUrl}/api/user/notifications/${id}/mark/`, {
			method: "PUT",
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
		});
		getProfile(accessToken, dispatch, setUser);
	} catch (err) {
		console.log(err)
	}
}
