export const config = {
  api: {
    authSendCode: "/auth/send-code",
    authVerifyCode: "/auth/verify-code",
    authRegister: "/auth/register",
    profileInfo: "/actions/profile-info",
    updateProfile: "/actions/profile-update",
    getQuiz: "/actions/quiz-perform",
    quizAnswer: "/actions/quiz-answer",
    quizCompleted: "/actions/quiz-complete",
    profileQrCode: "/actions/profile-qrcode",
    profileQrCodePng: "/actions/profile-qrcode-png",
    seminarFavoriteAdd: "/actions/favorite-add",
    seminarFavoriteRemove: "/actions/favorite-remove"
  },
  service: {
    isRegisterAvailable: false
  },
  user: {
    roles: [
      { value: "Developer", label: "Разработчик" },
      { value: "Architect", label: "Архитектор" },
      { value: "Analyst", label: "Аналитик" },
      { value: "Tester", label: "Тестировщик" },
      { value: "Management", label: "Менеджмент" },
      { value: "MarketingAndPR", label: "Маркетинг и PR" },
      { value: "Design", label: "Дизайн" },
      { value: "CyberSecurity", label: "Cyber Security" },
      { value: "DevOps", label: "DevOps-инженер" },
      { value: "TechLead/TeamLead", label: "TechLead/TeamLead" },
    ]
  },
  privacyDoc: "https://disk.yandex.ru/i/7GQM4hTElOtQNw",
  telegram: {
    logBotApiKey: "7299575455:AAEVBx_CrmriU07jemhcOv5Bq_8-tEkel_k",
    logBotChatId: "329901226",
  },
  docs: {
    scheduleDownload: "/docs/schedule.pdf"
  }
}
