/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import "./Personal.scss";
import { useSelector } from "react-redux";
import point from "../../images/point.svg";
import edit from "../../images/Edit.svg";
import { ViewAvatar } from "./ui/ViewAvatar/ViewAvatar";
import { positionsEnumLabels } from "../../constants/enum";
import { useProfile } from "../hooks/useProfile";

function Personal({ setIsAbout }) {
  const { user } = useSelector((state) => state.user);

  const { isStackAvailable } = useProfile({ user });

  return (
    <>
      <div className="personal">
        <div className="personal__info">
          <div className="personal__info-box">
            <ViewAvatar />
            <div className="personal__box">
              <h3 className="personal__header">
                {" "}
                {user?.firstName} {user?.secondName} {user?.lastName}{" "}
              </h3>
              <div className="personal__row">
                <span className="personal__contacts">
                  {" "}
                  {user?.phone?.replace(
                    /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/,
                    "$1 ($2) $3-$4-$5"
                  )}{" "}
                </span>
                <img className="personal__point" src={point} alt="point" />
                <span className="personal__contacts"> {user?.email} </span>
              </div>
            </div>
          </div>
        </div>
        <div className="personal__profile">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            <div>
              <div className="personal__attr">
                <div className="personal__attrLabel">Роль:</div>
                <div className="personal__attrValue">
                  {positionsEnumLabels[user.position]}
                </div>
              </div>
              {isStackAvailable && user?.stack && (
                <div className="personal__attr">
                  <div className="personal__attrLabel">Стэк:</div>
                  <div className="personal__attrValue">{user.stack}</div>
                </div>
              )}
              {user?.grade && (
                <div className="personal__attr">
                  <div className="personal__attrLabel">Уровень:</div>
                  <div className="personal__attrValue">{user.grade}</div>
                </div>
              )}
            </div>
            <div>
              {user?.address && (
                <div className="personal__attr">
                  <div className="personal__attrLabel">Город:</div>
                  <div className="personal__attrValue">{user.address}</div>
                </div>
              )}
              {user?.company && (
                <div className="personal__attr">
                  <div className="personal__attrLabel">Компания:</div>
                  <div className="personal__attrValue">{user.company}</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="personal__box_bottom">
          <span className="personal__span" onClick={() => setIsAbout(true)}>
            <img className="personal__edit" src={edit} alt="edit" />
            {user?.stack ? "Редактировать данные" : "Дополнить данные"}
          </span>
        </div>
      </div>
    </>
  );
}

export default Personal;
