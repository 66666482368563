import { FC } from 'react'

import { Flex } from '../../../styles/Global'
import { formatTime } from '../../../utils/helpers'
import { ActiveQuestionNo, TotalQuestionNo, QuizProgress, QuizHeading } from './styled'
import Counter from './Counter'

interface QuizHeaderProps {
  activeQuestion: number
  totalQuestions: number
  timer: number
}

const QuizHeader: FC<QuizHeaderProps> = ({ activeQuestion, totalQuestions, timer }) => {
  return (
    <>
      <Flex spaceBetween gap="6px">
        <QuizHeading>Квиз</QuizHeading>
        <Flex>
          <Counter time={`${formatTime(timer)}`} />
        </Flex>
      </Flex>
      <QuizProgress>
        <ActiveQuestionNo>Вопрос {activeQuestion + 1}&nbsp;</ActiveQuestionNo>
        <TotalQuestionNo>из {totalQuestions}</TotalQuestionNo>
      </QuizProgress>
    </>
  )
}

export default QuizHeader
