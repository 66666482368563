import React, { useEffect, useState } from "react";
import "./RightColumn.scss";
import { useSelector } from "react-redux";
import { WatchNews } from "./ui/WatchNews/WatchNews";
import { ApproveComing } from "./ui/ApproveComing/ApproveComing";

function RightColumn({ activeMenu }) {
  const [isCodeSucess, setIsCodeSucess] = React.useState(false);
  const { user } = useSelector((state) => state.user);
  const [userStatus, setUserStatus] = useState('');

  React.useEffect(() => {
    let timer1 = setTimeout(() => {
      isCodeSucess && window.rightBlockAddCode && window.rightBlockAddCode();
    }, 1000);
    return () => {
      clearTimeout(timer1);
    };
  });

  useEffect(() => {
    if (user?.status) {
      setUserStatus(user.status);
    }
  }, [user]);

  return (
    <div
      className={`column ${activeMenu == "broadcast" ? "" : "right_column"}`}
    >
      {userStatus === 'invite' && (
        <ApproveComing />
      )}
      <WatchNews />
    </div>
  );
}

export default RightColumn;
