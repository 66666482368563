import { createSlice } from '@reduxjs/toolkit'

const modal = createSlice({
	name: 'modal',
	initialState: {
		isOpenModal: false,
		modalOffline: false,
		modalAddPhoto: false,
		modalHistory: false,
		modalRules: false,
		burgerOpened: false,
		modalCalendar: false,
		calendarLinks: [],
		isModalSpeaker: false,
		modalQr: false,
		modalSeminarInvite: false,
		modalSeminarReject: false,
	},
	reducers: {
		setIsOpenModal(state, action) {
			state.isOpenModal = action.payload
		},
		setIsModalOffline(state, action) {
			state.modalOffline = action.payload
		},
		setIsModalAddPhoto(state, action) {
			state.modalAddPhoto = action.payload
		},
		setIsModalHistory(state, action) {
			state.modalHistory = action.payload
		},
		setIsModalRules(state, action) {
			state.modalRules = action.payload
		},
		setBurgerOpened(state, action) {
			state.burgerOpened = action.payload
		},
		setModalCalendar(state, action) {
			state.modalCalendar = action.payload
		},
		setCalendarLinks(state, action) {
			state.calendarLinks = action.payload
		},
		setIsModalSpeaker(state, action) {
			state.isModalSpeaker = action.payload
		},
		setIsModalQr(state, action) {
			state.modalQr = action.payload
		},
		setIsSeminarInvite(state, action) {
			state.modalSeminarInvite = action.payload
		},
		setIsSeminarReject(state, action) {
			state.modalSeminarReject = action.payload
		},
	}
})

export default modal.reducer

export const {
	setIsOpenModal,
	setIsModalOffline,
	setIsModalAddPhoto,
	setIsModalHistory,
	setIsModalRules,
	setBurgerOpened,
	setModalCalendar,
	setCalendarLinks,
	setIsModalSpeaker,
	setIsModalQr,
	setIsSeminarInvite,
	setIsSeminarReject,
} = modal.actions
