// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from './index'

export const developer: Topic = {
  topic: 'Developer',
  level: 'Beginner',
  totalQuestions: 5,
  totalScore: 15,
  totalTime: 900,
  questions: [
    {
      id: '10',
      question:
        'Что такое переменная и как ее объявить в выбранном вами языке программирования?',
      choices: [
        {
          id: '101',
          value: 'Место для хранения значений, объявляется с помощью ключевых слов `var`, `let` или `const`',
        },
        {
          id: '102',
          value: 'Функция, которая принимает параметры',
        },
        {
          id: '103',
          value: 'Условное выражение, которое возвращает `true` или `false`',
        },
        {
          id: '104',
          value: 'Циклическая структура, используемая для повторения действий',
        },
      ],
      type: 'MCQs',
      correctAnswers: [
        {
          id: '101',
          value: 'Место для хранения значений, объявляется с помощью ключевых слов `var`, `let` или `const`',
        },
      ],
      score: 10,
    },
    {
      id: '20',
      question: 'Как работает оператор `if-else`?',
      choices: [
        {
          id: '201',
          value: 'Проверяет условие и выполняет блок кода, если условие истинно, иначе выполняет другой блок кода',
        },
        {
          id: '202',
          value: 'Повторяет блок кода до тех пор, пока условие истинно',
        },
        {
          id: '203',
          value: 'Используется для определения функции',
        },
        {
          id: '204',
          value: 'Создает новую переменную и присваивает ей значение',
        },
      ],
      type: 'MCQs',
      correctAnswers: [
        {
          id: '201',
          value: 'Проверяет условие и выполняет блок кода, если условие истинно, иначе выполняет другой блок кода',
        },
      ],
      score: 5,
    },
    {
      id: '30',
      question:
        'Что такое переменная и как ее объявить в выбранном вами языке программирования?',
      choices: [
        {
          id: '301',
          value: 'Место для хранения значений, объявляется с помощью ключевых слов `var`, `let` или `const`',
        },
        {
          id: '302',
          value: 'Функция, которая принимает параметры',
        },
        {
          id: '303',
          value: 'Условное выражение, которое возвращает `true` или `false`',
        },
        {
          id: '304',
          value: 'Циклическая структура, используемая для повторения действий',
        },
      ],
      type: 'MCQs',
      correctAnswers: [
        {
          id: '301',
          value: 'Место для хранения значений, объявляется с помощью ключевых слов `var`, `let` или `const`',
        },
      ],
      score: 10,
    },
    {
      id: '40',
      question: 'Как работает оператор `if-else`?',
      choices: [
        {
          id: '401',
          value: 'Проверяет условие и выполняет блок кода, если условие истинно, иначе выполняет другой блок кода',
        },
        {
          id: '402',
          value: 'Повторяет блок кода до тех пор, пока условие истинно',
        },
        {
          id: '403',
          value: 'Используется для определения функции',
        },
        {
          id: '404',
          value: 'Создает новую переменную и присваивает ей значение',
        },
      ],
      type: 'MCQs',
      correctAnswers: [
        {
          id: '401',
          value: 'Проверяет условие и выполняет блок кода, если условие истинно, иначе выполняет другой блок кода',
        },
      ],
      score: 5,
    },
    {
      id: '50',
      question: 'Как работает оператор `if-else`?',
      choices: [
        {
          id: '501',
          value: 'Проверяет условие и выполняет блок кода, если условие истинно, иначе выполняет другой блок кода',
        },
        {
          id: '502',
          value: 'Повторяет блок кода до тех пор, пока условие истинно',
        },
        {
          id: '503',
          value: 'Используется для определения функции',
        },
        {
          id: '504',
          value: 'Создает новую переменную и присваивает ей значение',
        },
      ],
      type: 'MCQs',
      correctAnswers: [
        {
          id: '501',
          value: 'Проверяет условие и выполняет блок кода, если условие истинно, иначе выполняет другой блок кода',
        },
      ],
      score: 5,
    },
  ],
}
