import styled from "styled-components";
import {device} from "../../../styles/BreakPoints";

export const QuestionContainer = styled.div`
  max-width: 100%;
  @media ${device.sm} {
    max-width: 100%;
  }
`

export const AnswersContainer = styled.div`
  max-width: 100%;
  @media ${device.sm} {
    max-width: 100%;
  }
`

export const QuestionStyle = styled.h2`
  font-family: "MTS Text"; 
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.primaryText};
  line-height: 1.4;
  letter-spacing: -0.02em;
`
