import React, {useEffect} from "react";
import "./Promo.scss";
import { setIsModalQr, setIsOpenModal } from "../../redux/modal/modal";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "antd";
import Modal from "../Modal/Modal";
import { useProfile } from "../hooks/useProfile";
import { downloadBase64File } from "../../utils/helpers";

export const PromoQr = () => {
  const dispatch = useDispatch();
  const { isOpenModal } = useSelector(state => state.modal);
  const { user } = useSelector((state) => state.user);
  const {
    getUserQr,
    getUserQrImage,
    qrInline,
    setQrInline
  } = useProfile({ user });

  const handleShowQr = () => {
    dispatch(setIsOpenModal(true));
    dispatch(setIsModalQr(true));
  }

  const handleDownload = () => {
    getUserQrImage().then((res) => {
      downloadBase64File({
        contentType: 'image/png',
        base64Data: res,
        fileName: 'TrueTechCamp2024-QR.png'
      })
    })
  }

  useEffect(() => {
    getUserQr().then((res) => {
      setQrInline(res)
    })
  }, [])

  return (
    <div className="promo__info">
      <div
        className="promo__box promo__box_online promo-qr"
      >
        <h3 className="promo__header">Приглашаем тебя на&nbsp;МТС True&nbsp;Tech&nbsp;Camp</h3>
        <div className="score__info-box">
          <div className="score__span">
            <p>
              Скачай Qr-код для прохода на мероприятие и приходи на тусовку 26 июля в&nbsp;14:00&nbsp;в&nbsp;Dream&nbsp;Beach&nbsp;Club
            (Москва, Андропова, 1).
            </p>
            <p>
              Опция «Помощь другу» не сработает — попасть на&nbsp;мероприятие сможет только тот, кто прошёл  регистрацию.
              Не забудь взять паспорт или права.
            </p>
          </div>
          <div className="score__qr">
            <Button type="text" onClick={handleShowQr}>
              <div
                className="score__qr-image"
                dangerouslySetInnerHTML={{ __html: qrInline }}
              ></div>
            </Button>
            <Button onClick={() => handleDownload()} type='text' className="score__qr-download">
              Скачать QR
            </Button>
          </div>
        </div>
      </div>
      {isOpenModal && <Modal />}
    </div>
  );
}
