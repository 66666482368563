/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import "./Qr.scss";
import DownloadArrow from "../../images/DonloadArrow.svg";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { QRCodeCanvas } from "qrcode.react";
import { setUser } from "../../redux/user/user";

function Qr() {
  const { user } = useSelector((state) => state.user);
  const [qr, setQr] = useState(false);
  const [endTime, setEndTime] = useState();
  let screenWidth = window.innerWidth;
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      let unixTimestampCur = moment().unix();
      let start = 1714935600;

      if (start - unixTimestampCur > 0) {
        const totalTimeInSeconds = start - unixTimestampCur;
        setEndTime({
          days: moment(start * 1000).diff(moment(), "days"),
          hours: Math.floor((totalTimeInSeconds % (60 * 60 * 24)) / (60 * 60)),
          minute: Math.floor((totalTimeInSeconds % (60 * 60)) / 60),
          second: Math.floor(totalTimeInSeconds % 60),
        });
      } else {
        clearInterval(interval);
      }
    }, 1000);
  });

  const downloadQRCode = () => {
    const canvas = document.querySelector("#qrcode-canvas");
    if (!canvas) throw new Error("<canvas> not found in the DOM");

    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "QR code.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  useEffect(() => {});
  return (
    <>
      {user?.uuid && user?.invitation && (
        <div className="qr">
          <div className="qr__div">
            <div className="qr__box qr__box_medium">
              <QRCodeCanvas id="qrcode-canvas" value={user?.uuid} size={96} />
            </div>
            <div className="qr__box qr__box_small">
              <QRCodeCanvas id="qrcode-canvas" value={user?.uuid} size={70} />
            </div>
            <div className="qr__container">
              <span className="qr__span">
                Ваш бейдж для входа на мероприятие
              </span>
              <div className="qr__box qr__box_big">
                <QRCodeCanvas
                  id="qrcode-canvas"
                  value={user?.uuid}
                  size={169.41}
                />
              </div>
              <button className="qr__link" onClick={downloadQRCode}>
                <img src={DownloadArrow} alt="download link" />
                Скачать QR
              </button>
            </div>
          </div>
          <button className="qr__link qr__link_2" onClick={downloadQRCode}>
            <img src={DownloadArrow} alt="download link" />
            Скачать QR
          </button>
        </div>
      )}
    </>
  );
}

export default Qr;
