import { createSlice } from '@reduxjs/toolkit'

const event = createSlice({
	name: 'event',
	initialState: {
		event: [],
		schedule: [],
		speaker: [],
		tabs: [],
	},
	reducers: {
		setEvent(state, action) {
			state.event = action.payload
		},
		setSchedule(state, action) {
			state.schedule = action.payload
		},
		setSpeaker(state, action) {
			state.speaker = action.payload
		},
		setTabs(state, action) {
			state.tabs = action.payload
		},
	}
})

export default event.reducer

export const { 
	setEvent,
	setSchedule,
	setSpeaker,
	setTabs,
} = event.actions