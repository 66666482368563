import React, {useEffect, useState} from "react";
import "./Profile.scss";
import {Button, Cascader, Form, Input} from "antd";
import { updateProfile } from "../../api/profile/profile";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getAccessToken } from "../../utils/helpers";
import { toast } from "react-toastify";
import { ChevronDown } from "../ui/Icons/ChevronDown";
import { stackList } from "../../data/profileData";
import {useAuthValidator} from "../Auth/hooks/useAuthValidator";
import S from "../RegisterForm/RegisterForm.module.scss";
import { useProfile, stackDisableValue } from "../hooks/useProfile";

export const ProfileForm = ({
  onSubmit = (values) => {},
  submitText = "Сохранить",
  isShowEmail = true,
}) => {
  const dispatch = useDispatch();
  const accessToken = getAccessToken();
  const { user } = useSelector((state) => state.user);
  const [stack, setStack] = useState(user?.stack ? [user?.stack] : "");
  const [stackOptions, setStackOptions] = useState([]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const { isStackAvailable } = useProfile({ user });

  const [form] = Form.useForm();
  const [formData, setFormData] = useState({
    stack: user?.stack || "",
  });

  const { emailValidator } = useAuthValidator();

  const handleInputChange = (key, e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name ? name : key]:
        key === "stack" ?
          Array.isArray(value) ? value[0] : value
          : value,
    });
  };

  const handleChangeStack = (value) => {
    setStack(value);
    setIsSubmitDisabled(false);
  };

  const handleClearStack = () => {
    setStack("");
    setFormData({ ...{ formData }, ...{ stack: "" } })
    setIsSubmitDisabled(true);
  };

  const onFinish = async (values) => {
    if (isStackAvailable && !values?.stack) {
      setIsSubmitDisabled(true)
      return
    }
    const { stack = "Без стэка", ...formValues } = values
    const { id, createdAt, updatedAt, status, ...profile } = user
    const response = await updateProfile({
      user: {
        ...profile,
        ...formValues,
        ...{ stack: Array.isArray(stack) ? stack[0] : stack },
      },
      accessToken,
      dispatch,
      toast
    });
    onSubmit({ response });
  };

  useEffect(() => {
    if (isStackAvailable) {
      if (formData?.stack || !!stack) {
        setIsSubmitDisabled(false);
      } else {
        setIsSubmitDisabled(true);
      }
    }
  }, [isStackAvailable, formData?.stack, stack, setIsSubmitDisabled]);

  useEffect(() => {
    if (user?.position && stackList[user?.position]) {
      setStackOptions(stackList[user.position])
    }
  }, [user.position]);

  useEffect(() => {
    if (user?.stack) {
      setStack([user.stack])
    }
    if (!isStackAvailable) {
      setStack(stackDisableValue)
    }
  }, [user.stack]);

  return (
    <Form
      name="basic"
      form={form}
      autoComplete="off"
      className="profile__form"
      onFinish={onFinish}
      initialValues={{ ...user, remember: true }}
    >
      <span className="about__form-span">Отчество</span>
      <Form.Item
        name="secondName"
        rules={[
          { type: "string", required: true, message: "Введите отчество" },
        ]}
      >
        <Input
          placeholder="Отчество"
          name="secondName"
          onChange={(event) => handleInputChange('secondName', event)}
        />
      </Form.Item>
      {isStackAvailable && (
        <>
          <span className="profile__form-span">Стэк</span>
          <Form.Item
            name="stack"
            validateStatus={!!stack ? "" : "error"}
            help={!!stack ? "" : "Обязательное поле"}
          >
            <Cascader
              style={{ width: "100%" }}
              placeholder="Стэк"
              showSearch={{ matchInputWidth: false }}
              onChange={handleChangeStack}
              onClear={handleClearStack}
              expandIcon={<ChevronDown />}
              options={stackOptions}
            />
          </Form.Item>
        </>
      )}
      <span className="profile__form-span">Компания</span>
      <Form.Item
        name="company"
        rules={[
          { type: "string", required: true, message: "Введите компанию" },
        ]}
      >
        <Input
          placeholder="Компания"
          name="company"
          onChange={(event) => handleInputChange('company', event)}
        />
      </Form.Item>
      <span className="profile__form-span">Город</span>
      <Form.Item
        name="address"
        rules={[
          {
            type: "string",
            required: true,
            message: "Введите город или регион, где живёте",
          },
        ]}
      >
        <Input
          placeholder="Город или регион, где живёте"
          name="address"
          onChange={(event) => handleInputChange('address', event)}
        />
      </Form.Item>
      {isShowEmail && (
        <>
          <span className="profile__form-span">Email</span>
          <Form.Item
            name="email"
            rules={[
              { validator: emailValidator }
            ]}
            className={S.register__formEmail}
          >
            <Input
              placeholder="Email*"
              name="email"
              onChange={(e) => handleInputChange('email', e)}
            />
          </Form.Item>
          <div className={S.register__itemNote}>
            Будет использоваться для рассылки приглашений и новостей о мероприятии
          </div>
        </>
      )}

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="profile__send"
          disabled={isSubmitDisabled}
        >
          {submitText}
        </Button>
      </Form.Item>
    </Form>
  )
}
