import { setIsLogin } from "../../../redux/login/login";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export const useAuth = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onUserLogin = ({ token }: { token: string }) => {
    if (token) {
      localStorage.setItem("accessToken", token);
      localStorage.setItem("login", "true");
      dispatch(setIsLogin(true));
      navigate('/account');
    }
  }

  return {
    dispatch,
    navigate,
    onUserLogin,
  }
}
