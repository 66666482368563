export const getStorageUtm = () => {
  let utms = "";
  for (const element of [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_term",
    "utm_content",
  ]) {
    utms = utms + element + "=" + localStorage.getItem(element) + "&";
  }
  return utms
}
