// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.right_column {
  width: 360px;
}
.right_column * {
  box-sizing: border-box;
}
@media screen and (max-width: 460px) {
  .right_column {
    width: 100%;
  }
}

@media screen and (max-width: 1440px) {
  .desktopRight {
    display: none;
  }
}
@media screen and (max-width: 1270px) {
  .right_column {
    display: none;
  }
}
.code_main {
  width: 50px;
  height: 48px;
  position: absolute;
  word-wrap: break-word;
  text-align: center;
  bottom: 121px;
  right: 125px;
}

.code_text {
  color: #ff0032;
  font-family: "MTS Compact";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

.code_mainBlock {
  width: 260px;
  height: 289px;
  background-color: rgb(248, 249, 251);
  position: absolute;
  top: 550px;
  right: 15px;
  z-index: 1;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/RightColumn/RightColumn.scss"],"names":[],"mappings":"AAAA;EAIE,YAAA;AAFF;AADE;EACE,sBAAA;AAGJ;AACE;EANF;IAOI,WAAA;EAEF;AACF;;AACA;EACE;IACE,aAAA;EAEF;AACF;AACA;EACE;IACE,aAAA;EACF;AACF;AAEA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,qBAAA;EACA,kBAAA;EACA,aAAA;EACA,YAAA;AAAF;;AAEA;EACE,cAAA;EACA,0BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;AACF;;AACA;EACE,YAAA;EACA,aAAA;EACA,oCAAA;EACA,kBAAA;EACA,UAAA;EACA,WAAA;EACA,UAAA;EACA,eAAA;AAEF","sourcesContent":[".right_column {\n  & * {\n    box-sizing: border-box;\n  }\n  width: 360px;\n\n  @media screen and (max-width: 460px) {\n    width: 100%;\n  }\n}\n\n@media screen and (max-width: 1440px) {\n  .desktopRight {\n    display: none;\n  }\n}\n\n@media screen and (max-width: 1270px) {\n  .right_column {\n    display: none;\n  }\n}\n\n.code_main {\n  width: 50px;\n  height: 48px;\n  position: absolute;\n  word-wrap: break-word;\n  text-align: center;\n  bottom: 121px;\n  right: 125px;\n}\n.code_text {\n  color: #ff0032;\n  font-family: \"MTS Compact\";\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 500;\n}\n.code_mainBlock {\n  width: 260px;\n  height: 289px;\n  background-color: rgba(248, 249, 251, 1);\n  position: absolute;\n  top: 550px;\n  right: 15px;\n  z-index: 1;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
