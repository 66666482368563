import React from "react";
import "./WatchNews.scss"

export const WatchNews = () => {
  return (
    <div className="news bg-white rounded-[20px] mb-[32px] text-center">
      <div className="news__text">
        Следи за&nbsp;новостями
        в&nbsp;телеграм-канале  МТС&nbsp;True&nbsp;Tech&nbsp;Camp
      </div>
      <a className="news__link" href="https://t.me/TrueTechCamp" target="_blank" title="Перейти в телеграмм">Подписаться</a>
    </div>
  );
}
