/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import S from "./SeminarTabs.module.scss";
import { setFilteredSeminars, setTab, setTag } from "../../redux/seminar/seminar";

function SeminarTabs({ item, index }) {
  const dispatch = useDispatch();
  const { tabs, tag } = useSelector((state) => state.seminar);

  const handleChangeTab = (tab) => {
    dispatch(setTab(tab.id));
    dispatch(setTag(tab.tag))
    dispatch(setFilteredSeminars(tab.tag));
  };

  useEffect(() => {
    if (tag && tabs) {
      const selectedTab = tabs.find((item) => item.tag === tag);
      if (selectedTab) handleChangeTab(selectedTab);
    } else if (tabs) {
      handleChangeTab(tabs[0]);
    }
  }, [tag]);

  return (
    <div className={S.SeminarTabs}>
      {tabs && tabs?.map((item) => {
          return (
            <button
              type="button"
              key={item.id}
              onClick={() => handleChangeTab(item)}
              className={item.isSelected ? S.SeminarTabs__itemSelected : ''}
            >
              {item.title}
            </button>
          )
        })
      }
    </div>
  );
}

export default SeminarTabs;
