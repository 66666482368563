import React  from "react";
import "./Promo.scss";
import { useDispatch } from "react-redux";
import {
  setIsOpenModal,
  setIsModalRules,
} from "../../redux/modal/modal";

export const PromoAnnouncement = () => {
  const dispatch = useDispatch();

  return (
    <div className="promo__info">
      <div
        className="promo__box promo__box_online"
      >
        <h3 className="promo__header">Анкета участника</h3>
        <div className="score__info-box">
          <div className="score__span">
            Чтобы попасть на мероприятие, ответь
            на&nbsp;вопросы&nbsp;анкеты. Она состоит из&nbsp;двух частей:
            сначала нужно заполнить информацию о&nbsp;себе,
            а&nbsp;затем пройти небольшую игру за&nbsp;15&nbsp;минут
          </div>
          <button
            className="score__button"
            onClick={(e) => {
              dispatch(setIsOpenModal(true));
              dispatch(setIsModalRules(true));
            }}
          >
            Перейти к анкете
          </button>
        </div>
      </div>
    </div>
  );
}
