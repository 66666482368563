// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SeminarList_SeminarList__Fn89Q {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/Seminar/SeminarList.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,2BAAA;EACA,eAAA;EACA,SAAA;AACF","sourcesContent":[".SeminarList {\n  width: 100%;\n  display: flex;\n  justify-content: flex-start;\n  flex-wrap: wrap;\n  gap: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SeminarList": `SeminarList_SeminarList__Fn89Q`
};
export default ___CSS_LOADER_EXPORT___;
