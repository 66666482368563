// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.codenrock {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.codenrock button {
  font-family: "MTS Compact";
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: rgb(255, 255, 255);
  width: 335px;
  height: 52px;
  background-color: rgb(255, 0, 50);
  border-radius: 8px;
  margin-top: 32px;
}`, "",{"version":3,"sources":["webpack://./src/components/Codenrock/Codenrock.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;AACJ;AACI;EAEI,0BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,yBAAA;EACA,YAAA;EACA,YAAA;EACA,iCAAA;EACA,kBAAA;EACA,gBAAA;AAAR","sourcesContent":[".codenrock {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n\n    button {\n        //styleName: Paragraph/P3 Medium Comp;\n        font-family: \"MTS Compact\";\n        font-size: 17px;\n        font-weight: 500;\n        line-height: 24px;\n        text-align: center;\n        color: rgba(255, 255, 255, 1);\n        width: 335px;\n        height: 52px;\n        background-color: rgba(255, 0, 50, 1);\n        border-radius: 8px;\n        margin-top: 32px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
