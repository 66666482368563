import React from 'react'
import './ServerError.scss'
import { useNavigate } from "react-router-dom";
import mistake_500 from "../../images/500.png"

function ServerError() {
    const navigate = useNavigate();
    const isLogin = localStorage.getItem("login");

    return (
        <div className='not-found'>
            <img
                src={mistake_500}
                alt='error 500'
            />
            <h2>Ошибка сервера</h2>
            <span>На сервере произошла непредвиденная ошибка. Пожалуйста, подождите, она вскоре будет исправлена</span>
            <button
                onClick={ () => isLogin ? navigate('/account') : navigate('/login')}
            >На главную</button>
        </div>
    )
}

export default ServerError;
