import React  from "react";
import "./Promo.scss";

export const PromoUncomplete = () => {
  return (
    <div className="promo__info">
      <div
        className="promo__box promo__box_online promo-uncompleted"
      >
        <h3 className="promo__header">Время вышло</h3>
        <div className="score__info-box">
          <div className="score__span">
            К сожалению, тебе не удалось пройти тест за&nbsp;15&nbsp;минут.
            <br />Дождись обратной связи после проверки
            твоей анкеты модератором
          </div>
        </div>
      </div>
    </div>
  );
}
