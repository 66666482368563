import React from "react";
import "./CentralColumn.scss";
import "./EventsColumn.scss";
import EventList from "../Event/EventList";
import { useNavigate } from "react-router-dom";
import EventsHeader from "./ui/EventsHeader/EventsHeader";

function EventsColumn() {
  const navigate = useNavigate();

  const handleChangeTab = (tab) => {
    navigate(tab);
  }

  return (
    <>
      <div className={`column mt-[8px] lg:mt-0`}>
        <div
          className={`events-header text-[#1D2023] text-[20px] bg-white p-[24px] rounded-[20px] mb-[24px]`}
        >
          <EventsHeader />

          <div>
            <button className={'button_send mr-[10px] width-[156px]'}>
              Отдыхай
            </button>
            <button className={'button_secondary'} onClick={() => handleChangeTab('/seminar')}>
              Изучай
            </button>
          </div>
        </div>
        <EventList />
      </div>
    </>
  );
}

export default EventsColumn;
