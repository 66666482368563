export type Option = { value: string; label: string }
export type OptionEnum<T> = { value: T; label: string }

export enum UserStatuses {
  registered = "registered",
  verified = "verified",
  graded = "graded",
}

export enum PositionsEnum {
  Developer = "Developer",
  Architect = "Architect",
  Analyst = "Analyst",
  Tester = "Tester",
  Management = "Management",
  MarketingAndPR = "MarketingAndPR",
  Design = "Design",
  CyberSecurity = "CyberSecurity",
  DevOps = "DevOps",
  "TechLead/TeamLead" = "TechLead/TeamLead",
}

export const positionsEnumLabels: Record<PositionsEnum, string> =
  {
    [PositionsEnum.Developer]: "Разработчик",
    [PositionsEnum.Architect]: "Архитектор",
    [PositionsEnum.Analyst]: "Аналитик",
    [PositionsEnum.Tester]: "Тестировщик",
    [PositionsEnum.Management]: "Менеджмент",
    [PositionsEnum.MarketingAndPR]: "Маркетинг и PR",
    [PositionsEnum.Design]: "Дизайн",
    [PositionsEnum.CyberSecurity]: "Cyber Security",
    [PositionsEnum.DevOps]: "DevOps-инженер",
    [PositionsEnum["TechLead/TeamLead"]]: "TechLead/TeamLead",
  }
export const positionsEnumOptions: Option[] = Object.keys(
  positionsEnumLabels,
).map((value) => {
  const label =
    positionsEnumLabels[value as keyof typeof positionsEnumLabels]
  return {
    label,
    value,
  }
})
