import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { setNotificationSeen } from "../../api/notification/notification";
import moment from "moment";
import "../CentralColumn/CentralColumn.scss";
import { setIsModalHistory, setIsOpenModal } from "../../redux/modal/modal";
import { ReactComponent as CheckBoxOn } from "../../images/svg/chekbox.svg";
import { ReactComponent as CheckBoxOff } from "../../images/svg/checkboxoff.svg";
import { useNavigate } from "react-router-dom";

function Notification(card, indexNotif, setNotifs) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notif = card?.card;
  const token = localStorage.getItem("accessToken");
  const [checked, setCheked] = useState(notif.read);

  function setDate(time) {
    const stamp = moment(time).unix();
    const date = new Date(stamp * 1000);
    const formattedDate = date.toLocaleDateString();
    return formattedDate;
  }

  function setTime(time) {
    const stamp = moment(time).unix();
    const date = new Date(stamp * 1000);
    const formattedTime = date.toLocaleTimeString("en", {
      timeStyle: "short",
      hour12: false,
      timeZone: "Europe/Moscow",
    });
    return formattedTime;
  }
  const [isCodeSucess, setIsCodeSucess] = useState(false);

  useEffect(() => {
    isCodeSucess &&
      window.notificationHeaderCode &&
      window.notificationHeaderCode();
  }, [isCodeSucess]);

  useEffect(() => {
    window.notificationSetCodeCard && window.notificationSetCodeCard();
  }, []);
  console.log(card);
  return (
    <li
      id={`notification-${card.indexNotif}`}
      className="rounded-[20px] bg-white mb-[8px] last:mb-0 p-[16px] flex flex-col lg:flex-row justify-between"
      onMouseOver={() => {
        notif?.read === false &&
          checked === false &&
          setNotificationSeen(token, notif?.id, dispatch);
        setCheked(true);
      }}
    >
      <div
        className={`ml-[0px] flex lg:hidden flex-row justify-between mb-[16px]`}
      >
        <p className={`text-[17px] font-normal leading-[24px] text-[#626C77]`}>
          {setDate(notif?.created_at)}
        </p>
        <p className={`text-[17px] font-normal leading-[24px] text-[#A5AEB9]`}>
          {setTime(notif.created_at)}
        </p>
      </div>
      <div className="flex flex-col max-w-[638px] lg:mb-0 mb-[16px]">
        <span
          className={`flex items-center text-[24px] leading-[28px] font-medium`}
        >
          {notif.title}
          {checked === false ? (
            <div className="checkBoxHover flex items-center relative ml-[8px]">
              <CheckBoxOn />
            </div>
          ) : (
            <div className="checkBoxHover flex items-center relative ml-[8px]">
              <CheckBoxOff />
            </div>
          )}
        </span>
        <p className="text-[#626C77] text-[17px] font-normal leading-[24px] mt-[8px]">
          {notif.description}
        </p>
      </div>
      <div className="flex">
        {notif.title === "Не забудь про бейдж на True Tech Camp!" && (
          <button
            className={
              "button__red rounded-[8px] py-[10px] px-[18px] text-black text-[17px] font-medium leading-[24px] h-[44px] whitespace-nowrap ml-[5px] lg:w-auto w-full"
            }
            onClick={() => navigate("/account")}
          >
            Скачать QR-cod для участия
          </button>
        )}
        {notif.title === "Поздравляем!" && (
          <button
            className={
              "rounded-[8px] py-[10px] px-[18px] text-black text-[17px] font-medium leading-[24px] h-[44px] whitespace-nowrap ml-[5px] lg:w-auto w-full bg-[#F2F3F7]"
            }
            onClick={() => {
              dispatch(setIsOpenModal(true));
              dispatch(setIsModalHistory(true));
            }}
          >
            Посмотреть свои баллы
          </button>
        )}
        {notif.title === "Любишь подарки?" && (
          <button
            className={
              "rounded-[8px] py-[10px] px-[18px] text-black text-[17px] font-medium leading-[24px] h-[44px] whitespace-nowrap ml-[5px] lg:w-auto w-full bg-[#F2F3F7] bg-[#F2F3F7]"
            }
            onClick={() => navigate("/account")}
          >
            На главную
          </button>
        )}
        {notif.title === "!" && (
          <button
            className={clsx(
              notif.buttonStyle == "red" ? "bg-[#FF0032] text-white" : "",
              notif.buttonStyle == "gray" ? "bg-[#F2F3F7]" : "",
              notif.buttonStyle == "not-active"
                ? "bg-[#BCC3D080] text-[#969FA8] opacity-50 pointer-events-none"
                : "",
              "rounded-[8px] py-[10px] px-[18px] text-black text-[17px] font-medium leading-[24px] h-[44px] whitespace-nowrap ml-[5px] lg:w-auto w-full bg-[#F2F3F7]"
            )}
          >
            !
          </button>
        )}
        <div
          className={`timebox ml-[16px] hidden lg:flex flex-col justify-between`}
        >
          <p
            className={`text-[17px] font-normal leading-[24px] text-[#626C77]`}
          >
            {setDate(notif.created_at)}
          </p>
          <p
            className={`text-[17px] font-normal leading-[24px] text-[#A5AEB9]`}
          >
            {setTime(notif.created_at)}
          </p>
        </div>
      </div>
    </li>
  );
}

export default Notification;
