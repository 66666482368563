// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AuthPopupPasswordRecovery_authPopupPasswordRecovery__Uge-D {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.AuthPopupPasswordRecovery_authPopupPasswordRecovery__wrapperTitle__T\\+PW1 {
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 25px;
}
.AuthPopupPasswordRecovery_authPopupPasswordRecovery__wrapperTitle__backButton__MYOx0 {
  margin: 0px 30px -7px -60px;
}
.AuthPopupPasswordRecovery_authPopupPasswordRecovery__title__wWUu4 {
  display: inline-block;
  font-weight: 500;
  font-size: 25px;
  letter-spacing: -0.5px;
  font-family: "MTS Compact";
}
.AuthPopupPasswordRecovery_authPopupPasswordRecovery__subtitle__oKi4- {
  margin-bottom: 35px;
  color: #000;
  opacity: 0.5;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.AuthPopupPasswordRecovery_authPopupPasswordRecovery__formWrapper__b6GEc {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.AuthPopupPasswordRecovery_authPopupPasswordRecovery__wrapperButtonFooter__RNRdd {
  margin-top: 25px;
  padding-bottom: 7px;
  display: flex;
  justify-content: center;
}
.AuthPopupPasswordRecovery_authPopupPasswordRecovery__wrapperButtonFooter__RNRdd button {
  width: unset;
  margin: unset;
}
.AuthPopupPasswordRecovery_authPopupPasswordRecovery__wrapperButtonFooter__RNRdd button:first-child {
  padding-right: 20px;
  padding-left: 0px;
}
.AuthPopupPasswordRecovery_authPopupPasswordRecovery__wrapperButtonFooter__RNRdd button:last-child {
  padding-left: 20px;
  padding-right: 0px;
}

@media (max-width: 520px) {
  .AuthPopupPasswordRecovery_authPopupPasswordRecovery__wrapperButtonFooter__RNRdd {
    width: unset;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Auth/AuthPopupPasswordRecovery/AuthPopupPasswordRecovery.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;AACJ;AACI;EACE,2BAAA;AACN;AAGE;EACE,qBAAA;EACA,gBAAA;EACA,eAAA;EACA,sBAAA;EACA,0BAAA;AADJ;AAIE;EACE,mBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAFJ;AAKE;EACE,WAAA;EACA,aAAA;EACA,sBAAA;AAHJ;AAME;EACE,gBAAA;EACA,mBAAA;EACA,aAAA;EACA,uBAAA;AAJJ;AAMI;EACE,YAAA;EACA,aAAA;AAJN;AAKM;EACE,mBAAA;EACA,iBAAA;AAHR;AAKM;EACE,kBAAA;EACA,kBAAA;AAHR;;AASA;EAEI;IACE,YAAA;EAPJ;AACF","sourcesContent":[".authPopupPasswordRecovery {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  &__wrapperTitle {\n    display: flex;\n    align-items: center;\n    text-align: center;\n    margin-bottom: 25px;\n\n    &__backButton {\n      margin: 0px 30px -7px -60px;\n    }\n  }\n\n  &__title {\n    display: inline-block;\n    font-weight: 500;\n    font-size: 25px;\n    letter-spacing: -0.5px;\n    font-family: \"MTS Compact\";\n  }\n\n  &__subtitle {\n    margin-bottom: 35px;\n    color: #000;\n    opacity: 0.5;\n    text-align: center;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 24px;\n  }\n\n  &__formWrapper {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n  }\n\n  &__wrapperButtonFooter {\n    margin-top: 25px;\n    padding-bottom:7px;\n    display: flex;\n    justify-content: center;\n\n    button{\n      width: unset;\n      margin: unset;\n      &:first-child{\n        padding-right:20px;\n        padding-left:0px;\n      }\n      &:last-child{\n        padding-left:20px;\n        padding-right:0px;\n      }\n    }\n  }\n}\n\n@media (max-width: 520px) {\n  .authPopupPasswordRecovery{\n    &__wrapperButtonFooter {\n      width: unset;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"authPopupPasswordRecovery": `AuthPopupPasswordRecovery_authPopupPasswordRecovery__Uge-D`,
	"authPopupPasswordRecovery__wrapperTitle": `AuthPopupPasswordRecovery_authPopupPasswordRecovery__wrapperTitle__T+PW1`,
	"authPopupPasswordRecovery__wrapperTitle__backButton": `AuthPopupPasswordRecovery_authPopupPasswordRecovery__wrapperTitle__backButton__MYOx0`,
	"authPopupPasswordRecovery__title": `AuthPopupPasswordRecovery_authPopupPasswordRecovery__title__wWUu4`,
	"authPopupPasswordRecovery__subtitle": `AuthPopupPasswordRecovery_authPopupPasswordRecovery__subtitle__oKi4-`,
	"authPopupPasswordRecovery__formWrapper": `AuthPopupPasswordRecovery_authPopupPasswordRecovery__formWrapper__b6GEc`,
	"authPopupPasswordRecovery__wrapperButtonFooter": `AuthPopupPasswordRecovery_authPopupPasswordRecovery__wrapperButtonFooter__RNRdd`
};
export default ___CSS_LOADER_EXPORT___;
