import { createSlice } from "@reduxjs/toolkit";

const quiz = createSlice({
  name: "quiz",
  initialState: {
    questions: [],
    totalQuestions: 0,
    quizCompleted: false,
  },
  reducers: {
    setQuizQuestions(state, action) {
      state.questions = action.payload;
    },
    setTotalQuestions(state, action) {
      state.totalQuestions = action.payload;
    },
    setQuizCompleted(state, action) {
      state.quizCompleted = action.payload;
    }
  },
});

export default quiz.reducer;

export const { setQuizQuestions, setTotalQuestions, setQuizCompleted } = quiz.actions;
