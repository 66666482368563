// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AuthPopupSetNewPassword_authPopupSetNewPassword__m2zYO {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.AuthPopupSetNewPassword_authPopupSetNewPassword__title__LqiHn {
  display: inline-block;
  font-weight: 500;
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 28px;
  font-family: "MTS Compact";
}
.AuthPopupSetNewPassword_authPopupSetNewPassword__formWrapper__tV-ta {
  width: 100%;
  display: flex;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/components/Auth/AuthPopupSetNewPassword/AuthPopupSetNewPassword.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;AACE;EACE,qBAAA;EACA,gBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,0BAAA;AACJ;AAEE;EACE,WAAA;EACA,aAAA;EACA,sBAAA;AAAJ","sourcesContent":[".authPopupSetNewPassword {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  &__title {\n    display: inline-block;\n    font-weight: 500;\n    margin-bottom: 20px;\n    font-size: 24px;\n    line-height: 28px;\n    font-family: \"MTS Compact\";\n  }\n\n  &__formWrapper {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"authPopupSetNewPassword": `AuthPopupSetNewPassword_authPopupSetNewPassword__m2zYO`,
	"authPopupSetNewPassword__title": `AuthPopupSetNewPassword_authPopupSetNewPassword__title__LqiHn`,
	"authPopupSetNewPassword__formWrapper": `AuthPopupSetNewPassword_authPopupSetNewPassword__formWrapper__tV-ta`
};
export default ___CSS_LOADER_EXPORT___;
