import { createSlice } from '@reduxjs/toolkit'

const user = createSlice({
	name: 'user',
	initialState: {
		avatar: "",
		user: [],
		userHistory: [],
		leaders: null
	},
	reducers: {
		setAvatar(state, action) {
			state.avatar = action.payload
		},
		setUser(state, action) {
			state.user = action.payload
		},
		setUserHistory(state, action) {
			state.userHistory = action.payload
		},
		setLeaders(state, action) {
			state.leaders = action.payload
		},
	}
})

export default user.reducer

export const {
	setAvatar,
	setUser,
	setUserHistory,
	setLeaders
} = user.actions
