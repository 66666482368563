/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import S from "./SeminarList.module.scss";
import SeminarListItem from "./SeminarListItem";
import { getSeminars } from "../../api/events/events";
import { getAccessToken } from "../../utils/helpers";
import { setFilteredSeminars } from "../../redux/seminar/seminar";

function SeminarList() {
  const dispatch = useDispatch();
  const accessToken = getAccessToken();
  const { filteredSeminars } = useSelector((state) => state.seminar);

  useEffect(() => {
    getSeminars(accessToken, dispatch).then(() => {
      // Устанавливаем первоначальный фильтр после загрузки семинаров
      dispatch(setFilteredSeminars());
    });
  }, [])

  return (
    <div className={S.SeminarList}>
      {filteredSeminars && filteredSeminars?.map((item) => {
          return (
            <SeminarListItem seminar={item} key={item?.id} />
          )
        })
      }
    </div>
  );
}

export default SeminarList;
