import type { BaseOptionType, DefaultOptionType } from 'rc-cascader';

type StackList = {
  [key: string]: BaseOptionType[]
}

export const stackList: StackList = {
  "Developer": [
    { value: "Java-разработчик", label: "Java-разработчик" },
    { value: "Golang-разработчик", label: "Golang-разработчик" },
    { value: "Мобильный разработчик", label: "Мобильный разработчик" },
    { value: "Разработчик C#/.NET", label: "Разработчик C#/.NET" },
    { value: "Разработчик С++", label: "Разработчик С++" },
    { value: "Разработчик на JS", label: "Разработчик на JS" },
    { value: "Разработчик на Python", label: "Разработчик на Python" },
    { value: "PHP- разработчик", label: "PHP- разработчик" },
    { value: "ML - разработчик", label: "ML - разработчик" },
    { value: "Разработчик с другим стеком", label: "Разработчик с другим стеком" },
  ],
  "Architect": [
    { value: "Инфраструктурный архитектор", label: "Инфраструктурный архитектор" },
    { value: "Сетевой архитектор", label: "Сетевой архитектор" },
    { value: "Корпоративный (Enterprise) архитектор", label: "Корпоративный (Enterprise) архитектор" },
    { value: "Solution архитектор", label: "Solution архитектор" },
    { value: "Системный-архитектор", label: "Системный-архитектор" },
  ],
  "Tester": [
    { value: "Manual QA Engineer", label: "Manual QA Engineer" },
    { value: "Automation QA Engineer", label: "Automation QA Engineer" },
  ],
  "Analyst": [
    { value: "ML-исследователь", label: "ML-исследователь" },
    { value: "UX-исследователь", label: "UX-исследователь" },
    { value: "Data Scientist", label: "Data Scientist" },
    { value: "Системный аналитик", label: "Системный аналитик" },
    { value: "Продуктовый аналитик", label: "Продуктовый аналитик" },
    { value: "Бизнес-аналитик", label: "Бизнес-аналитик" },
  ],
  "Management": [
    { value: "Project manager", label: "Project manager" },
    { value: "Product manager", label: "Product manager" },
    { value: "Product Owner", label: "Product Owner" },
    { value: "Agile", label: "Agile" },
    { value: "Scrum", label: "Scrum" },
  ],
  "Design": [
    { value: "Дизайнер", label: "Дизайнер" },
    { value: "Продуктовый дизайнер", label: "Продуктовый дизайнер" },
    { value: "Коммуникационный дизайнер", label: "Коммуникационный дизайнер" },
    { value: "Графический дизайнер", label: "Графический дизайнер" },
    { value: "UX/UI дизайн", label: "UX/UI дизайн" },
    { value: "Дизайн-директор", label: "Дизайн-директор" },
    { value: "Арт-директор", label: "Арт-директор" },
  ],
  "MarketingAndPR": [
    { value: "Маркетолог", label: "Маркетолог" },
    { value: "Интернет-маркетолог", label: "Интернет-маркетолог" },
    { value: "Маркетолог-аналитик", label: "Маркетолог-аналитик" },
    { value: "UX редактор", label: "UX редактор" },
    { value: "UX писатель", label: "UX писатель" },
    { value: "PR-менеджер", label: "PR-менеджер" },
    { value: "Cjm-эксперт", label: "Cjm-эксперт" },
  ],
  "CyberSecurity": [
    { value: "Без стэка", label: "Без стэка" },
  ],
  "DevOps": [
    { value: "Без стэка", label: "Без стэка" },
  ],
  "TechLead/TeamLead": [
    { value: "Без стэка", label: "Без стэка" },
  ],
}
