import React from 'react'
import Popup from "../Popup/Popup";
import s from './LoginPage.module.scss'
import backgroundLoginPage from '../../images/backgroundLoginPage.png'

const LoginPage = ({children}: any) => (
  <div className={s.loginPage} >
    <div className={s.loginPage__wrapper}>
      <Popup children={children}/>
    </div>
  </div>
)

export default LoginPage
