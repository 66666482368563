import { sendLogToBot } from "../../api/logging/logging";

export const useLogBot = () => {

  const sendLogBot = ({ message }) => {
    sendLogToBot({ message })
  }

  return {
    sendLogBot,
  }
}
