import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useBookmarksSeminar = () => {
  const { favoriteList, seminars } = useSelector((state) => state.seminar);
  const [bookmarks, setBookmarks] = useState([])

  useEffect(() => {
    if (favoriteList?.length && seminars?.length) {
      const bookmarksItems = favoriteList?.map((item) => {
        return seminars.find((el) => String(el?.id) === String(item))
      })
        .sort((a, b) => String(a.schedule).toLowerCase() > String(b.schedule).toLowerCase() ? 1 : -1);
      setBookmarks(bookmarksItems)
    } else {
      setBookmarks([])
    }
  }, [favoriteList, seminars])

  return {
    favoriteList,
    bookmarks,
  }
}
