import React from "react";
import bigAvavtar from "../../../../images/BigAvatar.svg";
import { useSelector } from "react-redux";
import { baseUrl } from "../../../../config/env";

export const ViewAvatar = () => {
  const { user } = useSelector((state) => state.user);

  return (
    <div className="personal__photo">
      <img
        className="personal__avatar"
        src={user?.photo ? `${baseUrl}/${user?.photo}` : bigAvavtar}
        alt="avatar"
      ></img>
    </div>
  )
}
