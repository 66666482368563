const baseUrl = process.env.REACT_APP_BASE_URL;

export const loadJS = (uid, start) => {
  try {
    if (start !== null) {
      return new Promise((resolve, reject) => {
        let scriptEle = document.createElement("script");

        scriptEle.setAttribute("src", `${baseUrl}/api/user/${uid}/script.js`);
        scriptEle.setAttribute("type", "text/javascript");
        scriptEle.setAttribute("async", true);

        scriptEle.addEventListener("load", () => {
          resolve();
        });

        scriptEle.addEventListener("error", (err) => {
          console.log("err", err);
          reject(err);
        });

        document.body.appendChild(scriptEle);
      });
    }
  } catch (err) {
    console.log(err);
  }
};


      