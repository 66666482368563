import axios from "axios";
import {
  setSchedule,
  setEvent,
  setSpeaker,
  setTabs,
} from "../../redux/event/event";
import {
  setCalendarLinks,
  setIsModalSpeaker,
  setIsOpenModal,
} from "../../redux/modal/modal";
import { setFilteredSeminars, setSeminars } from "../../redux/seminar/seminar";
import {strapiToken, strapiApi, apiUrl, baseUrl} from "../../config/env";
import { formatList } from "../helper/strapiBridge";
import { config } from "../../config/config";

export async function getEvents(accessToken, dispatch, tag) {
  try {
    const response = await axios(`${strapiApi}/events?populate=image`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${strapiToken}`,
      }
    });
    const res = formatList(response.data?.data) || []
    dispatch(setEvent(res));
    dispatch(setFilteredSeminars());
    return res;
  } catch (err) {
    console.log(err);
  }
}

export async function getSeminars(accessToken, dispatch) {
  try {
    const response = await axios(`${strapiApi}/seminars?populate=curator_photo`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${strapiToken}`,
      }
    });
    const res = formatList(response.data?.data) || []
    dispatch(setSeminars(res));
    return res;
  } catch (err) {
    console.log(err);
  }
}

export async function addSeminarBookmark({ id, accessToken }) {
  try {
    const response = await axios(`${apiUrl}/v1${config.api.seminarFavoriteAdd}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      data: {
        data: {
          eventId: id
        }
      },
    });

    return response;
  } catch (err) {
    console.log(err);
  }
}

export async function removeSeminarBookmark({ id, accessToken }) {
  try {
    const response = await axios(`${apiUrl}/v1${config.api.seminarFavoriteRemove}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      data: {
        data: {
          eventId: id
        }
      },
    });

    return response;
  } catch (err) {
    console.log(err);
  }
}

export async function getSchedule(accessToken, dispatch) {
  try {
    const response = await axios(`${baseUrl}/api/events/schedule/`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    dispatch(setSchedule(response.data.results));
  } catch (err) {
    console.log(err);
  }
}

export async function scheduleToggle(
  accessToken,
  dispatch,
  id,
  setIsAdded,
  isAdded
) {
  try {
    const response = await axios(
      `${baseUrl}/api/events/${id}/schedule-toggle/`,
      {
        method: "PUT",
        data: {
          id: id,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    getSchedule(accessToken, dispatch, setEvent);
    setIsAdded(!isAdded);
  } catch (err) {
    console.log(err);
  }
}

export async function getTabs(accessToken, dispatch) {
  try {
    const response = await axios(`${baseUrl}/api/events/tag`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    dispatch(setTabs(response.data));
  } catch (err) {
    console.log(err);
  }
}

export async function getCalendarLinks(accessToken, dispatch) {
  try {
    const response = await axios(`${baseUrl}/api/user/calendar/links/`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    dispatch(setCalendarLinks(response.data));
  } catch (err) {
    console.log(err);
  }
}

export async function getSpeakerInfo(accessToken, dispatch, id) {
  try {
    const response = await axios(`${baseUrl}/api/events/speaker/${id}/`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    dispatch(setSpeaker(response.data));
    dispatch(setIsModalSpeaker(true));
    dispatch(setIsOpenModal(true));
  } catch (err) {
    console.log(err);
  }
}

export async function watchingBroadcast(accessToken) {
  try {
    const response = await axios(`${baseUrl}/api/user/watching-broadcast/`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  } catch (err) {
    console.log(err);
  }
}
