import { FC } from 'react'
import { AnswerStyle, AnswerLabel, ChoiceLabel } from './styled'
import { AnswerType } from '../../../data/QuizQuestions/index'

interface AnswerProps {
  index: number
  answer: AnswerType
  type: string
  selectedAnswer: AnswerType[]
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const Answer: FC<AnswerProps> = ({
  onChange,
  index,
  answer,
  type,
  selectedAnswer,
}) => {

  return (
    <AnswerStyle
      key={index}
      highlightAnswer={
        !!selectedAnswer.find((item) => item.id === answer?.id)
      }
    >
      <AnswerLabel>
        <input
          name={answer.id}
          // radio is for checked one option and checkbox is for checked multiple options
          type={type === 'MAQs' ? 'checkbox' : 'radio'}
          checked={
            !!selectedAnswer.find((item) => item.answer === answer?.answer)
          }
          onChange={onChange}
          value={index}
        />
        <span className="radio-label"></span>
        <span className="choice">{answer?.answer}</span>
      </AnswerLabel>
    </AnswerStyle>
  )
}

export default Answer
