import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import "./CentralColumn.scss";
import static_avatar from "../../images/static_avatar.svg";
import point from "../../images/point_leader.svg";
import { getLeaderboard } from "../../api/profile/profile";
import { setLeaders } from "../../redux/user/user";
import "./LeaderboardColumn.scss";

function LeaderboardColumn() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(11);
  const [position, setPosition] = useState(false);
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("accessToken");
  const { leaders, user } = useSelector((state) => state.user);
  const baseURL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    getLeaderboard(accessToken, dispatch, position, limit, page, setLeaders);
  }, [position, accessToken, page, limit]);

  useEffect(() => {
    setTimeout(() => {
      setUsers([]);
      setLeaders(null);
    }, []);
  }, []);

  useEffect(() => {
    if (leaders) {
      if (users.length > 0) {
        let res = [...users];
        leaders.results.forEach((user) => {
          res.push(user);
        });
        let uniqueArr = res.filter(
          (item, index, self) =>
            index ===
            self.findIndex((t) => JSON.stringify(t) === JSON.stringify(item))
        );
        setUsers(uniqueArr);
      } else {
        setUsers(leaders.results);
      }
    }
    return () => {
      setUsers([]);
      setLeaders(null);
    };
  }, [leaders]);

  const changePosition = () => {
    let prev = !position;
    setUsers([]);
    setPosition(prev);
  };

  const loadMore = () => {
    setPage(leaders.next);
  };

  return (
    <>
      <div
        className={`leaderboard column bg-white rounded-[16px] p-[24px] pb-[48px]`}
      >
        <div>
          <div>
            <h2
              className={`text-[#1D2023] text-[20px] lg:text-[24px] leading-[28px] lg:leading-[24px] font-medium font-wide`}
            >
              Лидерборд
            </h2>
            <p
              className={`font-normal text-[17px] leading-[24px] text-[#626C77] mt-[16px]`}
            >
              Первые 10 финалистов получат награду
            </p>
            <p className="leaderboard_position margin-top16 pb-[8px]">
              {" "}
              Моя позиция
            </p>
            <div
              className={`leaderboard-table-body py-[8px] px-[16px] !bg-[#FBFBFB] rounded-[16px]`}
            >
              <div
                className={`w-[26px] h-[26px] md:w-[36px] md:h-[36px] flex items-center justify-center rounded-[50%] text-[14px] md:text-[18px] font-medium leading-[20px] ${
                  user.rank <= 20 ? "bg-[#E677AD] text-white" : ""
                }`}
              >
                {user.rank}
              </div>
              <div
                className={`flex items-center text-[14px] md:text-[18px] leading-[20px] font-normal`}
              >
                {user.photo ? (
                  <img
                    src={baseURL + user.photo}
                    alt={user.first_name}
                    className={`mr-[12px] md:block hidden w-[32px] h-[32px] rounded-[50%]`}
                  />
                ) : (
                  <img
                    src={static_avatar}
                    alt={user.first_name}
                    className={`mr-[12px] md:block hidden w-[32px] h-[32px] rounded-[50%]`}
                  />
                )}
                {user.first_name + " " + user.last_name}
              </div>
              <div
                className={`flex items-center font-medium text-[12px] md:text-[14px] leading-[20px] text-[#6451B7] md:pl-[14px]`}
              >
                <img
                  src={point}
                  alt={"Point"}
                  className={`mr-[6px] md:mr-[8px] md:w-auto md:h-auto w-[14px] h-[14px]`}
                />
                {user.balance}
              </div>
            </div>
          </div>
        </div>
        <div className={`w-full h-[1px] bg-[#BCC3D080] my-[16px]`}></div>
        <div className={`leaderboard-table-header mb-[16px]`}>
          <div className={`leaderboard_position pl-[12px]`}>№</div>
          <div className="leaderboard_position">Участник</div>
          <div className="leaderboard_position">Баллы</div>
        </div>
        <div className="leaderboard-table">
          {leaders &&
            users.length > 0 &&
            users.map((leader, index) => (
              <div
                className={`leaderboard-table-body py-[8px] px-[16px] ${
                  leader.rank % 2 === 1 ? "rounded-[16px] bg-[#FBFBFB]" : ""
                } ${
                  leader.uuid == user?.uuid
                    ? "!bg-[#F2F3F7] rounded-[16px]"
                    : ""
                }`}
                key={leader.uuid}
              >
                <div
                  className={`w-[26px] h-[26px] md:w-[36px] md:h-[36px] flex items-center justify-center rounded-[50%] ${
                    leader.rank <= 10 ? "bg-[#E677AD] text-white" : ""
                  } text-[14px] md:text-[18px] font-medium leading-[20px]`}
                >
                  {leader.rank}
                </div>
                <div
                  className={`flex items-center text-[14px] md:text-[18px] leading-[20px] font-normal`}
                >
                  {leader.photo ? (
                    <img
                      src={leader.photo}
                      alt={leader.first_name}
                      className={`mr-[12px] md:block hidden w-[32px] h-[32px] rounded-[50%]`}
                    />
                  ) : (
                    <img
                      src={static_avatar}
                      alt={leader.first_name}
                      className={`mr-[12px] md:block hidden w-[32px] h-[32px] rounded-[50%]`}
                    />
                  )}
                  {leader.first_name + " " + leader.last_name}
                </div>
                <div
                  className={`flex items-center font-medium text-[12px] md:text-[14px] leading-[20px] text-[#6451B7] md:pl-[14px]`}
                >
                  <img
                    src={point}
                    alt={"Point"}
                    className={`mr-[6px] md:mr-[8px] md:w-auto md:h-auto w-[14px] h-[14px]`}
                  />
                  {leader.balance}
                </div>
              </div>
            ))}
        </div>
        <button
          className={`download bg-[#F2F3F7] rounded-[8px] py-[10px] px-[24px] flex items-center justify-center font-medium leading-[24px] text-[17px] cursor-pointer my-0 mx-auto mt-[32px] ${
            leaders?.next == null && leaders?.prev == null ? "hidden" : "flex"
          }`}
          onClick={() => loadMore()}
        >
          Загрузить ещё
        </button>
      </div>
    </>
  );
}

export default LeaderboardColumn;
