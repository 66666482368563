import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setIsOpenModal, setIsModalRules } from '../../../redux/modal/modal';

function Modalrules () {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);

    return (
        <div className='modal__rules'>
            {/* <h3 className='modal__header'>Зарабатывай true coins и получи шанс выиграть приз</h3> */}
            <div className='modal__rules-box'>
                <h3 className='modal__header-rules'>&nbsp;</h3>
                <button
                    className='modal__close-rules'
                    onClick={ () => { dispatch(setIsOpenModal(false)); dispatch(setIsModalRules(false));} }
                />
            </div>
            <div className='scroll scroll--zero_width leading-6'>
              Чтобы мы поняли, что мероприятие тебе точно подходит, нужно будет заполнить небольшую анкету. Она появится чуть позже. Жди от нас письмо на указанный email и не скучай
            </div>
        </div>
    )
}

export default Modalrules;
