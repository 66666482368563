// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EventLIstItem_eventListItem__17T2c {
  width: 100%;
  padding: 23px 25px;
  background-color: #fff;
  border-radius: 32px;
}
@media screen and (max-width: 460px) {
  .EventLIstItem_eventListItem__17T2c {
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .EventLIstItem_eventListItem__17T2c {
    width: calc(33.3333333333% - 10px);
    max-width: 330px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
}
.EventLIstItem_eventListItem__title__CpeEa {
  font-family: "MTS Compact";
  font-size: 32px;
  font-weight: 400;
  line-height: 1.1;
  margin-bottom: 17px;
}
.EventLIstItem_eventListItem__schedule__W4pQe {
  font-size: 17px;
  font-weight: 500;
  background-color: #C5FF46;
  line-height: 1.15;
  border-radius: 8px;
  display: inline-flex;
  padding: 5px 11px 5px 7px;
  align-items: center;
  margin-bottom: 22px;
}
.EventLIstItem_eventListItem__scheduleIcon__7-9t2 {
  margin-right: 8px;
}
.EventLIstItem_eventListItem__photo__FKHwm {
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Event/EventLIstItem.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,kBAAA;EACA,sBAAA;EACA,mBAAA;AACF;AACE;EANF;IAOI,WAAA;EAEF;AACF;AAAE;EAVF;IAWI,kCAAA;IACA,gBAAA;IACA,aAAA;IACA,sBAAA;IACA,uBAAA;IACA,8BAAA;EAGF;AACF;AADE;EACE,0BAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,mBAAA;AAGJ;AADE;EACE,eAAA;EACA,gBAAA;EACA,yBAAA;EACA,iBAAA;EACA,kBAAA;EACA,oBAAA;EACA,yBAAA;EACA,mBAAA;EACA,mBAAA;AAGJ;AADE;EACE,iBAAA;AAGJ;AADE;EACE,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,WAAA;AAGJ","sourcesContent":[".eventListItem {\n  width: 100%;\n  padding: 23px 25px;\n  background-color: #fff;\n  border-radius: 32px;\n\n  @media screen and (max-width: 460px) {\n    width: 100%;\n  }\n\n  @media screen and (min-width: 768px) {\n    width: calc(100% / 3 - 10px);\n    max-width: 330px;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    justify-content: space-between;\n  }\n\n  &__title {\n    font-family: \"MTS Compact\";\n    font-size: 32px;\n    font-weight: 400;\n    line-height: 1.1;\n    margin-bottom: 17px;\n  }\n  &__schedule {\n    font-size: 17px;\n    font-weight: 500;\n    background-color: #C5FF46;\n    line-height: 1.15;\n    border-radius: 8px;\n    display: inline-flex;\n    padding: 5px 11px 5px 7px;\n    align-items: center;\n    margin-bottom: 22px;\n  }\n  &__scheduleIcon {\n    margin-right: 8px;\n  }\n  &__photo {\n    border-radius: 16px;\n    position: relative;\n    overflow: hidden;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"eventListItem": `EventLIstItem_eventListItem__17T2c`,
	"eventListItem__title": `EventLIstItem_eventListItem__title__CpeEa`,
	"eventListItem__schedule": `EventLIstItem_eventListItem__schedule__W4pQe`,
	"eventListItem__scheduleIcon": `EventLIstItem_eventListItem__scheduleIcon__7-9t2`,
	"eventListItem__photo": `EventLIstItem_eventListItem__photo__FKHwm`
};
export default ___CSS_LOADER_EXPORT___;
