import React, { useState, useEffect } from "react";
import "./CentralColumn.scss";
import { useSelector } from "react-redux";
import Personal from "../Personal/Personal";
import { About } from "../About/About";
import NotificationsColumn from "./NotificationsColumn";
import LeaderboardColumn from "./LeaderboardColumn";
import BroadcastColumn from "./BroadcastColumn";
import Qr from "../Qr/Qr";
import EventsColumn from "./EventsColumn";
import { PromoWrapper } from "../Promo/PromoWrapper";
import SeminarsColumn from "./SeminarsColumn";
import BookmarksColumn from "./BookmarksColumn";

function CentralColumn({ activeMenu }) {
  const [isAbout, setIsAbout] = useState(false);
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    let timer1 = setTimeout(() => {
      !isAbout && window.scoresSetCode && window.scoresSetCode();
    }, 3000);
    return () => {
      clearTimeout(timer1);
    };
  });

  return (
    <div className={`column w-full`}>
      {activeMenu === "account" && (
        <>
          {user.event_format === "OFFLINE" && user.invitation && (
            <div className="offline">
              <Qr />
            </div>
          )}
          {isAbout === false && (
            <>
              <Personal setIsAbout={setIsAbout} />
              <PromoWrapper />
            </>
          )}
          {isAbout === true && <About setIsAbout={setIsAbout} />}
        </>
      )}
      {activeMenu === "notifications" && <NotificationsColumn />}
      {activeMenu === "leaderboard" && <LeaderboardColumn />}
      {activeMenu === "broadcast" && <BroadcastColumn />}
      {activeMenu === "event" && <EventsColumn />}
      {activeMenu === "seminar" && <SeminarsColumn />}
      {activeMenu === "bookmark" && <BookmarksColumn />}
    </div>
  );
}

export default CentralColumn;
