import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { guidToInt } from "../../utils/convertGuid";
import { useLocation } from "react-router-dom";

export const BroadCast = ({ eventKey }) => {
  const location = useLocation();

  return <div className="broadcast__container"></div>;
};
