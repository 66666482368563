import React from "react";
import "./ApproveComing.scss"
import { Button } from "antd";
import { updateProfile } from "../../../../api/profile/profile";
import { useDispatch, useSelector} from "react-redux";
import { getAccessToken } from "../../../../utils/helpers";
import { toast } from "react-toastify";

export const ApproveComing = () => {
  const dispatch = useDispatch();
  const accessToken = getAccessToken();
  const { user } = useSelector((state) => state.user);
  const handleApprove = async () => {
    const {
      id,
      createdAt,
      updatedAt,
      status,
      quizProcess,
      favorites,
      email,
      ...profile
    } = user
    const response = await updateProfile({
      user: {
        ...profile,
        ...{ status: 'approved' },
      },
      accessToken,
      dispatch
    });
    if (response?.status !== 'fail') {
      toast.success("Участие подтверждено", {
        position: "bottom-center",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
      });
    }
  }
  return (
    <div className="approve-notice bg-white rounded-[20px] mb-[20px] text-center">
      <h3>Идёшь&nbsp; на&nbsp;True&nbsp;Tech&nbsp;Camp?</h3>
      <div className="notice__text">
        Нам важно знать, сможешь ли ты прийти. Пожалуйста, подтверди своё участие
      </div>
      <Button type="primary" onClick={() => handleApprove()} className='button_send w-full font-medium'>
        Подтверждаю
      </Button>
    </div>
  );
}
