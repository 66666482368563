import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { setIsOpenModal, setIsModalOffline } from '../../../redux/modal/modal';
import { useSelector } from 'react-redux';
import { setUser } from '../../../redux/user/user';
import { changeFormat } from '../../../api/profile/profile';

function ModalOffline () {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);
    const accessToken = localStorage.getItem('accessToken')
    const [format, setFormat] = useState(user?.event_format);
    console.log(format)

    function changeValue(event) {
        setFormat(event.target.value);
    }

    return (
        <div className='modal__offline'>
            <div className='modal__offline-box'>
                <h3 className='modal__header-offline'>Формат мероприятия</h3>
                <button
                    className='modal__close-offline'
                    onClick={ () => { dispatch(setIsOpenModal(false)); dispatch(setIsModalOffline(false)); }}
                />
            </div>
            <div className='modal__radio-box'>
                <div className="form_radio">
                    <input 
                        id="radio-1" 
                        type="radio" 
                        name="radio" 
                        value="ONLINE" 
                        checked={format === 'ONLINE' ? true : false}
                        onClick={ changeValue }
                    />
                    <label for="radio-1"></label>
                </div>
                <div className='modal__span-box'>
                    <span className='modal__span-top'>Онлайн</span>
                    <span className='modal__span-bottom'>Подключайся к трансляции конференции в личном кабинете</span>
                </div>
            </div>
            <div className='modal__radio-box'>
                <div className="form_radio">
                    <input 
                        id="radio-2" 
                        type="radio" 
                        name="radio" 
                        value="OFFLINE" 
                        checked={format === 'OFFLINE' ? true : false}
                        onClick={ changeValue }
                    />
                    <label for="radio-2"></label>
                </div>
                <div className='modal__span-box'>
                    <span className='modal__span-top'>Офлайн</span>
                    <span className='modal__span-bottom'>Приходи на нашу площадку по адресу: Москва, МТС Live Холл, ш. Энтузиастов д.5, стр. 2</span>
                </div>
            </div>
            <button 
                className='modal__button modal__button_confirm'
                onClick={ () => { changeFormat(format, accessToken, dispatch, setUser); dispatch(setIsOpenModal(false)); dispatch(setIsModalOffline(false)); }}
            >
                Перейти
            </button>
        </div>
    )
}

export default ModalOffline;