import React, { useState, useEffect } from 'react'
import { useDispatch,useSelector } from 'react-redux';
import { setIsOpenModal, setIsModalHistory } from '../../../redux/modal/modal';
import coin from '../../../images/coin.svg'
import { getHistory } from '../../../api/profile/profile';
import { setUserHistory } from '../../../redux/user/user';

function ModalHistory () {
    const dispatch = useDispatch();
    const accessToken = localStorage.getItem("accessToken")
    const { userHistory } = useSelector((state) => state.user);

    useEffect(() => {
        getHistory(accessToken, dispatch, setUserHistory)
    }, []);

    return (
        <div className='modal__history'>
            <div className='modal__history-box'>
                <h3 className='modal__header-history'>Мои true coins</h3>
                <button
                    className='modal__close-history'
                    onClick={ () => { dispatch(setIsOpenModal(false)); dispatch(setIsModalHistory(false)); }}
                />
            </div>
            <ul className='modal__list'>
                {userHistory.map(card =>
                    <li className='modal__card' key={card.id}>
                        <span className='modal__card-name'>{card.description}</span>
                        <div className='modal__coin-box'> 
                            <img
                                src={coin}
                                alt={coin}
                            />
                            <span className='modal__price'>{card.balance}</span>
                        </div>
                    </li>
                )}
            </ul>
        </div>
    )
}

export default ModalHistory;