// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 36px 0 24px 0;
  padding: 0 0 24px;
  width: 100%;
}
@media screen and (max-width: 1024px) {
  .footer {
    margin: 0;
    padding: 20px;
    position: relative;
    z-index: 10;
  }
}
.footer .footer__copyright {
  font-family: "MTS Compact";
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  opacity: 50%;
}
.footer .footer__link {
  font-family: "MTS Compact";
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  opacity: 40%;
  cursor: pointer;
}
@media screen and (min-width: 1024px) {
  .footer .footer__link + .footer__link {
    margin-left: 57px;
  }
}

@media screen and (max-width: 460px) {
  .footer {
    background: rgb(248, 249, 251);
    flex-direction: column-reverse;
    margin: 0;
    padding: 20px;
    max-width: 280px;
  }
  .footer .footer__copyright {
    font-size: 14px;
  }
  .footer .footer__link {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .footer .footer__box {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,qBAAA;EACA,iBAAA;EAOA,WAAA;AALF;AADE;EANF;IAOI,SAAA;IACA,aAAA;IACA,kBAAA;IACA,WAAA;EAIF;AACF;AADE;EACE,0BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,YAAA;AAGJ;AAAE;EACE,0BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,YAAA;EACA,eAAA;AAEJ;AACE;EACE;IACE,iBAAA;EACJ;AACF;;AAGA;EACE;IACE,8BAAA;IACA,8BAAA;IACA,SAAA;IACA,aAAA;IACA,gBAAA;EAAF;EAEE;IACE,eAAA;EAAJ;EAGE;IACE,eAAA;IACA,mBAAA;EADJ;EAIE;IACE,aAAA;IACA,sBAAA;IACA,mBAAA;EAFJ;AACF","sourcesContent":[".footer {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  margin: 36px 0 24px 0;\n  padding: 0 0 24px;\n  @media screen and (max-width: 1024px) {\n    margin: 0;\n    padding: 20px;\n    position: relative;\n    z-index: 10;\n  }\n  width: 100%;\n\n  .footer__copyright {\n    font-family: \"MTS Compact\";\n    font-size: 18px;\n    font-weight: 400;\n    line-height: 20px;\n    text-align: left;\n    opacity: 50%;\n  }\n\n  .footer__link {\n    font-family: \"MTS Compact\";\n    font-size: 18px;\n    font-weight: 400;\n    line-height: 20px;\n    text-align: left;\n    opacity: 40%;\n    cursor: pointer;\n  }\n\n  @media screen and (min-width: 1024px) {\n    .footer__link + .footer__link {\n      margin-left: 57px;\n    }\n  }\n}\n\n@media screen and (max-width: 460px) {\n  .footer {\n    background: rgb(248, 249, 251);\n    flex-direction: column-reverse;\n    margin: 0;\n    padding: 20px;\n    max-width: 280px;\n\n    .footer__copyright {\n      font-size: 14px;\n    }\n\n    .footer__link {\n      font-size: 14px;\n      margin-bottom: 10px;\n    }\n\n    .footer__box {\n      display: flex;\n      flex-direction: column;\n      margin-bottom: 20px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
