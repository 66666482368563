import { useSearchParams } from "react-router-dom";

export const useUtm = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const initUtm = () => {
    for (const element of [
      "utm_source",
      "utm_medium",
      "utm_campaign",
      "utm_term",
      "utm_content",
    ]) {
      if (
        searchParams.get(element) !== null &&
        searchParams.get(element) !== ""
      ) {
        localStorage.setItem(element, searchParams.get(element) || "");
      }
    }
  }

  return {
    initUtm,
  }
}
